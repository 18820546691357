import { Fragment } from 'react';
import Classes from './css/draftOrderDetailPage.module.css';

const Aside = (props) => {
	const { draftOrder, paymentRemark, deliveryRemark } = props;

    const shippingAddress = draftOrder?.DoCustomerAddresses?.filter((address) => address.addressType === 'Shipping');
    const billingAddress = draftOrder?.DoCustomerAddresses?.filter((address) => address.addressType === 'Billing');

	return (
		<Fragment>
			{draftOrder?.length !== 0 && draftOrder?.DoCustomerDetails?.length !== 0 && (
				<div className={[Classes.Box,'box'].join(' ')} id='customerDetails'>
					<div id='customerData'>
						<div className={Classes.containerBox} id='customerBox' style={{borderBottom: '1px solid #DBDDE0'}}>
							<h6 className={Classes.asideCustomer} id='customerHeader' style={{margin: 'auto 0px 15px 0px'}}>Customer</h6>

							<div style={{fontSize:14}}>
								<p style={{color:'#2c6ecb'}}>
									{draftOrder?.DoCustomerDetails[0]?.displayName}
								</p>
								{draftOrder?.DoCustomerDetails[0]?.customerCode && (
									<div style={{display:'flex'}}>
										<p style={{marginRight:5,fontSize:13}}>Code:</p>
										<p style={{color:'#2c6ecb',fontSize:13}}>{draftOrder?.DoCustomerDetails[0]?.customerCode}</p>
									</div>
								)}
							</div>
						</div>

						<div className={Classes.containerBox} id='contactBox' style={{borderBottom: '1px solid #DBDDE0'}}>
							<p 	className={[Classes.asideHeaders,Classes.ContactHeader].join(' ')} id='contactHeader' style={{margin: 'auto 0px 15px 0px'}}>
								CONTACT INFORMATION
							</p>

							<div style={{ color:'#2c6ecb',fontSize:14 }}>
								<p>{draftOrder?.DoCustomerDetails[0]?.email}</p>
								<p style={{marginTop: '5px'}}>{draftOrder?.DoCustomerDetails[0]?.phoneNumber}</p>
							</div>
						</div>

						{draftOrder?.DoCustomerDetails[0]?.gstNumber?.length !== 0 && (
							<div className={Classes.MetafieldBox} id='gstBox'>
								<p className={Classes.GstContent}>GST Number</p>
								<p className={Classes.GstNumber}>{draftOrder?.DoCustomerDetails[0]?.gstNumber[0]}</p>
							</div>
						)}
					</div>

					{shippingAddress?.length !== 0 ? (
						<div className={[Classes.containerBox, Classes.commonBorderBottom].join(' ')} id='shippingAddress'>
							<p className={[Classes.asideHeaders,'mb-3'].join(' ')}>SHIPPING ADDRESS</p>

							<div style={{fontSize: '14px'}}>
								<p>
									{shippingAddress?.[0]?.firstName}{' '}
									{shippingAddress?.[0]?.lastName && shippingAddress?.[0]?.lastName !== "null" && shippingAddress?.[0]?.lastName}
								</p>
								<p>
									{shippingAddress?.[0]?.addressLine1}{' '}
									{shippingAddress?.[0]?.addressLine2 !== null && shippingAddress?.[0]?.addressLine2}
								</p>
								<p>{shippingAddress?.[0]?.pincode}{' '} {shippingAddress?.[0]?.city},</p>
								<p>{shippingAddress?.[0]?.state?.toTitleCase()},</p>	
								<p>{shippingAddress[0]?.country}</p>
								<p>{shippingAddress[0]?.phone}</p>
							</div>
						</div>
					):null}
				
					{billingAddress?.length !== 0 ? (
						<div className={Classes.containerBox} id='BillingAddress'>
							<p className={[Classes.asideHeaders,'mb-2'].join(' ')}>BILLING ADDRESS</p>

							<div style={{fontSize: '14px'}}>
								<p>
									{billingAddress?.[0]?.firstName}{' '}
									{billingAddress?.[0]?.lastName && billingAddress?.[0]?.lastName !== "null" && billingAddress?.[0]?.lastName}
								</p>
								<p>
									{billingAddress[0]?.addressLine1}
									{billingAddress[0]?.addressLine2 !== null && billingAddress[0]?.addressLine2}
								</p>
								<p>{billingAddress[0]?.pincode}{' '}{billingAddress[0]?.city},</p>	
								<p>{billingAddress[0]?.state?.toTitleCase()},</p>
								<p>{billingAddress[0]?.country}</p>
								<p>{billingAddress[0]?.phone}</p>
							</div>
						</div>
					):null}
				</div>
			)}

			{draftOrder?.length !== 0 && (
				<div id='gstNumberBox' >
					{draftOrder?.DoCustomerDetails?.length !== 0 && draftOrder?.DoCustomerDetails?.[0]?.gstNumber?.length !== 0 && (
						<div className={[Classes.Box,Classes.asideMetafields,'box'].join(' ')}>
							<h6 style={{margin: '5px 0px'}}>GST Number</h6>
							<p style={{ fontSize:'14px',paddingTop:5 }}>{draftOrder?.DoCustomerDetails[0]?.gstNumber[0]}</p>
						</div>
					)}

					{draftOrder?.createdVia && (
						<div className={[Classes.Box,Classes.asideMetafields, 'box'].join(' ')} id='platform'>
							<h6 style={{ margin:'5px 0px' }}>Platform</h6>
							<p style={{ fontSize: '14px',paddingTop: 5 }}>{draftOrder?.createdVia || "N/A"}</p>
						</div>
					)}
				</div>
			)}

			{draftOrder?.length !== 0 && draftOrder?.DoSellerDetails?.length > 0 && (
				<div className={Classes.Box} style={{ padding: 10 }} id='affiliateDetails'>
					<h6 style={{ margin: '5px 5px' }}>Seller</h6>
					<div style={{ padding: 3 }}>
						<p style={{ fontSize:14, marginLeft:5 }}>{draftOrder?.DoSellerDetails[0]?.sellerName}</p>
						<p style={{ fontSize:14, marginLeft:5 }}>{draftOrder?.DoSellerDetails[0]?.phoneNumber}</p>
						<p style={{ fontSize: 14, marginLeft: 5 }}>{draftOrder?.DoSellerDetails[0]?.sellerGstNumber}</p>
					</div>
				</div>
			)}

			{deliveryRemark && (
				<div style={{padding: '0.8rem 1.1rem'}} className={Classes.Box} id='deliveryRemark'>
					<h6 style={{margin: 'auto 0px'}}>Delivery Remark</h6>
					<p className={Classes.remarkBox}>{deliveryRemark}</p>
				</div>
			)}
                
			{paymentRemark && (
				<div style={{padding: '0.8rem 1.1rem'}} className={Classes.Box} id='paymentRemark'>
					<h6 style={{margin: 'auto 0px'}}>Payment Remark</h6>
					<p className={Classes.remarkBox}>{paymentRemark}</p>
				</div>
			)}
				
			{draftOrder?.length !== 0 && (draftOrder?.DoAffiliateUser ||  
			draftOrder?.DoSalesUser || draftOrder?.DoPreSalesUser) && (
				<div className={Classes.Box} style={{padding:10}} id='affiliateDetails'>
					<div style={{display:'flex'}}>
						<h6>Affiliate:</h6>
						<p style={{fontSize:14,marginLeft:5}}>{draftOrder?.DoAffiliateUser?.name}</p>
					</div>

					<div style={{display:'flex'}}>
						<h6>Sales User:</h6>
						<p style={{fontSize:14,marginLeft:5}}>{draftOrder?.DoSalesUser?.name}</p>
					</div>

					<div style={{display:'flex'}}>
						<h6>Pre Sales User:</h6>
						<p style={{fontSize:14,marginLeft:5}}>{draftOrder?.DoPreSalesUser?.name}</p>
					</div>
				</div>
			)}
				
			{draftOrder?.length !== 0 && draftOrder?.tags && draftOrder?.tags?.length !== 0 && (
				<div className={Classes.Box} style={{padding:'10px'}} id='removeTagsPrint'>
					<h6 style={{margin: 'auto 0px 15px 0px',padding: '6px'}}>Tags</h6>
					{draftOrder?.tags?.map((tag,index) => (
						<p key={index} className={[Classes.tags, Classes.tagsBox].join(' ')}>
							{tag}
						</p>
					))}
				</div>
			)}
		</Fragment>
	)				
};

export default Aside;
