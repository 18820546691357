import Classes from "./css/addProduct.module.css";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Prices from "../prices";
import { useCallback, useEffect, useState } from "react";

const MultiPriceCard = (props) => {
  const {
    pricesObject,
    priceCategories,
    priceCategory,
    payloadToUpload,
    setPayloadToUpload,
    priceCategoryAttribute,
  } = props;

  const [priceCategoryValue, setPriceCategoryValue] = useState(null);

  const handleSectionRemoval = (priceCardId) => {
    setPayloadToUpload((prevPayloadToUpload) => {
      return {
        ...prevPayloadToUpload,
        [priceCategoryAttribute]: prevPayloadToUpload?.[
          priceCategoryAttribute
        ]?.filter((price) => price?.priceCardId !== priceCardId),
      };
    });
  };

  const handlePriceCategoryValueChange = useCallback(
    (priceCardId) => (e, chosenValue) => {
      if (payloadToUpload?.[priceCategoryAttribute]?.length !== 0) {
        let chosenPriceCategoryToSet = chosenValue;

        if (
          payloadToUpload?.[priceCategoryAttribute]?.find((price) => {
            return price[priceCategory] === chosenValue;
          })
        ) {
          alert(`Price for this ${priceCategory} already exists`);
          chosenPriceCategoryToSet = null;
        }

        setPayloadToUpload((prevPayloadToUpload) => {
          return {
            ...prevPayloadToUpload,
            [priceCategoryAttribute]: prevPayloadToUpload?.[
              priceCategoryAttribute
            ]?.map((price) => {
              if (price?.priceCardId === priceCardId) {
                return {
                  ...price,
                  [priceCategory]: chosenPriceCategoryToSet,
                };
              } else {
                return price;
              }
            }),
          };
        });
      }
    },
    [payloadToUpload?.[priceCategoryAttribute], priceCategoryAttribute, priceCategory, setPayloadToUpload]
  );

  useEffect(() => {
    if (pricesObject?.[priceCategory]) {
      setPriceCategoryValue(pricesObject?.[priceCategory]);
    }
  }, [pricesObject, priceCategory]);

  return (
    <>
      <div className={Classes.Pricing} key={pricesObject?.priceCardId}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton
            onClick={() => handleSectionRemoval(pricesObject?.priceCardId)}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className={Classes.Boxes}>
          {priceCategories && (
            <Autocomplete
              forcePopupIcon={true}
              size="small"
              id="price-categories"
              required
              options={priceCategories}
              style={{ width: "48%" }}
              onChange={handlePriceCategoryValueChange(
                pricesObject?.priceCardId
              )}
              value={priceCategoryValue}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={priceCategory?.toUpperCase()}
                />
              )}
            />
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              marginTop: "2em",
            }}
          >
            <Prices
              payloadToUpload={payloadToUpload}
              setPayloadToUpload={setPayloadToUpload}
              pricesType={priceCategoryAttribute}
              priceCardId={pricesObject?.priceCardId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiPriceCard;
