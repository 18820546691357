import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import {Autocomplete, TextField, Button} from '@mui/material';
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import Classes2 from '../dashboard/css/dashboard.module.css'

const InventoryAnalytics = () => {
    const getFormattedDate = (date) => `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    const [startDate, setStartDate] = useState(() => 
        getFormattedDate(new Date(Date.now() - 15 * 24 * 60 * 60 * 1000))
    );// set last 15 days date by default
    const [endDate, setEndDate] = useState(() => getFormattedDate(new Date()));

    const currentTimeStamp = () => moment(new Date()).format('YYYY-MM-DD_HH:MM:SSS');
    const [data, setData] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [locationCode, setLocationCode] = useState('');
    const [locations, setLocations] = useState([]);
    const [reRender] = useState(false);
    const [text, setText] = useState('');
    const [filter, setFilter] = useState([]);

    const fetchLocation = (text) => {
        axiosInstance
            .get(`/location/locations`)
            .then((res) => {
                const locations = [];
                for (let location of res?.data?.data){
                    locations.push(location?.locationCode)
                }
                setLocations(locations) 
            })
    }

    useEffect(() => {
        fetchLocation();
    },[])

    const columns = [
    {
        field: 'sku_code',
        headerName: 'SKU Code',
        flex:1.5,
        headerClassName: 'super-app-theme--header',
        filterable : true,
    },
    {
        field: 'product_name',
        headerName: 'Product Name',
        flex:2,
        headerClassName: 'super-app-theme--header',
        filterable : true,
    },
    { 
        field: 'brand_name', 
        headerName: 'Brand Name', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : true,
    },
    { 
        field: 'locationCode', 
        headerName: 'Location Code', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'quantity', 
        headerName: 'Quantity', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'avgSellingPrice', 
        headerName: 'Average Selling Price', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        renderCell: (params) => {
            return '₹' + (+params.value).toFixed(2)
        }
    },
    { 
        field: 'avgPurchasePrice', 
        headerName: 'Average Purchase Price', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        renderCell: (params) => {
            return '₹' + (+params.value).toFixed(2)
        }
    }
];


const generateReport = async () => {
    const newReportName = 'Inventory Analytics';
    const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
    const params = {
        uploadFileName : fileName,
        reportName : newReportName,
        locationCode,
        text,
        startDate,
        endDate,
        'filter[]': filter
    };

    await axiosInstance
        .get(`/analytics/reports/inventoryAnalytics`, { params })
        .then((res) => {
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: res?.data?.message, type: "success" }
            });
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
            });
        });
};

function DateRange () {
        return (
            <div className={Classes2.DatePickerContainer} style={{ justifyContent:'normal', gap:'10px', width:'auto' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Start Date" 
                        sx={{ 
                                "& .MuiOutlinedInput-input": {padding: "9.5px 8px",width: '105px',fontSize:'14px'},
                                "& .MuiSvgIcon-root": { fontSize:"18px" },
                                "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                                "& .Mui-error": { color:'#00000099 !important' }
                        }}
                        inputFormat="YYYY-MM-DD"
                        size="small"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(startDate)}
                        value={dayjs(startDate)}
                        onChange={(newValue) => {
                            setStartDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        }} 
                    />
                    <DatePicker 
                        label="End Date"
                        sx={{ 
                            "& .MuiOutlinedInput-input": {padding: "9.5px 8px",width: '105px',fontSize:'14px'},
                            "& .MuiSvgIcon-root": { fontSize:"18px" },
                            "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                            "& .Mui-error": { color:'#00000099 !important' }
                        }}
                        inputFormat="YYYY-MM-DD"
                        size="small"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(endDate)}
                        value={dayjs(endDate)}
                        onChange={(newValue) => {
                            setEndDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        }} 
                    />
                </LocalizationProvider>
            </div>
        )
    }

    return (
        <Fragment>
                <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

                <div style={{ margin: '8px 0px', display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '8px' }}>
                    <Button variant='contained' onClick={generateReport}>Export Report</Button>
                </div>

                <div style={{ margin: '8px 0px', display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '24px', flexWrap:'wrap' }}>
                    <div style={{width: '200px'}}>
                        <Autocomplete
                            size='small'
                            disablePortal
                            id="locationCode"
                            name="locationCode"
                            options={locations}
                            renderInput={(params) => <TextField 
                                {...params} label="Location Code"
                                onBlur={(event) => {setLocationCode(event.target.value)}}
                            />}
                        />
                    </div>

                    {DateRange()}
                </div>
                
            
                <div>
                    <Table 
                        columns={columns} 
                        dataPosition={"inventories"} 
                        endpoint={`/inventory/getInventoriesAnalytics`} 
                        rowId={'inventory_id'}
                        checkBoxSelection={false}
                        tabName={'Inventory Analytics'}
                        setData={setData}
                        data={data}
                        createdByFilter={false}
                        isReactDispatch={true}
                        locationCode={locationCode}
                        reRender={reRender}
                        setText={setText}
                        startDateProp={startDate}
                        endDateProp={endDate}
                        setFilter={setFilter}
                        mobileResponsive={true}
                    />
                </div>
        </Fragment>
    )
}
export default InventoryAnalytics;