import Table from '../../../components/table/table';
import { setDraftOrders } from '../../../store/draftOrdersSlice';
import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';

const columns = [
  {
    field: 'draftOrderName',
    headerName: 'DRAFT ORDER NAME',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: true,
  },
  {
    field: 'createdAt',
    headerName: 'CREATED AT',
    flex: 1.5,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: true,
  },
  {
    field: 'displayName',
    headerName: 'CUSTOMER',
    flex: 1.5,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: true,
  },
  {
    field: 'totalPrice',
    valueFormatter: (params) => `₹${params.value}`,
    headerName: 'TOTAL',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: true,
  },
  {
    field: 'createdBy',
    headerName: 'CREATED BY',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: true,
    type: 'enum',
    tableName: 'DraftOrder',
    columnName: 'createdBy',
  },
  {
    field: 'createdVia',
    headerName: 'PLATFORM',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: true,
    type: 'enum',
    tableName: 'DraftOrder',
    columnName: 'createdVia',
  },
  {
    field: 'draftOrderId',
    headerName: 'ID',
    flex: 0.4,
    headerClassName: 'super-app-theme--header',
    filterable: false,
    sortable: false,
  },
  {
    field: 'draftOrderType',
    headerName: 'TYPE',
    flex: 0.4,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: true,
    enumValues: [
      "B2C",
      "B2B",
    ],
  },
  {
    field: 'customerPhoneNumber',
    headerName: 'PHONE NUMBER',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: false,
  },
  {
    field: 'customerCode',
    headerName: 'CUSTOMER CODE',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    filterable: true,
    sortable: true,
  },
];

const columnVisibilityModel = {
  customerPhoneNumber: false,
  draftOrderType: false,
  draftOrderId: false,
  customerCode: false,
}

export default function DraftOrdersNew() {
  const [reRender, setReRender] = useState(false);
  const draftOrders = useSelector((state) => state.draftOrders);

  return (
    <Fragment>
      <Table
        version="version-1"
        rowId={'draftOrderName'}
        columns={columns}
        dataPosition={"draftOrders"}
        endpoint={'/pgOrder/admin/draftOrders'}
        exportEndpoint={'/exports/draftOrders'}
        exportFileName={`Draft Orders - ${new Date()}`}
        checkBoxSelection={false}
        reRender={reRender}
        service={'draftOrder'}
        tabName={'Draft Order'} // must match with pinned tab name
        setData={setDraftOrders}
        data={draftOrders}
        columnVisibilityModel={columnVisibilityModel}
        navigateOnRowClickEndpoint={'/draftOrdersNew'}
        mobileResponsive={true}
        clickable={true}
      />
    </Fragment>
  );
}