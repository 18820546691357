import React, { useEffect, useState } from "react";
import Classes from "./dashboard.module.css";
import { useNavigate } from "react-router-dom";
import Permission from "../../auth/permissions";
import SettingsItem from "../../../components/settingsMenu";
import { websiteSettingsData } from "../../../constants/settingsConstants";

const WebsiteSettings = () => {
  const [webSettingType, setWebSettingType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    webSettingType && navigate(`${webSettingType}`);
  }, [webSettingType, navigate]);

  const handleItemClick = (type) => {
    setWebSettingType(type);
  };

  return (
    <>
      <div className={Classes.Container}>
        {websiteSettingsData.map((setting, index) => (
          <SettingsItem
            key={index}
            onClick={() => handleItemClick(setting.navigate)}
            iconSrc={setting.src}
            iconAlt={setting.alt}
            title={setting.text}
            subTitle={setting?.subTitle}
          />
        ))}
      </div>
    </>
  );
};

export default WebsiteSettings;
