import React, {Fragment, useState, useEffect } from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import Spinner from '../../../../components/spinner';

const UpdateShippingPopUp = (props)=> {
    const [states,setStates] = useState([]);
    const [loading,setLoading] = useState(false);
    const [number,setNumber] = useState(null);
    const [pincode,setPincode] = useState(null);
    const [gstNumber,setGstNumber] = useState(null);

    const {
        updateShippingModal,
        hideUpdateShippingPop,
        showCustomerDetail,
        addressType,
        addressArray,
        setAddressArray,
        selectedShippingAddress, 
        setSelectedShippingAddress,
    } = props;

    async function handlePincodeChange(event) {
        const limit = 6;
        const enteredPincode = event.target.value.slice(0, limit)
        setPincode(enteredPincode)
        try {
            const response = await fetch(`https://api.postalpincode.in/pincode/${enteredPincode}`);
            const data = await response.json();

            if (data[0]?.Status === 'Success') {
                const state = document.getElementById('states');
                const extractedState = data[0]?.PostOffice[0]?.State;
                state.value = extractedState;
            }
        } catch (err) {
            console.log(err);
        }
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleFormSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        hideUpdateShippingPop();

        axiosInstance
            .post(`/user/admin/${showCustomerDetail?.[0]?._id}/address/${selectedShippingAddress?.id}`, {
                address1: event.target.address1.value,
                address2: event.target.address2.value,
                city: event.target.city.value,
                province: event.target.states.value,
                zip: event.target.pincode.value,
                country: event.target.country.value,
                firstName: event.target.first.value,
                lastName: event.target.last.value ? event.target.last.value : undefined,
                nick_name: event.target.nick_name.value ?? undefined,
                phone: `+91${event.target.phone.value}`,
                addressType: addressType,
                gstNumber: event.target.gst.value ? event.target.gst.value : undefined,
            })
            .then((res) => {
                setPincode(null);
                setNumber(null);
                setGstNumber(null);
                setAddressArray([...addressArray, res.data.data]);
                setSelectedShippingAddress(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
                const errorMessage = err?.response?.data?.message;
                alert(errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleMinLengthPinCodes = (event) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if (event.target.value.match(pincode)) {
                document.getElementById('pinErrMsg1').style.display = 'none';
                document.getElementById('updateAddress').disabled = false;
            } else {
                document.getElementById('updateAddress').disabled = true;
                document.getElementById('pinErrMsg1').style.display = 'block';
            }
        } else {
            document.getElementById('pinErrMsg1').style.display = 'none';
            document.getElementById('updateAddress').disabled = false;
        }
    }

    const handleMinLengthPhoneNumber = (event) => {
        if (event.targe.value) {
            const phoneNo = /^\d{10}$/;
            if (event.target.value.match(phoneNo)) {
                document.getElementById('phoneErrMsg1').style.display = 'none';
                document.getElementById('updateAddress').disabled = false;
            } else {
                document.getElementById('updateAddress').disabled = true;
                document.getElementById('phoneErrMsg1').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg1').style.display = 'none';
            document.getElementById('updateAddress').disabled = false;
        }
    }

    const handleValidGst = (event) => {
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('updateAddress').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('updateAddress').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('updateAddress').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }

    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({id:stateName?._id,label:stateName?.state})
                }
                setGstNumber(selectedShippingAddress?.gstNumber);
                setNumber(selectedShippingAddress?.phone?.slice(3));
                setPincode(selectedShippingAddress?.zip);
                setStates(states)
            })
    },[selectedShippingAddress]);

    const handleNumberChange = event => {
        setNumber(event.target.value.slice(0, 10));
    };

    return (
        <Fragment>
            {updateShippingModal && (
                <div className={Classes.modal} style={{ bottom: '11%', top: '3%', zIndex:'9999' }}>
                    <div className={Classes.BoxContent}>
                        <p>Edit Shipping Address</p>
                    </div>

                    <form method="post" onSubmit={handleFormSubmit} id='form'>
                        <div className={Classes.AddressPopUp}>
                            <div style={{ marginTop: '10px' }}>
                                <TextField size="small"
                                    id='country'
                                    name='country'
                                    label='Country / Origin'
                                    value='India'
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top:"0%",fontSize:14},"& .MuiOutlinedInput-input":{padding:'6px 10px'}}}
                                    disabled={true}
                                />
                            </div>

                            <div style={{display:'flex', marginTop:10,justifyContent:'space-between'}}>
                                <div style={{width:'48%'}}>
                                    <TextField size="small"
                                        required={true}
                                        id='first'
                                        name='first'
                                        label='First Name'
                                        autoComplete='off'
                                        key={selectedShippingAddress?.firstName}
                                        defaultValue={selectedShippingAddress?.firstName}
                                        placeholder='Enter Your First Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField size="small"
                                        id='last'
                                        name='last'
                                        label='Last Name'
                                        autoComplete='off'
                                        key={selectedShippingAddress?.lastName}
                                        defaultValue={selectedShippingAddress?.lastName}
                                        placeholder='Enter Your Last Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div style={{display:'flex', marginTop:10,justifyContent:'space-between'}}>
                                <div style={{width:'48%'}}>
                                    <TextField size="small"
                                        required={true}
                                        id='nick_name'
                                        name='nick_name'
                                        label='Nick Name'
                                        autoComplete='off'
                                        key={selectedShippingAddress?.nick_name}
                                        defaultValue={selectedShippingAddress?.nick_name && selectedShippingAddress?.nick_name !== 'null' ? selectedShippingAddress?.nick_name : ''}
                                        placeholder='Enter Your Nick Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>


                            <div style={{marginTop:'10px'}}>
                                <TextField size="small"
                                    required={true}
                                    id='address1'
                                    name='address1'
                                    label='Address 1'
                                    autoComplete='off'
                                    key={selectedShippingAddress?.address1}
                                    defaultValue={selectedShippingAddress?.address1}
                                    placeholder='Apartment,suite,etc'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                <TextField
                                    id='address2'
                                    name='address2'
                                    label='Address 2'
                                    autoComplete='off'
                                    key={selectedShippingAddress?.address2}
                                    defaultValue={selectedShippingAddress?.address2}
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                                <div style={{width:'48%'}}>
                                    <TextField size="small"
                                        required={true}
                                        id='city'
                                        name='city'
                                        label='City'
                                        key={selectedShippingAddress?.city}
                                        defaultValue={selectedShippingAddress?.city}
                                        autoComplete='off'
                                        placeholder='Enter Your City'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            
                                <div style={{width:'48%'}}>
                                    <TextField size="small"
                                        type="number"
                                        required={true}
                                        id='pincode'
                                        name='pincode'
                                        label='Pincode'
                                        autoComplete='off'
                                        placeholder='Enter Your Pincode'
                                        maxLength={6}
                                        minLength={6}
                                        inputProps={{ maxLength: 6 }}
                                        sx={{ width: '100%'}}
                                        value={pincode}
                                        onBlur={(e) => handleMinLengthPinCodes(e)}
                                        onChange={handlePincodeChange}
                                        onKeyDown={preventNegativeValues}
                                    />

                                    <div 
                                        className={Classes.PhoneErrorMsg} 
                                        id='pinErrMsg1' 
                                        style={{ fontSize:'14px' }}
                                    >
                                        <p>Please enter a valid 6 digit pincode</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                                <div style={{width: '48%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="states"
                                        name='states'
                                        options={states}
                                        size="small"
                                        defaultValue={selectedShippingAddress?.province}
                                        key={selectedShippingAddress?.province}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="States" required/>}
                                    />
                                </div>

                                <div style={{width: '48%'}}>
                                    <TextField size="small"
                                        type="number"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        maxLength={10}
                                        sx={{ width: '100%'}}
                                        value={number}
                                        onBlur={handleMinLengthPhoneNumber}
                                        onChange={handleNumberChange}
                                        onKeyDown={preventNegativeValues}
                                    />

                                    <div
                                        className={Classes.PhoneErrorMsg}
                                        id='phoneErrMsg1'
                                        style={{ fontSize: '15px' }}
                                    >
                                        <p>Please enter a valid 10 digit mobile number</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        id='gst'
                                        name='gst'
                                        label='GST Number'
                                        size="small"
                                        autoComplete='off'
                                        placeholder='Enter GST Number'
                                        sx={{ width: '100%' }}
                                        value={gstNumber}
                                        onBlur={handleValidGst}
                                        onChange={(event) => setGstNumber(event.target.value.slice(0, 15))}
                                    />
                                    <div
                                        id='isValidGst'
                                        className={Classes.IsValidGst}
                                    >
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={Classes.DialogBtnBox} style={{bottom: "10%"}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideUpdateShippingPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='updateAddress' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>
                    </form>

                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default UpdateShippingPopUp;