import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Chip, AccordionSummary, Accordion, AccordionDetails, Typography } from '@mui/material';

const extractFileName = (url) => {
    if (!url) return "";
    const splitUrl = url.split("/");
    const fileNameWithParams = splitUrl[splitUrl.length - 2];
    // const fileName = fileNameWithParams.split("?")[0];
    return fileNameWithParams;
}

export default function DocumentsSection({ documents, relatedTo }) {
    return (
        <div style={{ width: '100%', marginBottom: 20 }}>
            <div style={{ width: '100%', borderRadius: 4, boxShadow: "0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)" }}>
                <p style={{ fontWeight: 600, padding: "3px 15px", borderRadius: "4px 4px 0px 0px", backgroundColor: '#9e9e9e1f', fontSize: 15 }}>Documents</p>
                {Object.keys(documents).map((key, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>{key?.toTitleCase()}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {documents[key].map((doc, index) => (
                                <Chip 
                                    icon={<FilePresentIcon />} 
                                    style={{ margin: '1px 3px' }} 
                                    clickable label={decodeURIComponent(extractFileName(doc))} 
                                    component="a" href={doc} target='_blank' 
                                />
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
}