import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Classes from "./css/Modal.module.css";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';
import Classes from '../css/asideModal.module.css';
import CustomerModal from "./newCustomerModal";
import InfiniteScroll from "react-infinite-scroll-component";


const AsideModal = (props) => {
    const [customers, setCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [customerInfo, setCustomerInfo] = useState([]);
    const [toggleBtn, setToggleBtn] = useState(true);
    const [searchPage, setSearchPage] = useState(1);
    const [page, setPages] = useState(1);
    const [maxPages, setMaxPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');

    // newCustomer Model
    const [gstNO, setGstNO] = useState();
    const [num, setNum] = useState('');

    const {
        setDetail,
        searchCustomer,
        toggleButton,
        setToggleButton,
        setCustomerIDAddress,
        setPreviousTarget,
        setBillingAddress,
        toggle,
        setToggle,
        setAddressArray,
        setVendorContacts,
        setVendorContactDetails,
        setVendorGstNumber,
        fetchVendorAddresses,
    } = props;

    const getModal = () => {
        setShowModal(true);
        props.onHideModal();
    };

    const hideModal = () => {
        props.onHideModal();
        setNum('');
        setGstNO('');
        setErrorMessage('');
        setShowModal(false);
    };

    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('modal');
        if (container !== null) {
            if (!container.contains(e.target)) {
                props.onHideModal(false);
            }
        }
    });

    const handleCustomer = (customer) => {
        props.onHideModal();
        setToggleBtn(true);
        setCustomerInfo(customer);
    }

    useEffect(() => {
        if (toggleButton) {
            axiosInstance
                .get(`/vendor/vendors?text=${searchCustomer}`)
                .then((res) => {
                    setCustomers(res.data.data);
                    setToggleButton(false);
                })
        }
    }, [toggleButton, searchCustomer, setToggleButton, searchPage])

    useEffect(() => {
        if (toggle) {
            axiosInstance
                .get(`/vendor/vendors?text=${searchCustomer}`)
                .then((res) => {
                    setCustomers(res.data.data);
                    setPages(2)
                    setToggle(false)
                })
        }
    }, [toggle, setToggle, searchCustomer])

    useEffect(() => {
        if (toggleBtn) {
            if (customerInfo?.length > 0) {
                axiosInstance
                    .get(`/vendor/vendor/${customerInfo}`)
                    .then((res) => {
                        setDetail([res?.data?.data])
                        if (res?.data?.data?.vendorGstNumber?.length > 0) {
                            setVendorGstNumber(res?.data?.data?.vendorGstNumber[0]);
                        }
                        setToggleBtn(false);
                        axiosInstance
                            .get(`/vendor/vendorContactDetails/${customerInfo}`)
                            .then((res) => {
                                setVendorContacts(res?.data?.data);
                                if (res?.data?.data?.length > 0) {
                                    setVendorContactDetails(res?.data?.data[0]);
                                }
                                fetchVendorAddresses({vendorCode: customerInfo, isSetDefaultSelectedAddress: true }) // fetch all addresses
                                    .then(() => {              
                                        setPreviousTarget([]);
                                        setToggleBtn(false);
                                    })
                            })
                    })
            }
        }
    }, [toggleBtn,
        setDetail,
        customerInfo,
        setBillingAddress,
        setCustomerIDAddress,
        setPreviousTarget,
        setAddressArray,
        setVendorContacts
    ]);


    const fetchMoreData = () => {
        if (maxPages >= page) {
            setTimeout(() => {
                axiosInstance
                    .get(`/user/admin?page=${page}&text=${searchCustomer}`)
                    .then((res) => {
                        if (res.data.data.maxPage >= page) {
                            setPages(page + 1)
                        } else {
                            setPages(1)
                        }
                        setCustomers([...customers, ...res.data.data.users]);
                    })
            }, 1500);
        }
    }

    return (
        <Fragment>
            {props.showModal && (
                <div className={Classes.modal} id="modal">
                    <div
                        className={Classes.ContactSearch}
                        onClick={() => getModal()}
                    >
                        <FontAwesomeIcon
                            icon={faCirclePlus}
                            style={{
                                fontSize: '17px',
                                paddingRight: '10px',
                                color: 'rgb(129, 138, 155)',
                                paddingTop: '3px',
                            }}
                        />
                        <p>Create New Vendor</p>
                    </div>
                    <div style={{ height: '350px' }}>
                        {customers.length !== 0 &&
                            <div>
                                <InfiniteScroll
                                    dataLength={customers.length}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    loader={page - 1 !== maxPages
                                        ? <h6>Loading...</h6>
                                        : ''}
                                >
                                    {customers.map((item, index) => (
                                        <div key={index} className={Classes.styles} onClick={() => handleCustomer(item.vendorCode)}>
                                            <div>
                                                <div style={{ display: 'flex' }}>
                                                    {item.vendorName !== "undefined"
                                                        ? <p style={{ marginRight: '5px' }}>{item.vendorName}</p>
                                                        : ''
                                                    }

                                                </div>
                                            </div>
                                            <div>
                                                <p style={{ color: 'gray' }}>{item.vendorCode}</p>
                                            </div>
                                        </div>
                                    ))}
                                </InfiniteScroll>
                            </div>
                        }
                    </div>
                </div>
            )}

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <CustomerModal
                    onShowModal={showModal}
                    setModal={setShowModal}
                    onHideModal={hideModal}
                    setDetail={setDetail}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    setVendorContactDetails={setVendorContactDetails}
                />
            </div>
        </Fragment>
    );
}


export default AsideModal;