import DesktopResponsiveTable from "./DesktopResponsiveTable";
import MobileResponsiveTable from "./MobileResponsiveTable";
import Classes from "./css/table.module.css";
import { useEffect, useState } from "react";
export default function Table(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 540);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 540);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    columns,
    dataPosition,
    endpoint,
    rowId,
    exportEndpoint,
    exportFileName,
    mobileResponsive,
    clickable,
    navigateOnRowClickEndpoint,
    initialFilterState,
    delayedExport,
    redirectUrlOnSnackBarClick,
    startDateProp,
    endDateProp,
    tabName
  } = props;

  return (
    <div>
      {mobileResponsive ? (
        isMobile ? (
          <div className={Classes.MobileResponsiveTable}>
            <MobileResponsiveTable
              columns={columns}
              dataPosition={dataPosition}
              endpoint={endpoint}
              rowId={rowId}
              exportEndpoint={exportEndpoint}
              exportFileName={exportFileName}
              clickable={clickable}
              tab={navigateOnRowClickEndpoint}
              initialFilterState={initialFilterState}
              delayedExport={delayedExport}
              redirectUrlOnSnackBarClick={redirectUrlOnSnackBarClick}
              tabName={tabName}
              startDateProp={startDateProp}
              endDateProp={endDateProp}
            />
          </div>
        ) : (
          <div className={Classes.DesktopResponsiveTable}>
            <DesktopResponsiveTable {...props} />
          </div>
        )
      ) : (
        <div className={Classes.DesktopResponsiveTable}>
          <DesktopResponsiveTable {...props} />
        </div>
      )}
    </div>
  );
}
