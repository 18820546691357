import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	product: null,
	draftOrderName: null,
	userName: null,
	customerName: null,
	groupId: null,
	productManagement: null,
	catalogueName: null
};

const breadcrumbSlice = createSlice({
	name: 'breadcrumb',
	initialState: initialState,
	reducers: {
		setBreadcrumb: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setBreadcrumb, reset } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
