import { Fragment } from 'react';
import Classes from './productCreated.module.css';
import { useSelector } from 'react-redux';
import { setReportServiceData } from '../../../store/reportServiceSlice';
import Table from '../../../components/table/table';

const ProductsCreated = () => {
	const reportServiceData = useSelector((state) => state.reportServiceData);

	const RequestTypeRenderCell = (row) => {
		return (
			<p 
				style={{
					backgroundColor: requestTypesStyle[row?.requestType] ? 
					requestTypesStyle[row?.requestType] : 'powderblue',
					width:'fit-content'
				}}
				className={Classes.RequestType}
			>
				{row?.requestType}
			</p>
		)
	};

	const DurationRenderCell = (row) => {
		return (
			<p className={Classes.DurationContent}>
				{row?.duration}
			</p>
		)
	};

	const TotalRenderCell = (row) => {
		return (
			<p 
				className={Classes.Total} 
				style={{
					background:'#627d98', color:'#fff',
					fontWeight:600, height:'fit-content',
					display:'flex', justifyContent: 'center'
				}}
			>{row?.total ? row?.total : 0}</p>
		)
	};

	const SuccessRenderCell = (row) => {
		return (
			<p 
				className={Classes.Success} 
				style={{background:'#0A9154',color:'#fff',fontWeight:600,height:'fit-content'}}
			>{row?.success ? row?.success : 0}</p>
		)
	};

	const FailedRenderCell = (row) => {
		return (
			<p 
				className={Classes.Failed} 
				style={{background:'#e12d39',color:"#fff",fontWeight:600,height:'fit-content'}}
			>{row?.failed ? row?.failed : 0}</p>
		)
	};

	const CreatedByRenderCell = (row) => {
		return (
			<p style={{textAlign:'center'}}>
				{row?.createdBy ? row?.createdBy?.userName : 'N/A'}
			</p>
		)
	};

	const requestTypes = [
		'Create Product','Delete Product','Update Product',
		'Upload Images','Update Pricing', 'Update Product Images',
		'Update','Create','Delete','Upload','Images','Pricing','Product'
	];

	const createdFrom = [
		"Development",
		"Localhost",
		"Production"
	];

	let columns = [
        {
            field: 'groupId',
            headerName: 'Group Id',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
			filterable: false
        },
		{
			field: 'productUploadType',
			headerName: 'Upload Type',
			flex: 1.4,
			headerClassName:  'super-app-theme--header'
		},
        {
            field: 'createdAt',
            headerName: 'DATE',
            flex: 2,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'requestType',
            headerName: 'Request Type',
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
			enumValues: requestTypes,
            renderCell: (params) => ( <RequestTypeRenderCell {...params?.row} /> ),
			customRenderCell: RequestTypeRenderCell
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
            renderCell: (params) => ( <CreatedByRenderCell {...params?.row} /> ),
			customRenderCell: CreatedByRenderCell
        },
        {
            field: 'duration',
            headerName: 'Duration',
            flex: 1,
            headerClassName: 'super-app-theme--header',
			filterable: false,
            renderCell: (params) => ( <DurationRenderCell {...params?.row} />),
			customRenderCell: DurationRenderCell
        },
        {
            field: 'createdFrom',
            headerName: 'Created From',
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
            enumValues: createdFrom
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 0.7,
			headerAlign: 'center',
			align: 'center',
            headerClassName: 'super-app-theme--header',
			renderCell: (params) => ( <TotalRenderCell {...params?.row} /> ),
			customRenderCell: TotalRenderCell
        },
		{
            field: 'success',
            headerName: 'Success',
            flex: 0.8,
			headerAlign: 'center',
			align: 'center',
            headerClassName: 'super-app-theme--header',
			renderCell: (params) => ( <SuccessRenderCell {...params?.row} /> ),
			customRenderCell: TotalRenderCell
        },
		{
            field: 'failed',
            headerName: 'Failed',
            flex: 0.7,
			headerAlign: 'center',
			align: 'center',
            headerClassName: 'super-app-theme--header',
			renderCell: (params) => ( <FailedRenderCell {...params?.row} /> ),
			customRenderCell: FailedRenderCell
        }
    ];

	const requestTypesStyle = {
		'Delete Product': '#FFD79D',
		'Update Product': '#f1e46f',
	}

	return (
		<Fragment>
			<Table
				columns={columns}
				dataPosition={"product"}
				endpoint={'/productCreated/group'}
				rowId={'_id'}
				checkBoxSelection={false}
				tabName={'reportService'}
				service='reportService'
				setData={setReportServiceData}
				data={reportServiceData}
				navigateOnRowClickEndpoint={`/productsCreated`}
				mobileResponsive={true}
				exportEndpoint={'/exports/orders'}
				exportFileName={`Orders - ${new Date()}`}
				clickable={true}
			/>
		</Fragment>
	);
};

export default ProductsCreated;
