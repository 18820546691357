import React, {Fragment} from "react";
import Classes from './css/create.module.css';


const TaxRatesPrint = (props)=> {
    const { 
        taxRates,
        showProduct,
        showCustomerDetail,
        addressArray,
        selectedShippingAddress,
    } = props;

    const handleFirst2Decimal = (amount) => {
        let checkDecimal = countPlaces(amount)
        return checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2 ? amount : Math.trunc(amount*100)/100
    }

    const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

    const unstructureTaxLines = (dpoTaxLines) => {
        const unstruturedTaxLines = []
        for (let i = 0; i < dpoTaxLines.length; i++) {
            const dpoTaxLine = dpoTaxLines[i]
            unstruturedTaxLines.push(...(Object.values(...Object.values(dpoTaxLine))))
        }
        return unstruturedTaxLines
    }

    return (
        <Fragment>
                <div className={[Classes.Box,'box'].join(' ')}>
                    <div style={{padding:'10px 20px',borderBottom:'1px solid lightgray'}}>
                        <h6>Tax rates</h6>
                    </div> 

                    {showProduct.length !== 0 &&  (
                        <div style={{padding:'10px 20px',fontSize:'13px'}}>
                            {unstructureTaxLines(taxRates)?.map((taxes,index) => (
                                <div style={{padding:'5px'}} key={index}>
                                    <div>
                                        <p style={{marginRight:5}}>
                                            {showProduct?.shippingLine?.taxAmount && showProduct?.shippingLine?.taxAmount !== 0 && taxes?.hsnCode === "9965" ? 
                                            "Shipping HSN Code": "HSN Code"} ({taxes?.hsnCode})
                                        </p>

                                        {showCustomerDetail.length !== 0  && addressArray.length !== 0 && (selectedShippingAddress !== null) ? (
                                            <div>
                                                {selectedShippingAddress?.province === 'West Bengal' ? (
                                                    <>
                                                        <div className={Classes.TaxRatePrintFlexBetween}>
                                                            <p style={{padding:'0px 5px'}}>
                                                                CGST ({taxes?.cgstRate}%)
                                                            </p>
                                                            <p>&#8377;{handleFirst2Decimal(taxes?.cgstAmount)}</p>
                                                        </div>

                                                        <div className={Classes.TaxRatePrintFlexBetween}>
                                                            <p style={{padding:'0px 5px'}}>
                                                                SGST ({taxes?.sgstRate}%)
                                                            </p>
                                                            <p>&#8377;{handleFirst2Decimal(taxes?.sgstAmount)}
                                                            </p>
                                                        </div>
                                                    </>
                                                ): (
                                                    <div className={Classes.TaxRatePrintFlexBetween}>
                                                        <p style={{padding:'0px 5px'}}>
                                                            IGST({taxes?.cgstRate + taxes?.sgstRate}%)
                                                        </p>
                                                        <p>&#8377;{handleFirst2Decimal(taxes?.cgstAmount + taxes?.sgstAmount)}</p>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <div className={Classes.TaxRatePrintFlexBetween}>
                                                    <p style={{padding:'0px 5px'}}>
                                                        CGST ({taxes?.cgstRate}%)
                                                    </p>
                                                    <p>&#8377;{handleFirst2Decimal(taxes?.cgstAmount)}</p>
                                                </div>

                                                <div className={Classes.TaxRatePrintFlexBetween}>
                                                    <p style={{padding:'0px 5px'}}>
                                                        SGST ({taxes?.sgstRate}%)
                                                    </p>
                                                    <p>&#8377;{handleFirst2Decimal(taxes?.sgstAmount)}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {/* {showProduct?.shippingLine && (
                                <>
                                    {showProduct?.shippingLine?.taxAmount && showProduct?.shippingLine?.taxAmount !== 0 && (
                                        <div style={{padding:'10px'}}>
                                            <div>
                                                <p>Shipping HSN Code(9965)</p>
                                            </div>
                                            <div className={Classes.TaxRatesPopupFlexBetween}>
                                                <p style={{padding:'0px 15px'}}>GST(18%)</p>
                                                <p>&#8377;{showProduct?.shippingLine?.taxAmount}</p>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )} */}

                            <div 
                                style={{padding:'5px',fontWeight:'600'}}
                                className={Classes.TaxRatePrintFlexBetween}
                            >
                                <div>
                                    <p>Total Tax</p>
                                </div>
                                <div>
                                    <p>&#8377;{handleFirst2Decimal(showProduct?.TaxAmount)}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
        </Fragment>
    );
}


export default TaxRatesPrint;