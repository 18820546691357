import React, {Fragment} from "react";
import Classes from './css/draftOrderDetailPage.module.css';


const VendorDetails = (props)=> {
    const { draftOrder } = props;

    

    return (
        <Fragment>
            <div
                className={[
                    Classes.Box,'box','customerVertical',Classes.customerVertical
                ].join(' ')}
                style={{ marginTop:30 }}
            >
                {draftOrder?.PoVendorDetails && draftOrder?.PoVendorDetails?.length !== 0 ? (
                    <div style={{ display: 'flex' }}>
                        <div className={[Classes.containerBox,Classes.customerBox].join(' ')}>
                            <div>
                                <div className='mb-2'>
                                    <p className={Classes.contactTitle}>
                                        VENDOR NAME
                                    </p>
                                </div>

                                <div style={{ fontSize:14 }}>
                                    <div style={{ color:'#2c6ecb',display:'flex',flexDirection:'column' }}>
                                        <p style={{ marginRight:5 }}>
                                            {draftOrder?.PoVendorDetails[0]?.vendorName}
                                        </p>
                                        <p>{draftOrder?.PoVendorDetails[0]?.vendorGstNumber && `GST - ${draftOrder?.PoVendorDetails[0]?.vendorGstNumber}`}</p>
                                    </div>

                                </div>
                            </div>

                            {draftOrder?.PoVendorDetails?.length > 1 ? (
                                <div>
                                    <div>
                                        <div className='mb-2' style={{marginTop:8}}>
                                            <p className={Classes.contactTitle}>
                                                BILLING DETAILS
                                            </p>
                                        </div>

                                        <div style={{ fontSize:14 }}>
                                            <div style={{ display:'flex' }}>
                                                <p style={{ marginRight:5 }}>
                                                    {draftOrder?.PoVendorDetails[1]?.firstName}
                                                </p>
                                                <p>{draftOrder?.PoVendorDetails[1]?.lastName || ""}</p>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <p style={{ marginRight: '5px' }}>
                                                    {draftOrder?.PoVendorDetails[1]?.addressLine1}
                                                </p>
                                                {draftOrder?.PoVendorDetails[1]?.addressLine2 !== null
                                                    ? <p>{draftOrder?.PoVendorDetails[1]?.addressLine2}</p>
                                                    : ''
                                                }
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <p style={{ marginRight: '5px' }}>
                                                    {draftOrder?.PoVendorDetails[1]?.pincode}
                                                </p>
                                                <p>{draftOrder?.PoVendorDetails[1]?.city},</p>
                                            </div>

                                            <div>
                                                <p>{draftOrder?.PoVendorDetails[1]?.state?.toTitleCase()},</p>
                                            </div>
                                            <div style={{ display: 'flex' }}>

                                                <p>{draftOrder?.PoVendorDetails[1]?.country}</p>
                                            </div>
                                            <div>
                                                <p>{draftOrder?.PoVendorDetails[1]?.phone}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div style={{display:'flex',flexDirection:'row-reverse',width:'66%'}}>
                            {draftOrder?.PoLocationDetails && draftOrder?.PoLocationDetails?.length !== 0 ? (
                                <>
                                    {draftOrder?.PoLocationDetails?.map((location,index) => (
                                        <div 
                                            className={location?.addressType !== 'Billing' && Classes.ShippingAddressBox} 
                                            key={index}
                                            style={{width:'60%'}}
                                        >
                                            <div className={Classes.containerBox}>
                                                <div className='mb-2'>
                                                    <p className={Classes.AddressTitle}>
                                                        {location?.addressType === 'Billing' ? 
                                                            `DEPO24 ${location?.addressType?.toUpperCase()} ADDRESS` :
                                                            `WAREHOUSE ${location?.addressType?.toUpperCase()} ADDRESS`
                                                        } 
                                                    </p>
                                                </div>

                                                <div style={{ fontSize: '14px' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <p style={{ marginRight: '5px' }}>
                                                            {location?.firstName}
                                                        </p>
                                                        <p>{location?.lastName || ""}</p>
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <p style={{ marginRight: '5px' }}>
                                                            {location?.addressLine1}
                                                        </p>
                                                        {location?.addressLine2 !== null
                                                            ? <p>{location?.addressLine2}</p>
                                                            : ''
                                                        }
                                                    </div>

                                                    <div style={{ display: 'flex' }}>
                                                        <p style={{ marginRight: '5px' }}>
                                                            {location?.pincode}
                                                        </p>
                                                        <p>{location?.city},</p>
                                                    </div>

                                                    <div>
                                                        <p>{location?.state?.toTitleCase()},</p>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>

                                                        <p>{location?.country}</p>
                                                    </div>
                                                    <div>
                                                        <p>{location?.phone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : null}
                        </div>

                        {/* {draftOrder?.PoLocationDetails && draftOrder?.PoLocationDetails?.length !== 0 ? (
                            <div>
                                <div className={Classes.containerBox}>
                                    <div className='mb-2'>
                                        <p className={Classes.AddressTitle}>
                                            DEPO24 {draftOrder.PoLocationDetails[0]?.addressType?.toUpperCase()} ADDRESS
                                        </p>
                                    </div>

                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <p style={{ marginRight: '5px' }}>
                                                {draftOrder?.PoLocationDetails[0]?.firstName}
                                            </p>
                                            <p>{draftOrder?.PoLocationDetails[0]?.lastName}</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p style={{ marginRight: '5px' }}>
                                                {draftOrder?.PoLocationDetails[0]?.addressLine1}
                                            </p>
                                            {draftOrder?.PoLocationDetails[0]?.addressLine2 !== null
                                                ? <p>{draftOrder?.PoLocationDetails[0]?.addressLine2}</p>
                                                : ''
                                            }
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <p style={{ marginRight: '5px' }}>
                                                {draftOrder?.PoLocationDetails[0]?.pincode}
                                            </p>
                                            <p>{draftOrder?.PoLocationDetails[0]?.city},</p>
                                        </div>

                                        <div>
                                            <p>{draftOrder?.PoLocationDetails[0]?.state?.toTitleCase()},</p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p>{draftOrder?.PoLocationDetails[0]?.country}</p>
                                        </div>
                                        <div>
                                            <p>{draftOrder?.PoLocationDetails[0]?.phone}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null} */}
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
}


export default VendorDetails;