import { Fragment } from 'react';
import Classes from '../draftOrdersNew/css/draftOrderDetailPage.module.css';

const Aside = (props) => {
    const { draftOrder, notes, deliveryRemark } = props;

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-IN');
        }
    }

    

    const checkNullValues = (obj, text) => {
        try {
            for (const key in obj) {
                if (
                    key !== "createdAt" && key !== "updatedAt" &&
                    key !== "draftPurchaseOrderId" && key !== `dpo${text}DetailId`
                ) {
                    if (obj[key] !== null) { return true; }
                }
            }
            return false;
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Fragment>
            {draftOrder?.length !== 0 && draftOrder?.DpoVendorDetails?.length !== 0 && (
                <div className={[Classes.Box,'box'].join(' ')}>
                    <div style={{ borderBottom:'1px solid #DBDDE0' }} className={Classes.containerBox}>
                        <h6 className={Classes.asideCustomer}>From Warehouse</h6>
                        <div style={{ color:'#2c6ecb',display:'flex',flexDirection:'column',fontSize:14 }}>
                            <p style={{ marginRight:5 }}>{draftOrder?.DpoVendorDetails[0]?.vendorName}</p>
                            <p>GST - {draftOrder?.DpoVendorDetails[0]?.vendorGstNumber}</p>
                            <p>{draftOrder?.DpoVendorDetails[0]?.vendorCode}</p>
                        </div>
                    </div>
                    
                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                        {checkNullValues(draftOrder?.DpoVendorContactDetails, "VendorContact") !== false && (
                            <div className={Classes.containerBox}>
                                <p className={[Classes.asideHeaders, 'mb-3'].join(' ')} >CONTACT INFORMATION</p>
                                {draftOrder?.DpoVendorContactDetails !== null && (
                                    <div style={{ color:'#2c6ecb',fontSize:14 }}>
                                        <p>{`${draftOrder?.DpoVendorContactDetails?.firstName} ${draftOrder?.DpoVendorContactDetails?.lastName ? draftOrder?.DpoVendorContactDetails?.lastName: "" }`}</p>
                                        <p>{draftOrder?.DpoVendorContactDetails?.role}</p>
                                        <p>{draftOrder?.DpoVendorContactDetails?.email}</p>
                                        <p>{draftOrder?.DpoVendorContactDetails?.phoneNumber}</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {draftOrder?.DpoVendorDetails !== null && draftOrder?.DpoVendorDetails?.length !== 0 && 
                    draftOrder?.DpoVendorDetails?.map((vendor, index) => ( vendor?.addressLine1 && (
                        <div className={Classes.containerBox} style={{ borderBottom: '1px solid #DBDDE0' }}>
                            <p className={[Classes.asideHeaders,'mb-3'].join(' ')}>
                                {vendor?.addressType === "Shipping" ? "PICKUP" : "BILLING" } ADDRESS
                            </p>

                            <div key={index} style={{ fontSize: '14px' }}>
                                <p>{vendor?.firstName}{" "}{vendor?.lastName && vendor?.lastName}</p>
                                <p>{vendor?.addressLine1}</p>
                                <p>{vendor?.addressLine2 !== null ? vendor?.addressLine2 : null}</p>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ marginRight:5 }}>{vendor?.pincode}</p>
                                    {vendor?.city && <p>{vendor?.city},</p>}
                                </div>
                                <p>{vendor?.state && vendor?.state?.toTitleCase()}</p>
                                <p>{vendor?.phoneNumber && vendor?.phoneNumber}</p>
                            </div>
                        </div>
                    )))}
                </div>
            )}

            {draftOrder?.length !== 0 && draftOrder?.DpoLocationDetails.length !== 0 && (
                <div className={[Classes.Box,'box'].join(' ')}>
                    <div className={Classes.containerBox} style={{ borderBottom:'1px solid #DBDDE0' }}>
                        <h6 className={Classes.asideCustomer}>To Warehouse</h6>
                        <div style={{ color:'#2c6ecb',fontSize:14 }}>
                            <p style={{ marginRight:5 }}>
                                {draftOrder?.DpoLocationDetails[0]?.locationName}
                            </p>
                            <p>GST - {draftOrder?.DpoLocationDetails[0]?.locationGstNumber}</p>
                            <p>{draftOrder?.DpoLocationDetails[0]?.locationCode}</p>
                        </div>
                    </div>

                    {checkNullValues(draftOrder.DpoLocationContactDetails, "LocationContact") !== false && (
                        <div className={Classes.containerBox} style={{ borderBottom: '1px solid #DBDDE0' }}>
                            <p className={[Classes.asideHeaders,'mb-3'].join(' ')}>CONTACT INFORMATION</p>
                            {draftOrder?.DpoLocationContactDetails !== null && (
                                <div style={{ color:'#2c6ecb',fontSize:14 }}>
                                    <p>{draftOrder?.DpoLocationContactDetails?.firstName}{' '}
                                    {draftOrder?.DpoLocationContactDetails?.lastName && draftOrder?.DpoLocationContactDetails?.lastName}</p>
                                    <p>{draftOrder?.DpoLocationContactDetails?.role}</p>
                                    <p>{draftOrder?.DpoLocationContactDetails?.email}</p>
                                    <p>{draftOrder?.DpoLocationContactDetails?.phoneNumber}</p>
                                </div>
                            )}
                        </div>
                    )}

                    {draftOrder?.DpoLocationDetails?.length !== 0 && draftOrder?.DpoLocationDetails?.map((location, index) => (
                        <div className={Classes.containerBox} style={{ borderBottom:'1px solid #DBDDE0' }}>
                            <p className={[Classes.asideHeaders,'mb-3'].join(' ')}>{location?.addressType?.toUpperCase()} ADDRESS</p>
                            <div key={index} style={{ fontSize: '14px' }}>
                                <p>{location?.firstName}{' '}{location?.lastName && location?.lastName !== "null" && location?.lastName}</p>
                                <p>{location?.addressLine1}</p>
                                <p>{location?.addressLine2 !== null && location?.addressLine2}</p>
                                <div style={{ display:'flex' }}>
                                    <p style={{ marginRight:5 }}>{location?.pincode}</p>
                                    {location?.city && ( <p>{location?.city},</p> )}
                                </div>
                                <p>{location?.state && location?.state?.toTitleCase()}</p>
                                <p>{location?.phoneNumber && location?.phoneNumber}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {draftOrder?.length !== 0 && checkNullValues(draftOrder.DpoShipmentDetails, "Shipment") !== false && (
                <div className={[Classes.Box,Classes.asideMetafields,'box'].join(' ')}>
                    <h6 style={{ margin: '5px 0px' }}>Shipment Details</h6>
                    {draftOrder?.DpoShipmentDetails?.transporterName && (
                        <div style={{ display: 'flex',margin: '8px 0px' }}>
                            <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                Transporter Name:
                            </p>
                            <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                {draftOrder?.DpoShipmentDetails?.transporterName}
                            </p>
                        </div>
                    )}
                    
                    {draftOrder?.DpoShipmentDetails?.transporterType && (
                        <div style={{ display:'flex',margin:'8px 0px' }}>
                            <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                Transporter Type:
                            </p>
                            <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                {draftOrder?.DpoShipmentDetails?.transporterType}
                            </p>
                        </div>
                    )}

                    {draftOrder?.DpoShipmentDetails?.estimatedDispatchDate && (
                        <div style={{ display:'flex',margin:'8px 0px' }}>
                            <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                Estimated Dispatch Date:
                            </p>
                            <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                {formatDate(draftOrder?.DpoShipmentDetails?.estimatedDispatchDate)}
                            </p>
                        </div>
                    )}

                    {draftOrder?.DpoShipmentDetails?.estimatedReceivingDate && (
                        <div style={{ display:'flex',margin:'8px 0px' }}>
                            <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                Estimated Delivery Date:
                            </p>
                            <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                {formatDate(draftOrder?.DpoShipmentDetails?.estimatedReceivingDate)}
                            </p>
                        </div>
                    )}

                    {draftOrder?.DpoShipmentDetails?.freightCharges && (
                        <div style={{ display: 'flex', margin: '8px 0px' }}>
                            <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                Freight Charges:
                            </p>
                            <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                &#8377;{draftOrder?.DpoShipmentDetails?.freightCharges}
                            </p>
                        </div>
                    )}

                    {draftOrder?.DpoShipmentDetails?.biltyNumber && (
                        <div style={{ display: 'flex',margin: '8px 0px' }}>
                            <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                Bilty Number:
                            </p>
                            <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                {draftOrder?.DpoShipmentDetails?.biltyNumber}
                            </p>
                        </div>
                    )}

                    {draftOrder?.DpoShipmentDetails?.biltyDate && (
                        <div style={{ display: 'flex',margin: '8px 0px' }}>
                            <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                Bilty Date:
                            </p>
                            <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                {formatDate(draftOrder?.DpoShipmentDetails?.biltyDate)}
                            </p>
                        </div>
                    )}
                </div>
            )}

            {deliveryRemark && (
                <div className={Classes.Box} style={{ padding:'0.8rem 1.1rem' }} id='deliveryRemark'>
                    <h6 style={{ margin: 'auto 0px' }}>Delivery Remark</h6>
                    <p className={Classes.remarkBox}>{deliveryRemark}</p>
                </div>
            )}

            {notes && (
                <div className={Classes.Box} id='notes' style={{ padding:'0.8rem 1.1rem' }}>
                    <h6 style={{ margin:'auto 0px' }}>Notes</h6>
                    <p className={Classes.remarkBox}>{notes}</p>
                </div>
            )}

            {draftOrder?.length !== 0 && draftOrder?.documentsAttached?.length !== 0 && (
                <div className={Classes.Box} style={{ padding:10 }}>
                    <h6 style={{ margin:'auto 0px',padding:6 }} className='mb-3'>
                        Documents Attached
                    </h6>
                    <ul className="list-group mt-3">
                        {draftOrder?.documentsAttached?.map((fileName, index) => (
                            <a className="list-group-item" key={index} href={fileName}>
                                {decodeURIComponent(fileName.substring(fileName.lastIndexOf('/') + 1, fileName.lastIndexOf('?')))}
                            </a>
                        ))}
                    </ul>
                </div>
            )}
        </Fragment>
    )
};

export default Aside;