import { Fragment, useState } from 'react';
import Table from '../../../../components/table/table';
import { setCatalogues } from '../../../../store/catalogues';
import { useSelector } from 'react-redux';
import { Button, Chip, IconButton, Tooltip } from "@mui/material";
import SnackBar from '../../../../components/SnackBar';
import { FilePresent, Delete } from '@mui/icons-material';
import DeleteCatalogue from './deleteCatalogue';
import CatalogueForm from './catalogueForm';
import { useNavigate } from 'react-router-dom';

const EditIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" viewBox="0 0 24 24" fill="none" 
            stroke="currentColor" 
            stroke-width="2" stroke-linecap="round" 
            stroke-linejoin="round" 
            className="icon icon-tabler icons-tabler-outline icon-tabler-edit"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
            <path d="M16 5l3 3" />
        </svg>
    )
};

const Catalogues = () => {
    const [openForm, setOpenForm] = useState(false);
    const catalogues = useSelector((state) => state?.catalogues);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [reRender, setReRender] = useState(false);
    const [openDeleteCatalogue, setOpenDeleteCatalogue] = useState(false);
    const [catalogueId, setCatalogueId] = useState(null);
    const [catalogueData, setCatalogueData] = useState(null);

    const navigate = useNavigate();

    const handleCloseForm = () => {
        setOpenForm(false);
        setCatalogueData(null);
    }

    const handleOpenCatalogueForm = () => {
        setOpenForm(true);
    }

    const handleClick = (event) => {
        event.stopPropagation(); // Prevents the click from bubbling up
    }

    const handleOpenDeleteCatalogue = (event, Id) => {
        event.stopPropagation();
        setOpenDeleteCatalogue(true);
        setCatalogueId(Id);
    }

    const handleCloseDeleteCatalogue = () => {
        setOpenDeleteCatalogue(false);
        setCatalogueId(null);
    }

    const handleOpenUpdateCatalogue = (event, data) => {
        event.stopPropagation();
        setOpenForm(true);
        setCatalogueData(data);
    }

    const TagsRenderCell = (row) => {
        return row?.tags ? (
            <div>
                {row?.tags?.slice(0,2)?.map((tag, index) => (
                    <Chip key={index} label={tag} style={{ margin: '3px' }} />
                ))}
                {row?.tags?.length > 2 ? (
                    <Tooltip title={
                        <div>
                            {row?.tags?.slice(2)?.map((tag, index) => (
                                <p>{tag}, </p>
                            ))}
                        </div>
                    } arrow>
                    <Chip size="small" label={`+ ${row?.tags?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity:'0.8'}} />
                    </Tooltip>
                ) : null}
            </div>
        ) : (
            <Chip label={row?.tags} style={{ margin: '3px' }} />
        )
    };
    
    const ActionsRenderCell = (row) => {
        return (
            <div className='gap-3'>
                <Tooltip title="Edit Catalogue" onClick={(e) => handleOpenUpdateCatalogue(e, row)}>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Catalogue" onClick={(e) => handleOpenDeleteCatalogue(e, row?.id)}>
                    <IconButton>
                        <Delete style={{ color: 'red' }} />
                    </IconButton>
                </Tooltip>
            </div>
        )
    };

    const DocumentRenderCell = (row) => {
        return (
            <Chip
                size="small"
                icon={<FilePresent style={{ color: '#616161' }} />}
                style={{ color: '#000000de' }}
                clickable
                label={`${row?.name}-cataloguePdf`}
                component="a"
                href={row?.catalogue_pdf}
                target="_blank"
                onClick={handleClick}
            />
        )
    };

    const CoverPhotoRenderCell = (row) => {
        return (
            <Chip
                size="small"
                icon={<FilePresent style={{ color: '#616161' }} />}
                style={{ color: '#000000de' }}
                clickable
                label={`${row?.name}-coverPhoto`}
                component="a"
                href={row?.cover_photo}
                target="_blank"
                onClick={handleClick}
            />
        )
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
			columnName: 'name',
			tableName: 'catalogues'
        },
        {
            field: 'cover_photo',
            headerName: 'Cover Photo',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable: false, 
            renderCell: (params) => ( <CoverPhotoRenderCell {...params?.row} />),
            customRenderCell: CoverPhotoRenderCell
        },
        {
            field: 'catalogue_pdf',
            headerName: 'Catalogue PDF',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable: false, 
            renderCell: (params) => ( <DocumentRenderCell {...params?.row} />),
            customRenderCell: DocumentRenderCell
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.7,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
			columnName: 'type',
			tableName: 'catalogues'
        },
        {
            field: 'tags',
            headerName: 'Tags',
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <TagsRenderCell {...params?.row} /> ),
            customRenderCell: TagsRenderCell,
        },
        {
            field: 'date_of_uploaded',
            headerName: 'Date Of Uploaded',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'uploaded_by',
            headerName: 'Uploaded By',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
			columnName: 'name',
			tableName: 'admins'
        },
        {
            field: 'times_of_download',
            headerName: 'Download Count',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => ( <ActionsRenderCell {...params?.row} /> ),
            customRenderCell: ActionsRenderCell
        },
    ];

    return (
        <Fragment>
            <div className='d-flex justify-content-end mb-2 gap-2'>
                <Button 
                    onClick={() => navigate('/settings/downloadedReports')}
                    type='button' 
                    variant="contained" 
                >
                    Downloaded Reports
                </Button>

                <Button 
                    onClick={handleOpenCatalogueForm} 
                    type='button' 
                    variant="contained" 
                >
                    Create Catalogue
                </Button>
            </div>
        
            <Table
                columns={columns}
                dataPosition={"catalogues"}
                endpoint={'/catalogues'}
                rowId={'id'}
                checkBoxSelection={false}
                service={'catalogues'}
                tabName={'Catalogues'}
                setData={setCatalogues}
                data={catalogues}
                navigateOnRowClickEndpoint={`/settings/catalogues`}
                version="version-2"
                mobileResponsive={true}
                exportEndpoint={'/exports/catalogues'}
                exportFileName={`Catalogues - ${new Date()}`}
                reRender={reRender}
            />

            <CatalogueForm
                open={openForm}
                handleClose={handleCloseForm}
                setSnackBar={setSnackBar}
                setReRender={setReRender}
                catalogueData={catalogueData}
            />

            <DeleteCatalogue 
                open={openDeleteCatalogue}
                handleClose={handleCloseDeleteCatalogue}
                catalogueId={catalogueId}
                setSnackBar={setSnackBar}
                setReRender={setReRender}
            />

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
};

export default Catalogues;