import { handleNegativeZero } from "../../../../utility/calculation";

const handleMrpCalculation = (MRP, discount, GST) => {
    const Discount = (MRP-(discount * (1+(GST/100))))/MRP;
    const amount =  Discount * 100;
    const result = Math.floor(amount * 100) / 100;
    const limitAmount = MRP / (1+(GST/100));
    return { result, limitAmount, Discount }
}

export const handleGstRateFile = (gst, setLimitPrice, setProductDetails, productDetails) => {
    const MRP = parseFloat(document.getElementById('mrp')?.value);
    const discounts = {
        listDiscount: parseFloat(document.getElementById('listDiscount')?.value),
        retailerDiscount : parseFloat(document.getElementById('retailerDiscount')?.value),
        specialRetailerDiscount : parseFloat(document.getElementById('specialRetailerDiscount')?.value),
        partnerPriceDiscount : parseFloat(document.getElementById('partnerPriceDiscount')?.value),
        promoterPriceDiscount : parseFloat(document.getElementById('promoterPriceDiscount')?.value),
        interiorDiscount : parseFloat(document.getElementById('interiorDiscount')?.value),
        oemDiscount : parseFloat(document.getElementById('oemDiscount')?.value),
        endUserDiscount : parseFloat(document.getElementById('endUserDiscount')?.value)
    }
    let discountUpdate = {};
    Object.keys(discounts).forEach(key => {
        if (!isNaN(discounts[key])) {
            const priceData = handleMrpCalculation(MRP, discounts[key], parseFloat(gst));
            const keyName1 = discountType[key][0];
            const keyName2 = discountType[key][1];
            if (priceData?.Discount < 0) {
                discountUpdate[keyName1] = true;
                setLimitPrice(Math.round(priceData?.limitAmount * 100) / 100);
            } else {
                discountUpdate[keyName1] = false;
                discountUpdate[keyName2] = priceData?.result;
            } 
        }
    });
    productDetails?.length && setProductDetails([{...productDetails[0], ...discountUpdate }]);
}


const discountType = {
    listDiscount: ["listPriceError", "listingDiscount"],
    retailerDiscount: ["retailPriceError", "retailDiscount"],
    specialRetailerDiscount: ["specialRetailPriceError", "specialRetailDiscount"],
    interiorDiscount: ["interiorPriceError", "interiorDiscount"],
    partnerPriceDiscount: ["partnerPriceError", "partnerPriceDiscount"],
    promoterPriceDiscount: ["promoterPriceError", "promoterPriceDiscount"],
    oemDiscount: ["oemPriceError", "oemDiscount"],
    endUserDiscount: ["endUserPriceError", "endUserDiscount"]
}

export const handleDiscountFile = (event, setLimitPrice, setProductDetails, productDetails) => {
    const keyName1 = discountType[event?.target?.id][0]
    const keyName2 = discountType[event?.target?.id][1]
    if (event.target.value) {
        const MRP = parseFloat(document.getElementById('mrp')?.value);
        const discountedUnitPrice = parseFloat(event.target.value)
        const GST = parseInt(document.getElementById('gstRate').value);
        // handle negative zero is used because -0 < 0 is true in javascript so we need to handle it
        const differenceOfMrpAndDiscountedUnitPriceInclGst = handleNegativeZero(
          Math.round((MRP - discountedUnitPrice * (1 + GST / 100)) * 100) / 100
        );
        const discountPercentage = Math.round((((MRP-(discountedUnitPrice * (1+(GST/100))))/MRP) * 100) * 100)/100;
        const listAmount = MRP / (1+(GST/100));
        if (differenceOfMrpAndDiscountedUnitPriceInclGst < 0) {
            setLimitPrice(Math.round(listAmount * 100) / 100);
            productDetails?.length && setProductDetails([{...productDetails[0], [keyName1]: true}]);
        }else {
            productDetails?.length && setProductDetails([{...productDetails[0], [keyName1]: false, [keyName2]: discountPercentage, [event.target.name]: discountedUnitPrice}]);
        }
    } else {
        productDetails?.length && setProductDetails([{...productDetails[0], [keyName1]: false, [keyName2]: ''}]);
    }   
}

export const handleMRPFile = (setLimitPrice, setProductDetails, productDetails, hsnCode, gst) => {
    const MRP = parseFloat(document.getElementById('mrp')?.value);
    const GST = gst ? gst : parseInt(document.getElementById('gstRate')?.value);
    const discounts = {
        listDiscount: parseFloat(document.getElementById('listDiscount')?.value),
        retailerDiscount : parseFloat(document.getElementById('retailerDiscount')?.value),
        specialRetailerDiscount : parseFloat(document.getElementById('specialRetailerDiscount')?.value),
        partnerPriceDiscount : parseFloat(document.getElementById('partnerPriceDiscount')?.value),
        promoterPriceDiscount : parseFloat(document.getElementById('promoterPriceDiscount')?.value),
        interiorDiscount : parseFloat(document.getElementById('interiorDiscount')?.value),
        oemDiscount : parseFloat(document.getElementById('oemDiscount')?.value),
        endUserDiscount : parseFloat(document.getElementById('endUserDiscount')?.value)
    }

    let discountUpdate = {};
    Object.keys(discounts).forEach(key => {
        if (!isNaN(discounts[key])) {
            const priceData = handleMrpCalculation(MRP, discounts[key], GST);
            const keyName1 = discountType[key][0];
            const keyName2 = discountType[key][1];
            if (priceData?.Discount < 0) {
                discountUpdate[keyName1] = true
                setLimitPrice(priceData?.limitAmount)
            } else {
                discountUpdate[keyName1] = false;
                discountUpdate[keyName2] = priceData?.result;
            }
        }
    });
    if (hsnCode) discountUpdate['hsnCode'] = hsnCode;
    if (gst) discountUpdate['gst'] = gst;
    productDetails?.length && setProductDetails([{...productDetails[0], ...discountUpdate }]);
}


export const priceTypesDiscountMap = {
  listing: "listingDiscount",
  retail: "retailDiscount",
  specialRetail: "specialRetailDiscount",
  interior: "interiorDiscount",
  oem: "oemDiscount",
  endUser: "endUserDiscount",
  partner: "partnerPriceDiscount",
  promoter: "promoterPriceDiscount",
};

/**
 * Handles the blur event for price inputs.
 * 
 * @param {Event} event - The blur event object.
 * @param {string} pricesType - The type of prices being handled. Either 'defaultPrices',
 *  'districtBasedPrices', or 'franchiseBasedPrices'.
 * @param {string} handledPriceCardId - The ID of the price card being handled.
 * @returns {void}
 */
export const handlePriceBlur = ({event, pricesType, priceCardId, payloadToUpload, setPayloadToUpload}) => {
  const inputFieldName = event?.target?.name;
  // this map is specically due to different naming convention for partner and promoter discount
  // priceTypes object is used to map the price type with the discount name being edited
  const newPricesObj = Object.keys(priceTypesDiscountMap)
    ?.filter((priceType) => {
      if (inputFieldName === "mrp" || inputFieldName === "gst") {
        return true; // if input field is mrp then return all price types
      } else {
        return priceType === inputFieldName; // else return only the price type being edited
      }
    })
    ?.map((priceType) => {
      let discountedProductPrice;
      if (inputFieldName === "mrp" || inputFieldName === "gst") {
        discountedProductPrice =
          +payloadToUpload?.[pricesType]?.[priceType + "Price"];
      } else {
        discountedProductPrice = +event?.target?.value;
      }
      const discountNameBeingEdited = priceTypesDiscountMap[priceType];
      if (discountedProductPrice) {
        let MRP;
        let GST;
        if (inputFieldName === "mrp") {
          MRP = +event?.target?.value;
        } else {
          MRP = +payloadToUpload?.defaultPrices?.mrp;
        }
        if (inputFieldName === "gst") {
          GST = +event?.target?.value;
        } else {
          GST = +payloadToUpload?.defaultPrices?.gst;
        }
        // handle negative zero is used because -0 < 0 is true in javascript so we need to handle it
        const discountPercentage = +(
          ((MRP - discountedProductPrice * (1 + GST / 100)) / MRP) *
          100
        ).toFixed(2);
        const mrpExclusiveGst = MRP / (1 + GST / 100);
        if (handleNegativeZero(discountPercentage) < 0) {
          const limitPrice = Math.round(mrpExclusiveGst * 100) / 100;
          return {
            [`${priceType}Price`]: undefined,
            [discountNameBeingEdited]: undefined,
            errors: {
              [`${priceType}PriceError`]: `Please enter price less than ${limitPrice}!`,
            },
          };
        } else {
          return {
            [`${priceType}Price`]: +discountedProductPrice,
            [discountNameBeingEdited]: +discountPercentage,
            errors: {
              [`${priceType}PriceError`]: undefined,
            },
          };
        }
      } else {
        // if price is empty then set it, and discount to empty
        return {
          [`${priceType}Price`]: undefined, // if price is empty then set it to empty
          [discountNameBeingEdited]: undefined, // if price is empty then set discount to empty
          errors: {
            [`${priceType}PriceError`]: undefined,
          },
        };
      }
    })
    .reduce((acc, curr) => {
      const errors = {
        ...acc?.errors,
        ...curr?.errors,
      };
      return {
        ...acc,
        ...curr,
        errors,
      };
    }, {});
  if (inputFieldName === "mrp") {
    setPayloadToUpload((prevPayloadToUpload) => {
      return {
        ...prevPayloadToUpload,
        [pricesType]: {
          ...prevPayloadToUpload?.[pricesType],
          ...newPricesObj,
          errors: {
            ...prevPayloadToUpload?.[pricesType]?.errors,
            ...newPricesObj?.errors,
          },
          mrp: +event?.target?.value,
        },
      };
    });
    handleParentProductMRP({payloadToUpload, setPayloadToUpload, mrp: +event?.target?.value});
  } else if (inputFieldName === "gst") {
    setPayloadToUpload((prevPayloadToUpload) => {
      return {
        ...prevPayloadToUpload,
        [pricesType]: {
          ...prevPayloadToUpload?.[pricesType],
          ...newPricesObj,
          errors: {
            ...prevPayloadToUpload?.[pricesType]?.errors,
            ...newPricesObj?.errors,
          },
          gst: +event?.target?.value,
        },
      };
    });
  } else {
    setPayloadToUpload((prevPayloadToUpload) => {
      if (pricesType === "defaultPrices") {
        const defaultPrices = {
          ...prevPayloadToUpload,
          [pricesType]: {
            ...prevPayloadToUpload?.[pricesType],
            ...newPricesObj,
            errors: {
              ...prevPayloadToUpload?.[pricesType]?.errors,
              ...newPricesObj?.errors,
            },
          },
        };

        // delete the price and discount for undefined values
        Object.keys(defaultPrices?.[pricesType])?.forEach((key) => {
          if (
            defaultPrices?.[pricesType]?.[key] === undefined 
            || defaultPrices?.[pricesType]?.[key] === ""
          ) {
            delete defaultPrices?.[pricesType]?.[key];
          }
        });

        return defaultPrices;
      } else {
        // districtBasedPrices or franchiseBasedPrices
        let districtOrFranchiseBasedPrices = {
          ...prevPayloadToUpload,
          [pricesType]: prevPayloadToUpload?.[pricesType]?.map((prevPrice) => {
            if (prevPrice?.priceCardId === priceCardId) {
              return {
                ...prevPrice,
                ...newPricesObj,
                errors: {
                  ...prevPrice?.errors,
                  ...newPricesObj?.errors,
                },
              };
            }
            return prevPrice;
          }),
        };

        // delete the price and discount for undefined values
        Object.keys(districtOrFranchiseBasedPrices?.[pricesType])?.forEach((key) => {
          if (
            districtOrFranchiseBasedPrices?.[pricesType]?.[key] === undefined 
            || districtOrFranchiseBasedPrices?.[pricesType]?.[key] === ""
          ) {
            delete districtOrFranchiseBasedPrices?.[pricesType]?.[key];
          }
        });

        return districtOrFranchiseBasedPrices;
      }
    });
  }
};

//we can't set parent product MRP greater than its sum of all childMRP
export const handleParentProductMRP = ({
  payloadToUpload,
  setPayloadToUpload,
  mrp,
}) => {
  const parentMRP = mrp || +payloadToUpload?.defaultPrices?.mrp;
  //child Products and Parent MRP must exist
  if (payloadToUpload?.childProducts?.length !== 0 && parentMRP) {
    console.log({
      childProducts: payloadToUpload?.childProducts,
    });
    const sumOfChildMRP = +payloadToUpload?.childProducts?.reduce(
      (total, childProduct) => {
        if (+childProduct?.mrp)
          total =
            +total + +childProduct?.mrp * +childProduct?.childProductQuantity;

        return total;
      },
      0
    );

    if (parentMRP > sumOfChildMRP) {
      const limitPrice = Math.floor(sumOfChildMRP);
      setPayloadToUpload((prevPayloadToUpload) => {
        return {
          ...prevPayloadToUpload,
          defaultPrices: {
            ...prevPayloadToUpload?.defaultPrices,
            errors: {
              ...prevPayloadToUpload?.defaultPrices?.errors,
              mrpPriceError: `Please enter price less than ${limitPrice}!`,
            },
          },
        };
      });
    } else {
      setPayloadToUpload((prevPayloadToUpload) => {
        return {
          ...prevPayloadToUpload,
          defaultPrices: {
            ...prevPayloadToUpload?.defaultPrices,
            errors: {
              ...prevPayloadToUpload?.defaultPrices?.errors,
              mrpPriceError: undefined,
            },
          },
        };
      });
    }
  }
};