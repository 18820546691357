import React, { Fragment, useEffect, useState, forwardRef, useMemo } from 'react';
import Classes from '../product/css/product.module.css';
import axiosInstance from '../../../utility/axios-instance';
import { useSelector, useDispatch } from 'react-redux';
import Permission from '../../auth/permissions';
import NewPagination from '../../../components/newPagination';
import Spinner from '../../../components/spinner';
import { Paper, Tooltip } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { setProducts } from '../../../store/productsSlice';
import { services } from "../../../utility/checkRoleBasedPermission";
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { statusOnlyOperators, brandOnlyOperators, timestampOnlyOperators, SearchInput  } 
from '../../../components/products/productComponents';
import EditMinMaxQtyProduct from './editMinMaxQtyProducts';
import { 
  GridToolbarColumnsButton, GridToolbarContainer, 
  GridToolbarDensitySelector,
  GridToolbarFilterButton 
} from '@mui/x-data-grid';
import { TextField, Autocomplete, Button, Switch } from "@mui/material";
import { downloadCsvFromS3SignedUrl } from "../../../utility/downloadCSV";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSearchParams } from 'react-router-dom';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AutoComplete = ({franchises, setFranchise, setPageNo}) => {
  return (
    <Autocomplete
      disablePortal
      id='franchises'
      options={franchises || []}
      size='small'
      sx={{ width: 200 }}
      ListboxProps={{sx: { fontSize:14}}}
      onChange={(event, newValue) => {
        if (newValue === null) {
          setPageNo(1);
          setFranchise(null);
          return;
        }
        setFranchise(newValue?.Id);
        setPageNo(1);
      }}
      renderInput={(params) => (
        <TextField {...params} label='Franchises' />
      )}
    />
  );
}

const LocationsAutoComplete = ({setLocCode, franchise, setPageNo}) => {
  const [locationCodes, setLocationCodes] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`/pos/fetchFranchise/${franchise}`)
      .then((res) => {
        setLocationCodes(res?.data?.data?.locations) 
      })
  }, [franchise]);

  return (
    locationCodes?.length !== 0 && 
    <Autocomplete
      size='small'
      disablePortal
      multiple
      id="locationCode"
      name="locationCode"
      options={locationCodes || []}
      getOptionLabel={(locCode) => locCode}
      sx={{ width: 160 }}
      ListboxProps={{sx: { fontSize:14}}}
      onChange={(event, newValue) => {
        if (newValue === null) { setPageNo(1); setLocCode(null); return; }
        setPageNo(1);
        setLocCode(newValue);
      }}
      renderInput={(params) => 
        <TextField {...params} label="Location Code" />
      }
    />
  );
}

const colorFilters = [
  { title: "Maroon - Min Qty", color: "maroon" },
  { title: "Orange - Max Qty", color: "orange" },
  { title: "Red - Both", color: "red" }
];

const ColorAutoComplete = ({ setColor, color, setPageNo}) => {
  return (
    <Autocomplete
      disablePortal
      id='colorFilter'
      options={colorFilters }
      size='small'
      sx={{ width: 160}}
      ListboxProps={{sx: { fontSize:14}}}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => {
        const { title, color } = option;
        return (
          <span {...props} style={{ color: color }}>
            {title}
          </span>
        );
      }}
      onChange={(event, newValue) => {
        if (newValue === null) {
          setPageNo(1);
          setColor(null);
          return;
        }
        setPageNo(1);
        setColor(newValue?.title);
      }}
      renderInput={(params) => (
        <TextField {...params} label='Color' />
      )}
    />
  );
}


const CustomToolbar = ({ 
  searchFor, handleSearch,franchises, setFranchise, setColor, color, setLocationCode, franchise, 
  setIncludingQty, includingQty, locationCode, setPageNo
}) => {
  const handleChange = (event) => {
    setIncludingQty(event.target.checked)
  }

  return (
    <GridToolbarContainer style={{ paddingBottom: '4px' }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <div
        className={Classes.SearchBox}
        style={{ border: 'none', marginLeft: 'auto',display:'flex' }}
      >
        {locationCode && (
          <FormControlLabel
            control={<Switch checked={includingQty}  onChange={handleChange} name="jason" />}
            label="Including Franchise"
          />
        )}
        <ColorAutoComplete setColor={setColor} color={color} setPageNo={setPageNo}/>
        {franchise && (
          <LocationsAutoComplete setLocCode={setLocationCode} franchise={franchise} setPageNo={setPageNo}/>
        )}
        <AutoComplete franchises={franchises} setFranchise={setFranchise} setPageNo={setPageNo}/>
        <SearchInput searchFor={searchFor} handleSearch={handleSearch} width={200}/>
      </div>
    </GridToolbarContainer>
  );
}

const FranchiseInventoryManagement = (props) => {
  const [searchProduct, setSearchProduct] = useState('');
  const [pageNo,setPageNo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
  const initialFilterState={ items: [{ field: 'active', operator: '=', value: true }] }
  const [filters, setFilters] = useState(initialFilterState?.items || []);
  const [sort, setSort] = useState( { field: 'productName', sort: 'asc' });
  const [open,setOpen] = useState(false);
  const [reRender,setReRender] = useState(false);
  const [franchises,setFranchises] = useState([]);
  const [franchise,setFranchise] = useState(null);
  const [color,setColor] = useState(null);
  const [locationCode, setLocationCode] = useState(null);
  const [includingQty, setIncludingQty] = useState(false);

  const products = useSelector((state) => state.products);
  const pinTab = useSelector((state) => state.pinTab);
  const dispatch = useDispatch();
  const [editMinMaxQtyProducts, setEditMinMaxQtyProducts] = useState({
    productId: '',
    minQty: '',
    maxQty: '',
    sku: ''
  })
  const [searchParams, setSearchParams] = useSearchParams();

  const showEditMinMax = (e, productId, minQty, maxQty, sku) => {
    setOpen(true);
    setEditMinMaxQtyProducts((prevDetails) => {
      return { ...prevDetails, "productId":productId, "minQty":minQty ? minQty: '', "maxQty":maxQty ? maxQty: '', "sku": sku }
    });
  }

  const handleClose = () => {
    setEditMinMaxQtyProducts((prevDetails) => {
      return { ...prevDetails, "productId":" ", "minQty":" ", "maxQty":" ", "sku": "" }
    });
    setOpen(false)
  }

  const colorsData = (params) => {
    return params?.row?.minQty > params?.row?.totalAvailableQuantity &&  
    params?.row?.totalAvailableQuantity > params?.row?.maxQty ? 'red' :
    params?.row?.minQty > params?.row?.totalAvailableQuantity ? "maroon": 
    params?.row?.totalAvailableQuantity > params?.row?.maxQty ? "orange" : '#000'
  }

  let columns = [
    {
      field: 'image', 
      filterable: false, 
      headerName: 'IMAGE', 
      flex: 0.4, 
      headerClassName: 'super-app-theme--header', 
      sortable: false,
      renderCell: (params) => (
        <img
          src={
            params.value !== undefined ? params.value : ''
          }
          alt='icon'
          className={Classes.ProductImages}
        />
      )
    },
    {
      field: 'sku',
      filterable: true,
      headerName: 'SKU',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.sku} placement="top">
          <p style={{ color: colorsData(params) }}>{params?.row?.sku}</p>
        </Tooltip>
      )
    },
    {
      field: 'productName',
      filterable: true,
      headerName: 'PRODUCT',
      flex: 1.8,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.productName} placement="top">
          <p style={{ color: colorsData(params) }}>{params?.row?.productName}</p>
        </Tooltip>
      )
    },
    { 
      field: 'totalAvailableQuantity',
      filterable: true,
      headerName: 'AVL QUANTITY',
      flex: 0.7,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: true,
      renderCell: (params) => (
        <div style={{ width:'100%', textAlign:'center', color: colorsData(params) }}>
          {params?.row?.totalAvailableQuantity}
        </div>
      )
    },
    {
      field: 'minQty',
      filterable: true,
      headerName: 'MIN QUANTITY',
      flex: 0.7,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: true,
      renderCell: (params) => (
        <div style={{ width:'100%', textAlign:'center', color: colorsData(params) }}>
          {params?.row?.minQty ? params?.row?.minQty : "N/A"}
        </div>
      )
    },
    {
      field: 'maxQty',
      filterable: true,
      headerName: 'MAX QUANTITY',
      flex: 0.7,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: true,
      renderCell: (params) => (
        <div style={{ width:'100%', textAlign:'center', color: colorsData(params) }}>
          {params?.row?.maxQty ? params?.row?.maxQty : "N/A"}
        </div>
      )
    },
    {
      field: 'edit',
      filterable: false,
      headerName: '',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: (params) => (
        <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
          <Permission service={services?.franchiseOwner} permission="update">
            <BorderColorIcon sx={{cursor : 'pointer', color : 'gray',marginLeft:"10px"}}
              fontSize='small' 
              onClick={(e) => showEditMinMax(e, params?.row?.pgDbProductId, params?.row?.minQty, params?.row?.maxQty, params?.row?.sku)}
            />
          </Permission>
        </div>
      )
    },
  ];

  document.addEventListener('mouseup', function (e) {
    let container = document.getElementById('modalOutline');
    if (container !== null) {
      if (!container.contains(e.target)) {
        container.style.border = '1px solid rgb(206, 212, 218)';
      }
    }
  });

  const handleProduct = async (event) => {
    setPageNo(1);
    setSearchProduct(event?.target?.value);
  }

  const fetchProduct = async (type) => {
    setLoading(true);
    const filtersForQuery = [...filters]
    if(filtersForQuery?.[0]?.field === 'active'){
      if(filtersForQuery?.[0]?.value === 'Active'){
        filtersForQuery[0].value = true
      }else if(filtersForQuery?.[0]?.value === 'Draft'){
        filtersForQuery[0].value = false
      }
    }
    const filtersColor = {
      "Maroon - Min Qty": 'minQty', 
      "Orange - Max Qty": 'maxQty',
      "Red - Both": 'both'
    }  
    const params = {
      page: type,
      text: searchProduct,
      franchiseId: franchise ? franchise :'',
      dashboard: franchise ? 'productManagement' : '',
      sort: JSON.stringify(sort) || null,
      colorFilter: filtersColor[color],
      locationCode: locationCode ? locationCode : '',
      includingQty: includingQty,
      ...((filtersForQuery?.[0]?.value || filtersForQuery?.[0]?.value === false) && { filters: JSON.stringify(filtersForQuery) || null }),
    };

    await axiosInstance
      .get(`/product`, {
        params,
      })
      .then((res) => {
        setPageNo(null);
        dispatch(setProducts(res.data.data));
      });
    setLoading(false);
  };

  const handleSnackBarClose = () => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: false }
    });
  };

  useEffect(() => {
    // fetch Product
    if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Products List')) {
      searchParams.set("page", 1);
      setSearchParams(searchParams);
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get('page');
      fetchProduct(pageNo ? pageNo : page ? page : 1);
    }
  }, [sort, filters, reRender, franchise, color, locationCode, includingQty, searchProduct]);

  columns =  useMemo(() =>
      columns.map((col) => {
        if (col.field === 'brandName') {
          return {
            ...col,
            filterOperators: brandOnlyOperators,
          }
        }else if(col.field === 'createdAt' || col.field === 'updatedAt'){
          return {
            ...col,
            filterOperators: timestampOnlyOperators,
          }
        } else if(col.field === 'active'){
          return {
            ...col,
            filterOperators: statusOnlyOperators,
          }
        }
        return col;
      }),
    [],
  );

  useEffect(() => {
    const fetchFranchises = async () => {
      try {
        const response = await axiosInstance(`/pos/fetchAllFranchises?withId=${true}`);
        const franchises = await response?.data?.data;
        setFranchises(franchises);
      } catch (error) {
        console.error('Error fetching franchises:', error);
      }
    };

    fetchFranchises();
  }, []);
  
  const handleExport = (event) => {
    event.preventDefault();
    setLoading(true);

    const filtersForQuery = [...filters]
    if(filtersForQuery?.[0]?.field === 'active'){
      if(filtersForQuery?.[0]?.value === 'Active'){
        filtersForQuery[0].value = true
      }else if(filtersForQuery?.[0]?.value === 'Draft'){
        filtersForQuery[0].value = false
      }
    }

    const filtersColor = {
      "Maroon - Min Qty": 'minQty', 
      "Orange - Max Qty": 'maxQty',
      "Red - Both": 'both'
    }

    const params = {
      franchiseId: franchise ? franchise : '',
      text: searchProduct,
      sort: JSON.stringify(sort) || null,
      dashboard: franchise ? 'productManagement' : '',
      colorFilter: filtersColor[color],
      locationCode: locationCode ? locationCode : '',
      includingQty: includingQty,
      ...((filtersForQuery?.[0]?.value || filtersForQuery?.[0]?.value === false)  && { filters: JSON.stringify(filtersForQuery) || null }),
    };
    axiosInstance
      .get(`/exports/inventoryManagement`,{
        params
      })
      .then((result) => {
        const signedUrl = result?.data?.signedUrlOfObject;
        downloadCsvFromS3SignedUrl(signedUrl, `Products export - ${new Date()}`);
        const successMessage = "Products exported successfully!"
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error,"error")
        const errorMessage = error?.response?.data?.message
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
        });
      })
  }

  return (
    <Fragment>
      <div id="color">
        <Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
          <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
            {snackBar?.message}
          </Alert>
        </Snackbar>

        <div
          className="alert alert-primary p-2"
          data-file-upload-alert role="alert"
          style={{ "display": "none" }}
          id='updateNotification'
        >
          File Successfully Updated
        </div>

        <div style={{display:'flex',justifyContent:'flex-end',marginTop:-25}}>
          <Permission service="products" permission="read">
            <Button
              onClick={handleExport}
              variant='contained'
              color='success'
              style={{textTransform:'capitalize',padding:"4px 12px"}}
              sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
              startIcon={<FileDownloadIcon />}
            >
              Export
            </Button>
          </Permission>
        </div>

        <div className={Classes.Container}>
          <div className={Classes.ProductContainer}>
            <div className="d-block d-md-none">
              <div
                className={Classes.ProductHeader}
                style={{ backgroundColor: '#243750', borderRadius: '5px', color: '#fff', paddingTop: 6 }}
              >
                <div style={{ width: '54%', margin: 'auto 25px auto 15px' }}>
                  <p>PRODUCT</p>
                </div>

                <div style={{ width: '14%', margin: 'auto' }}>
                  <p>STATUS</p>
                </div>

                <div style={{ width: '14%', margin: 'auto' }}>
                  <p>SKU</p>
                </div>

                <div style={{ width: '10%', margin: 'auto' }}>
                  <p>BRAND</p>
                </div>
              </div>

              {products?.products?.length !== 0 && products?.products?.map((product) => (
                <div
                  key={product?.variantId}
                  className={[Classes.Box, 'childNode'].join(' ')}
                >
                  <div
                    className={Classes.Boxes}
                    style={{ cursor:'pointer',display:'flex',width:'100%' }}
                  >
                    <div className={Classes.Name}>
                      <div style={{display:'flex',width:'94%'}}>
                        <div className={Classes.ProductImages} style={{margin:0,marginRight:5,width:'12%'}}>
                          <img
                            src={product?.image}
                            alt='icon'
                            style={{width:'100%',height:"100%"}}
                          />
                        </div>

                        <div style={{width:'80%',marginRight:5}}>
                          <p className={Classes.ProductHeadText}>
                            {product?.productName}
                          </p>

                          <div className={['d-md-none',Classes.ProductDetails].join(' ')}>
                            <p className='text-muted' style={{marginRight:5}}>SKU:</p>
                            <p className='text-muted'>{product?.sku}</p>
                          </div>

                          <div className={['d-md-none',Classes.ProductVendorText].join(" ")}>
                            <p className='text-muted' style={{marginRight:5}}>AVL QUANTITY:</p>
                            <p className='text-muted'>{product?.totalAvailableQuantity}</p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>MIN QUANTITY:</p>
                            <p className='text-muted'>{product?.minQty}</p>
                          </div>

                          <div className={['d-md-none',Classes.ProductVendorText].join(" ")}>
                            <p className='text-muted' style={{marginRight:5}}>MAX QUANTITY:</p>
                            <p className='text-muted'>{product?.maxQty}</p>
                          </div>

                          <div className={['d-md-none',Classes.ProductVendorText].join(" ")}>
                            <Permission service={services?.franchiseOwner} permission="update">
                              <BorderColorIcon sx={{cursor : 'pointer', color : 'gray',marginLeft:"10px"}}
                                fontSize='small' 
                                onClick={(e) => showEditMinMax(e, product?.pgDbProductId, product?.minQty, product?.maxQty, product?.sku)}
                              />
                            </Permission>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="d-none d-sm-block" style={{ display: 'flex', justifyContent: "center", width: '100%', alignItems: "center" }} >
              <Box className="shadow"
                component={Paper}
                sx={{
                  '& .super-app-theme--header': {
                    backgroundColor: '#243750',
                    color: '#ffffff',
                    cursor: "default",
                  },
                }}>
                <DataGrid
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    toolbar: {
                      searchFor: searchProduct,
                      handleSearch: handleProduct,
                      franchises: franchises,
                      setFranchise: setFranchise,
                      setColor: setColor,
                      color: color,
                      setLocationCode: setLocationCode,
                      franchise: franchise,
                      setIncludingQty: setIncludingQty,
                      includingQty: includingQty,
                      locationCode: locationCode,
                      setPageNo
                    }
                  }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                    },
                  }}
                  rows={
                    products?.products?.map((product) => ({
                      image: product?.image,
                      productName: product?.productName,
                      id: product?.productId,
                      sku: product?.sku,
                      totalAvailableQuantity: product?.totalAvailableQuantity,
                      minQty: product?.minQty,
                      maxQty: product?.maxQty,
                      inventory: product?.inventory
                    }))
                  }
                  columns={columns}
                  filterMode="server"
                  sortingMode="server"
                  paginationMode="server"
                  disableRowSelectionOnClick
                  // checkboxSelection={true}
                  // loading={loading}
                  autoHeight
                  autoPageSize
                  onFilterModelChange={(filter) => (setFilters(filter?.items))}
                  // onRowSelectionModelChange={(newRowSelectionModel) => {
                  //   setProductId(newRowSelectionModel)
                  // }}
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    ".PrivateSwitchBase-input": {
                      height: "23px",
                      margin: "10px 13px",
                      width: "20px"
                    },
                    cursor: "pointer",
                    ".MuiDataGrid-footerContainer": {
                      display: "none",
                    },
                  }}
                  // onRowClick={(params) => {
                  //   navigate(`${params?.id}`);
                  // }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'productName', sort: 'asc' }],
                    },
                  }}
                  onSortModelChange={(sort) => setSort(sort[0])}
                />
              </Box>
            </div>
          </div>
        </div>
        {loading && <Spinner />}

        <div style={{ margin: '10px auto 0px' }} >
          {products?.products?.length !== 0 ? (
            <nav aria-label="Page navigation">
              <NewPagination totalPage={products} fetchData={fetchProduct} />
            </nav>
          ) : null}
        </div>

        <EditMinMaxQtyProduct 
          open={open}
          handleClose={handleClose}
          editMinMaxQtyProducts={editMinMaxQtyProducts}
          loading={loading}
          setLoading={setLoading}
          reRender={reRender}
          setReRender={setReRender}
        />
      </div>
    </Fragment>
  )
};

export default FranchiseInventoryManagement;