import React, {Fragment, useState, useEffect} from "react";
import axiosInstance from '../../../utility/axios-instance';
import Classes from '../createOrders/css/asideModal.module.css';
import InfiniteScroll from "react-infinite-scroll-component";


const CustomerModal = (props)=> {
    const [customers, setCustomers] = useState([]);
    // const [showModal, setShowModal] = useState(false);
    const [toggleBtn,setToggleBtn] = useState(true);
    const [searchPage,setSearchPage] = useState(1);
    const [page,setPages] = useState(1);
    const [maxPages,setMaxPage] = useState(1);
    const [total,setTotal] = useState(1);

    const {
        // setDetail,
        searchCustomer,
        toggleButton,
        setToggleButton,
        showModal,
        onHideModal,
        toggle,
        setToggle,
        customerInfo,
        setCustomerInfo,
        setSearchCustomer
    } = props;

    document.addEventListener('mouseup', function(e) {
        let container = document.getElementById('customerModal');
        if(container !== null){
            if (!container.contains(e.target)) {
                onHideModal();
            }
        }
    });

    const handleCustomer = (customer,displayName) => {
        setSearchCustomer('')
        onHideModal();
        setToggleBtn(true);
        if (customerInfo.length === 0) {
            setCustomerInfo([{id:customer,name: displayName}]);
        } else {
            setCustomerInfo([...customerInfo,{id:customer,name: displayName}]);
        }
    }

    useEffect(() => {
        if(toggleButton){
            axiosInstance
            .get(`/fieldSalesVisit/userSpecificCustomers?text=${searchCustomer}&page=${searchPage}`)
            .then((res) => {
                setMaxPage(res.data.data.maxPage)
                setCustomers(res.data.data.users);
                if(res.data.data.maxPages >= searchPage){
                    setSearchPage(searchPage + 1)
                    setPages(2)
                }
                setToggleButton(false);
            })
        }
    },[toggleButton,searchCustomer,setToggleButton,searchPage])

    useEffect(() => {
        if(toggle){
            axiosInstance
            .get(`/fieldSalesVisit/userSpecificCustomers`)
            .then((res) => {
                setCustomers(res.data.data.users);
                setTotal(res.data.data.total)
                setMaxPage(res.data.data.maxPage);
                setPages(2)
                setToggle(false)
            })
        }
    },[toggle,setToggle])
    

    const fetchMoreData = () => {
        if(maxPages >= page){
			setTimeout(() => {
				axiosInstance
                    .get(`/fieldSalesVisit/userSpecificCustomers?page=${page}&text=${searchCustomer}`)
                    .then((res) => {
                        if(res.data.data.maxPage >= page){
                            setPages(page + 1)
                        }else{
                            setPages(1)
                        }
                        setCustomers([...customers,...res.data.data.users]);
                    })
			}, 1500);
		}
    }

    return (
        <Fragment>
            {showModal && (
                <div 
                    className={Classes.modal}  
                    id="customerModal" 
                    style={{width:'94%',overflowY:'unset',padding:0}}
                >
                    {customers?.length !== 0 && 
                        <InfiniteScroll
                            dataLength={customers?.length}
                            next={fetchMoreData}
                            hasMore={true}
                            height={'auto'}
                            style={{maxHeight:240}}
                            loader={
                                page-1 !== maxPages ? 
                                <h6>Loading...</h6>
                                : ''}
                        >
                            {customers?.map((item,index) => (
                                <div key={index}>
                                    {!(customerInfo.length !== 0 && customerInfo.some(customer => customer.id === item._id)) && (
                                        <div 
                                            className={Classes.styles}
                                            style={{
                                                padding: "5px 15px",
                                                margin:0
                                            }} 
                                            onClick={()=> handleCustomer(item?._id,item?.displayName)}
                                        >
                                            <div>
                                                <div style={{display:'flex'}}>
                                                    {item?.displayName ? 
                                                    item?.displayName :
                                                    `${item?.firstName} ${item?.lastName || ""}`}
                                                </div>
                                            </div>
                                            <div>
                                                <p style={{color:'gray'}}>
                                                    {item?.phone}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </InfiniteScroll>
                    }
                </div>
            )}
        </Fragment>
    );
}


export default CustomerModal;