import { Autocomplete, Chip, TextField } from "@mui/material";
import Classes from "./addProducts/css/addProduct.module.css";
import React from "react";
import { handlePriceBlur } from "./addProducts/AllFunctions";

/**
 * Renders a component for displaying and editing prices.
 *
 * @component
 * @param {Object} payloadToUpload - The payload object containing price data.
 * @param {function} setPayloadToUpload - The function to update the payload object.
 * @param {string} pricesType - The type of prices to display defaultPrices,
 * districtBasedPrices and franchiseBasedPrices.
 * @param {string} priceCardId - The ID of the price card.
 * @returns {JSX.Element} The rendered Prices component.
 */
function Prices({
  payloadToUpload,
  pricesType,
  priceCardId,
  setPayloadToUpload,
}) {
  const preventNegativeValues = (e) =>
    ["e", "E", "+", "-", " "].includes(e.key) && e.preventDefault();
  let pricesObj;
  if (pricesType === "defaultPrices") {
    pricesObj = payloadToUpload?.[pricesType];
  } else {
    pricesObj = payloadToUpload?.[pricesType]?.find(
      (price) => price?.priceCardId === priceCardId
    );
  }
  return (
    <React.Fragment>
      {pricesType === "defaultPrices" && (
        <div style={{ width: "47%", borderRadius: "0.25rem" }}>
          <TextField
            name="mrp"
            type="number"
            id="mrp"
            step="0.01"
            size="small"
            autoComplete="off"
            error={
              payloadToUpload?.defaultPrices?.errors?.mrpPriceError
                ? true
                : false
            }
            helperText={
              payloadToUpload?.defaultPrices?.errors?.mrpPriceError || ""
            }
            onKeyDown={(e) => {
              preventNegativeValues(e);
              e.keyCode === 69 && e.preventDefault();
            }}
            key={payloadToUpload?.defaultPrices?.mrp}
            defaultValue={payloadToUpload?.defaultPrices?.mrp}
            required={true}
            className={[Classes.searchField, Classes.InputField].join(" ")}
            placeholder="&#8377;  0.00"
            onBlur={(e) =>
              handlePriceBlur({
                event: e,
                pricesType: "defaultPrices",
                payloadToUpload,
                setPayloadToUpload,
              })
            }
            label="MRP"
          />
        </div>
      )}

      {pricesType === "defaultPrices" && (
        <div style={{ width: "47%", borderRadius: "0.25rem" }}>
          <Autocomplete
            freeSolo
            forcePopupIcon={true}
            name="gst"
            id="gstRate"
            onChange={(e, newValue) =>
              handlePriceBlur({
                event: { target: { name: "gst", value: newValue || "18.0" } },
                pricesType: "defaultPrices",
                payloadToUpload,
                setPayloadToUpload,
              })
            }
            key={payloadToUpload?.defaultPrices?.gst || "18.0"}
            defaultValue={payloadToUpload?.defaultPrices?.gst || "18.0"}
            size="small"
            options={["18.0", "0.0", "5.0", "12.0", "28.0"]}
            disablePortal
            ListboxProps={{ sx: { fontSize: 14 } }}
            sx={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionSelected={(option, value) => option === value}
            renderInput={(params) => (
              <TextField {...params} label="GST Rate" required />
            )}
          />
        </div>
      )}

      <div style={{ width: "47%", borderRadius: "0.25rem" }}>
        <TextField
          name="listing"
          type="number"
          id="listingDiscount"
          step="0.01"
          required={true}
          size="small"
          onKeyDown={(e) => {
            preventNegativeValues(e);
            e.keyCode === 69 && e.preventDefault();
          }}
          key={pricesObj?.listingPrice}
          defaultValue={pricesObj?.listingPrice || ""}
          min="0"
          autoComplete="off"
          className={[Classes.searchField, Classes.InputField].join(" ")}
          placeholder="&#8377;  0.00"
          onBlur={(e) =>
            handlePriceBlur({
              event: e,
              pricesType,
              priceCardId,
              payloadToUpload,
              setPayloadToUpload,
            })
          }
          label="LIST PRICE (Exclusive GST)"
          error={pricesObj?.errors?.listingPriceError ? true : false}
          helperText={pricesObj?.errors?.listingPriceError || ""}
        />
        {(!pricesObj?.errors?.listingPriceError && pricesObj?.listingPrice) ? (
          <Chip
            label={`Discount: ${pricesObj?.listingDiscount || 0}%`}
            size="small"
            sx={{ width: "100%", marginTop: "5px" }}
            color="success"
          />
        ) : ""}
      </div>

      <div style={{ width: "47%", borderRadius: "0.25rem" }}>
        <TextField
          name="retail"
          type="number"
          id="retailDiscount"
          autoComplete="off"
          size="small"
          step="0.01"
          onKeyDown={(e) => {
            preventNegativeValues(e);
            e.keyCode === 69 && e.preventDefault();
          }}
          key={pricesObj?.retailPrice}
          defaultValue={pricesObj?.retailPrice || ""}
          className={[Classes.searchField, Classes.InputField].join(" ")}
          placeholder="&#8377;  0.00"
          onBlur={(e) =>
            handlePriceBlur({
              event: e,
              pricesType,
              priceCardId,
              payloadToUpload,
              setPayloadToUpload,
            })
          }
          label="RETAILER (Exclusive GST)"
          error={pricesObj?.errors?.retailPriceError ? true : false}
          helperText={pricesObj?.errors?.retailPriceError || ""}
        />
        {(!pricesObj?.errors?.retailPriceError && pricesObj?.retailPrice) ? (
          <Chip
            label={`Discount: ${pricesObj?.retailDiscount || 0}%`}
            size="small"
            sx={{ width: "100%", marginTop: "5px" }}
            color="success"
          />
        ) : ""}
      </div>

      <div style={{ width: "47%", borderRadius: "0.25rem" }}>
        <TextField
          name="specialRetail"
          type="number"
          id="specialRetailDiscount"
          autoComplete="off"
          step="0.01"
          size="small"
          onKeyDown={(e) => {
            preventNegativeValues(e);
            e.keyCode === 69 && e.preventDefault();
          }}
          key={pricesObj?.specialRetailPrice}
          defaultValue={pricesObj?.specialRetailPrice || ""}
          className={[Classes.searchField, Classes.InputField].join(" ")}
          placeholder="&#8377;  0.00"
          onBlur={(e) =>
            handlePriceBlur({
              event: e,
              pricesType,
              priceCardId,
              payloadToUpload,
              setPayloadToUpload,
            })
          }
          label="SPECIAL RETAILER (Exclusive GST)"
          error={pricesObj?.errors?.specialRetailPriceError ? true : false}
          helperText={pricesObj?.errors?.specialRetailPriceError || ""}
        />
        {(!pricesObj?.errors?.specialRetailPriceError &&
          pricesObj?.specialRetailPrice) ? (
            <Chip
              label={`Discount: ${pricesObj?.specialRetailDiscount || 0}%`}
              size="small"
              sx={{ width: "100%", marginTop: "5px" }}
              color="success"
            />
          ) : ""}
      </div>

      <div style={{ width: "47%", borderRadius: "0.25rem" }}>
        <TextField
          name="interior"
          type="number"
          id="interiorDiscount"
          size="small"
          step="0.01"
          onKeyDown={(e) => {
            preventNegativeValues(e);
            e.keyCode === 69 && e.preventDefault();
          }}
          key={pricesObj?.interiorPrice}
          defaultValue={pricesObj?.interiorPrice || ""}
          autoComplete="off"
          className={[Classes.searchField, Classes.InputField].join(" ")}
          placeholder="&#8377;  0.00"
          onBlur={(e) =>
            handlePriceBlur({
              event: e,
              pricesType,
              priceCardId,
              payloadToUpload,
              setPayloadToUpload,
            })
          }
          label="INTERIOR (Exclusive GST)"
          error={pricesObj?.errors?.interiorPriceError ? true : false}
          helperText={pricesObj?.errors?.interiorPriceError || ""}
        />
        {(!pricesObj?.errors?.interiorPriceError && pricesObj?.interiorPrice) ? (
          <Chip
            label={`Discount: ${pricesObj?.interiorDiscount || 0}%`}
            size="small"
            sx={{ width: "100%", marginTop: "5px" }}
            color="success"
          />
        ) : ""}
      </div>

      <div style={{ width: "47%", borderRadius: "0.25rem" }}>
        <TextField
          name="oem"
          type="number"
          id="oemDiscount"
          step="0.01"
          size="small"
          onKeyDown={(e) => {
            preventNegativeValues(e);
            e.keyCode === 69 && e.preventDefault();
          }}
          key={pricesObj?.oemPrice}
          defaultValue={pricesObj?.oemPrice || ""}
          autoComplete="off"
          className={[Classes.searchField, Classes.InputField].join(" ")}
          placeholder="&#8377;  0.00"
          onBlur={(e) =>
            handlePriceBlur({
              event: e,
              pricesType,
              priceCardId,
              payloadToUpload,
              setPayloadToUpload,
            })
          }
          label="OEM (Exclusive GST)"
          error={pricesObj?.errors?.oemPriceError ? true : false}
          helperText={pricesObj?.errors?.oemPriceError || ""}
        />
        {(!pricesObj?.errors?.oemPriceError && pricesObj?.oemPrice) ? (
          <Chip
            label={`Discount: ${pricesObj?.oemDiscount || 0}%`}
            size="small"
            sx={{ width: "100%", marginTop: "5px" }}
            color="success"
          />
        ) : ""}
      </div>

      <div style={{ width: "47%", borderRadius: "0.25rem" }}>
        <TextField
          name="endUser"
          type="number"
          id="endUserDiscount"
          step="0.01"
          size="small"
          onKeyDown={(e) => {
            preventNegativeValues(e);
            e.keyCode === 69 && e.preventDefault();
          }}
          key={pricesObj?.endUserPrice}
          defaultValue={pricesObj?.endUserPrice || ""}
          autoComplete="off"
          className={[Classes.searchField, Classes.InputField].join(" ")}
          placeholder="&#8377;  0.00"
          onBlur={(e) =>
            handlePriceBlur({
              event: e,
              pricesType,
              priceCardId,
              payloadToUpload,
              setPayloadToUpload,
            })
          }
          label="END USER (Exclusive GST)"
          error={pricesObj?.errors?.endUserPriceError ? true : false}
          helperText={pricesObj?.errors?.endUserPriceError || ""}
        />
        {(!pricesObj?.errors?.endUserPriceError && pricesObj?.endUserPrice) ? (
          <Chip
            label={`Discount: ${pricesObj?.endUserDiscount || 0}%`}
            size="small"
            sx={{ width: "100%", marginTop: "5px" }}
            color="success"
          />
        ) : ""}
      </div>

      <div style={{ width: "47%", borderRadius: "0.25rem" }}>
        <TextField
          name="partner"
          type="number"
          id="partnerPriceDiscount"
          step="0.01"
          size="small"
          onKeyDown={(e) => {
            preventNegativeValues(e);
            e.keyCode === 69 && e.preventDefault();
          }}
          key={pricesObj?.partnerPrice}
          defaultValue={pricesObj?.partnerPrice || ""}
          autoComplete="off"
          className={[Classes.searchField, Classes.InputField].join(" ")}
          placeholder="&#8377;  0.00"
          onBlur={(e) =>
            handlePriceBlur({
              event: e,
              pricesType,
              priceCardId,
              payloadToUpload,
              setPayloadToUpload,
            })
          }
          label="PARTNER PRICE (Exclusive GST)"
          error={pricesObj?.errors?.partnerPriceError ? true : false}
          helperText={pricesObj?.errors?.partnerPriceError || ""}
        />
        {(!pricesObj?.errors?.partnerPriceError && pricesObj?.partnerPrice) ? (
          <Chip
            label={`Discount: ${pricesObj?.partnerPriceDiscount || 0}%`}
            size="small"
            sx={{ width: "100%", marginTop: "5px" }}
            color="success"
          />
        ) : ""}
      </div>

      <div style={{ width: "47%", borderRadius: "0.25rem" }}>
        <TextField
          name="promoter"
          type="number"
          id="promoterPriceDiscount"
          size="small"
          step="0.01"
          onKeyDown={(e) => {
            preventNegativeValues(e);
            e.keyCode === 69 && e.preventDefault();
          }}
          key={pricesObj?.promoterPrice}
          defaultValue={pricesObj?.promoterPrice || ""}
          autoComplete="off"
          className={[Classes.searchField, Classes.InputField].join(" ")}
          placeholder="&#8377;  0.00"
          onBlur={(e) =>
            handlePriceBlur({
              event: e,
              pricesType,
              priceCardId,
              payloadToUpload,
              setPayloadToUpload,
            })
          }
          label="PROMOTER PRICE (Exclusive GST)"
          error={pricesObj?.errors?.promoterPriceError ? true : false}
          helperText={pricesObj?.errors?.promoterPriceError || ""}
        />
        {(!pricesObj?.errors?.promoterPriceError && pricesObj?.promoterPrice) ? (
          <Chip
            label={`Discount: ${pricesObj?.promoterPriceDiscount || 0}%`}
            size="small"
            sx={{ width: "100%", marginTop: "5px" }}
            color="success"
          />
        ) : ""}
      </div>
    </React.Fragment>
  );
}

export default Prices;
