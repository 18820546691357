import Table from "../../../../components/table/table";
import { useSelector } from 'react-redux';
import { setDownloadedReports } from "../../../../store/downloadedReports";

const columns = [
    {
        field: 'catalogueName',
        headerName: 'Catalogue Name',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        type: 'enum',
        columnName: 'name',
        tableName: 'catalogues'
    },
    {
        field: 'reportType',
        headerName: 'Report Type',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        type: 'enum',
        columnName: 'report_type',
        tableName: 'downloaded_reports'
    },
    {
        field: 'requestedBy',
        headerName: 'Customer Name',
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'phone',
        headerName: 'Phone',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'customerCode',
        headerName: 'Customer Code',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'medium',
        headerName: 'Medium',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        type: 'enum',
        columnName: 'medium',
        tableName: 'downloaded_reports'
    },
    {
        field: 'created_at',
        headerName: 'Date',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
]

const DownloadedReports = () => {
    const downloadedReports = useSelector((state) => state?.downloadedReports);

    return (
        <Table
            columns={columns}
            dataPosition={"downloadedReports"}
            endpoint={"/catalogues/downloaded/reports"}
            rowId={'id'}
            checkBoxSelection={false}
            service={'catalogues'}
            tabName={'Catalogues'}
            setData={setDownloadedReports}
            data={downloadedReports}
            version="version-2"
            mobileResponsive={true}
            exportEndpoint={'/exports/downloadedReports'}
            exportFileName={`Downloaded Reports - ${new Date()}`}
        />
    )
}

export default DownloadedReports;