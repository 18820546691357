import { Fragment } from 'react';
import Classes from './css/orders.module.css';

const NewSellerDetails = (props) => {
    const { draftOrder } = props;

    

    return (
        <Fragment>
            <div style={{width:'100%',marginBottom:20}}>
                <div style={{ width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                    <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>Seller Details</p>
                    <div style={{display:'flex',justifyContent:'space-between',padding:'15px',flexWrap:'wrap'}}>
                        <div className={Classes.CommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Seller:</p>
                            <p>{draftOrder?.OSellerDetails[0]?.sellerName}</p>
                        </div>

                        {draftOrder?.OSellerDetails[0]?.sellerGstNumber && (
                            <div className={Classes.CommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>GST Number:</p>
                                <p>{draftOrder?.OSellerDetails[0]?.sellerGstNumber}</p>
                            </div>
                        )}

                        <div className={Classes.CommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Seller Code:</p>
                            <p>{draftOrder?.OSellerDetails[0]?.sellerCode}</p>
                        </div>

                        <div className={Classes.CommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Phone Number:</p>
                            <p>{draftOrder?.OSellerDetails[0]?.phoneNumber}</p>
                        </div>

                        <div className={Classes.CommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Email:</p>
                            <p>{draftOrder?.OSellerDetails[0]?.email}</p>
                        </div>   
                    </div>
                </div>

                {/* OSellerAddresses */}

                {draftOrder?.OSellerAddresses && draftOrder?.OSellerAddresses?.length !== 0 && draftOrder?.OSellerAddresses?.map((location, index) => (
                    <div style={{ marginTop:15,width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                        <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>{location?.addressType} Address</p>
                        <div style={{display:'flex',justifyContent:'flex-start',padding:'15px 15px 5px',flexWrap:'wrap'}}>
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Name:</p>
                                <p>{location?.firstName}{" "}{location?.lastName && location?.lastName}</p>
                            </div>
                            
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Address1:</p>
                                <p>{location?.addressLine1}</p>
                            </div>

                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Address2:</p>
                                <p>{location?.addressLine2}</p>
                            </div>
                        {/* </div>

                        <div style={{display:'flex',justifyContent:'space-between',padding:'10px 15px'}}> */}
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Pincode:</p>
                                <p>{location?.pincode}</p>
                            </div>
                            
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>City:</p>
                                <p>{location?.city}</p>
                            </div>

                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>State:</p>
                                <p>{location?.state?.toTitleCase()}</p>
                            </div>
                        {/* </div>

                        <div style={{display:'flex',padding:'5px 15px 15px'}}> */}
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Country:</p>
                                <p>{location?.country}</p>
                            </div>

                            
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Phone Number:</p>
                                <p>{location?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )

}

export default NewSellerDetails;