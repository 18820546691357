import { Fragment } from 'react';
import Classes from '../../draftOrdersNew/css/draftOrderDetailPage.module.css';
import { ImCross } from 'react-icons/im';
import Button from '@mui/material/Button';



const Aside = (props) => {

    const {
        draftOrder,
        notes,
        deliveryRemark,
        files,
        setFiles,
        defaultFiles,
        setDefaultFiles,
        handleDocumentsUpdate
    } = props;

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-IN');
        }
    }

    

    const extractFileName = (url) => {
        const splitUrl = url.split("/");
        const fileNameWithParams = splitUrl[splitUrl.length - 1];
        const fileName = fileNameWithParams.split("?")[0];
        return fileName;
    }

    function checkNullValues(obj, text) {
        try {
            for (const key in obj) {
                if (
                    key !== "createdAt" &&
                    key !== "updatedAt" &&
                    key !== "purchaseOrderId" &&
                    key !== `po${text}DetailId`
                ) {
                    if (obj[key] !== null) {
                        return true;
                    }
                }
            }
            return false;
        } catch (err) {
            console.log(err);
        }
    }

    const handleFileSelect = (event) => {
        setFiles([...files, ...event.target.files]);
        event.target.value = null;
    };

    const removeFile = (index, files, setFiles) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles)
    };



    return (
        <Fragment>
            <div
                className={[
                    Classes.Box,
                    'box'
                ].join(' ')}
            >
                {draftOrder.length !== 0 && (
                    <div>
                        {draftOrder?.PoVendorDetails.length !== 0 && (
                            <div>
                                <div>
                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        <div className={Classes.containerBox}>
                                            <div className={Classes.asideCustomer}>
                                                <h6 style={{ margin: 'auto 0px' }}>
                                                    Vendor
                                                </h6>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: '#2c6ecb',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <p style={{ marginRight: '5px' }}>
                                                        {draftOrder?.PoVendorDetails[0]?.vendorName}
                                                    </p>
                                                    <p>GST - {draftOrder?.PoVendorDetails[0]?.vendorGstNumber}</p>
                                                    <p>{draftOrder?.PoVendorDetails[0]?.vendorCode}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        {checkNullValues(draftOrder.PoVendorContactDetails, "VendorContact") !== false && (
                                            <div className={Classes.containerBox}>
                                                <div className='mb-3'>
                                                    <p className={Classes.asideHeaders}>
                                                        CONTACT INFORMATION
                                                    </p>
                                                </div>
                                                {draftOrder?.PoVendorContactDetails !== null && (
                                                    <div
                                                        style={{
                                                            color: '#2c6ecb',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        <p>{`${draftOrder?.PoVendorContactDetails?.firstName} ${draftOrder?.PoVendorContactDetails?.lastName || ""}`}</p>
                                                        <p>{draftOrder?.PoVendorContactDetails?.role}</p>
                                                        <p>{draftOrder?.PoVendorContactDetails?.email}</p>
                                                        <p>{draftOrder?.PoVendorContactDetails?.phoneNumber}</p>

                                                        {/* <p style={{ marginTop: '5px' }}>
                                        </p> */}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {draftOrder?.PoVendorDetails !== null && (
                                    <div>
                                        <div>
                                            {draftOrder?.PoVendorDetails.length !== 0 ? (
                                                <div>
                                                    {draftOrder?.PoVendorDetails.map((vendor, index) => (
                                                        <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                                            <div className={Classes.containerBox}>
                                                                <div>
                                                                    <div className='mb-3'>
                                                                        <p className={Classes.asideHeaders}>{vendor.addressType === "Shipping" ? "PICKUP" : "BILLING" } ADDRESS</p>
                                                                    </div>

                                                                    <div key={index} style={{ fontSize: '14px' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <p style={{ marginRight: '5px' }}>{vendor?.firstName}</p>
                                                                            <p>{vendor?.lastName || ""}</p>
                                                                        </div>

                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <p style={{ marginRight: '5px' }}>{vendor?.addressLine1}</p>
                                                                            {vendor?.addressLine2 !== null ? (
                                                                                <p>{vendor?.addressLine2}</p>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </div>

                                                                        <div style={{ display: 'flex' }}>
                                                                            <p style={{ marginRight: '5px' }}>{vendor?.pincode}</p>
                                                                            <p>{vendor?.city},</p>
                                                                        </div>

                                                                        <div>
                                                                            <p>{vendor?.state?.toTitleCase()},</p>
                                                                        </div>

                                                                        <div>
                                                                            <p>{vendor?.phoneNumber}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null}

                                        </div>

                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                )}
            </div>
            <div
                className={[
                    Classes.Box,
                    'box'
                ].join(' ')}
            >
                {draftOrder.length !== 0 && (
                    <div>
                        {draftOrder?.PoLocationDetails.length !== 0 && (
                            <div>
                                <div>
                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        <div className={Classes.containerBox}>
                                            <div className={Classes.asideCustomer}>
                                                <h6 style={{ margin: 'auto 0px' }}>
                                                    Location
                                                </h6>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: '#2c6ecb',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <p style={{ marginRight: '5px' }}>
                                                        {draftOrder?.PoLocationDetails[0]?.locationName}
                                                    </p>
                                                    <p>GST - {draftOrder?.PoLocationDetails[0]?.locationGstNumber}</p>
                                                    <p>{draftOrder?.PoLocationDetails[0]?.locationCode}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        {checkNullValues(draftOrder.PoLocationContactDetails, "LocationContact") !== false && (
                                            <div className={Classes.containerBox}>
                                                <div className='mb-3'>
                                                    <p className={Classes.asideHeaders}>
                                                        CONTACT INFORMATION
                                                    </p>
                                                </div>

                                                {draftOrder?.PoLocationContactDetails !== null && (
                                                    <div
                                                        style={{
                                                            color: '#2c6ecb',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        <p>{`${draftOrder?.PoLocationContactDetails?.firstName} ${draftOrder?.PoLocationContactDetails?.lastName || ""}`}</p>
                                                        <p>{draftOrder?.PoLocationContactDetails?.role}</p>
                                                        <p>{draftOrder?.PoLocationContactDetails?.email}</p>
                                                        <p>{draftOrder?.PoLocationContactDetails?.phoneNumber}</p>

                                                        {/* <p style={{ marginTop: '5px' }}>
                                        </p> */}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div>
                                    {draftOrder?.PoLocationDetails.length !== 0 ? (
                                        <div>
                                            {draftOrder?.PoLocationDetails.map((location, index) => (
                                                <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                                    <div className={Classes.containerBox}>
                                                        <div>
                                                            <div className='mb-3'>
                                                                <p className={Classes.asideHeaders}>{location.addressType?.toUpperCase()} ADDRESS</p>
                                                            </div>

                                                            <div key={index} style={{ fontSize: '14px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.firstName}</p>
                                                                    <p>{location?.lastName || ""}</p>
                                                                </div>

                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.addressLine1}</p>
                                                                    {location?.addressLine2 !== null ? (
                                                                        <p>{location?.addressLine2}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.pincode}</p>
                                                                    <p>{location?.city},</p>
                                                                </div>

                                                                <div>
                                                                    <p>{location?.state?.toTitleCase()},</p>
                                                                </div>

                                                                <div>
                                                                    <p>{location?.phoneNumber}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>

                            </div>
                        )}
                    </div>
                )}
            </div>

            {draftOrder.length !== 0 && (
                <div className='' >
                    {checkNullValues(draftOrder.PoShipmentDetails, "Shipment") !== false && (
                        <div
                            className={[
                                Classes.Box,
                                Classes.asideMetafields,
                                'box'
                            ].join(' ')}
                        >
                            <h6 style={{ margin: '5px 0px' }}>
                                Shipment Details
                            </h6>
                            <div style={{ display: 'flex', borderBottom: '1px solid #DBDDE0', margin: '12px 0px' }}>
                                <p style={{ fontWeight: '500', marginRight: '7px', fontSize: '14px', width: '50%' }}>
                                    Transporter Name:
                                </p>
                                <p style={{
                                    color: '#2c6ecb',
                                    fontSize: '14px',
                                    width: '50%'

                                }}>
                                    {draftOrder?.PoShipmentDetails?.transporterName}
                                </p>
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid #DBDDE0', margin: '12px 0px' }}>
                                <p style={{ fontWeight: '500', marginRight: '7px', fontSize: '14px', width: '50%' }}>
                                    Transporter Type:
                                </p>
                                <p style={{
                                    color: '#2c6ecb',
                                    fontSize: '14px', width: '50%'
                                }}>
                                    {draftOrder?.PoShipmentDetails?.transporterType}
                                </p>
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid #DBDDE0', margin: '12px 0px' }}>
                                <p style={{ fontWeight: '500', marginRight: '7px', fontSize: '14px', width: '50%' }}>
                                    Estimated Dispatch Date:
                                </p>
                                <p style={{
                                    color: '#2c6ecb',
                                    fontSize: '14px', width: '50%'
                                }}>
                                    {formatDate(draftOrder?.PoShipmentDetails?.estimatedDispatchDate)}
                                </p>
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid #DBDDE0', margin: '12px 0px' }}>
                                <p style={{ fontWeight: '500', marginRight: '7px', fontSize: '14px', width: '50%' }}>
                                    Estimated Delivery Date:
                                </p>
                                <p style={{
                                    color: '#2c6ecb',
                                    fontSize: '14px', width: '50%'
                                }}>
                                    {formatDate(draftOrder?.PoShipmentDetails?.estimatedReceivingDate)}
                                </p>
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid #DBDDE0', margin: '12px 0px' }}>
                                <p style={{ fontWeight: '500', marginRight: '7px', fontSize: '14px', width: '50%' }}>
                                    Freight Charges:
                                </p>
                                <p style={{
                                    color: '#2c6ecb',
                                    fontSize: '14px', width: '50%'
                                }}>
                                    &#8377;{draftOrder?.PoShipmentDetails?.freightCharges}
                                </p>
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid #DBDDE0', margin: '12px 0px' }}>
                                <p style={{ fontWeight: '500', marginRight: '7px', fontSize: '14px', width: '50%' }}>
                                    Bilty Number:
                                </p>
                                <p style={{
                                    color: '#2c6ecb',
                                    fontSize: '14px', width: '50%'
                                }}>
                                    {draftOrder?.PoShipmentDetails?.biltyNumber}
                                </p>
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid #DBDDE0', margin: '12px 0px' }}>
                                <p style={{ fontWeight: '500', marginRight: '7px', fontSize: '14px', width: '50%' }}>
                                    Bilty Date:
                                </p>
                                <p style={{
                                    color: '#2c6ecb',
                                    fontSize: '14px', width: '50%'
                                }}>
                                    {formatDate(draftOrder?.PoShipmentDetails?.biltyDate)}
                                </p>
                            </div>

                        </div>
                    )}
                </div>
            )}

            {deliveryRemark && (
                <div className={Classes.Box} id='deliveryRemark'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Delivery Remark
                        </h6>
                        <p className={Classes.remarkBox}>
                            {deliveryRemark}
                        </p>
                    </div>
                </div>
            )}


            {notes && (
                <div className={Classes.Box} id='notes'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Notes
                        </h6>
                        <p className={Classes.remarkBox}>
                            {notes}
                        </p>
                    </div>
                </div>
            )}

            {draftOrder.length !== 0 && (
                <div className={Classes.Box}>
                <div className={Classes.containerBox}>
                    <h6 style={{ marginBottom: '15px' }}>
                        Documents
                    </h6>
                    <div className="mb-3">
                        <label htmlFor="file-upload" className="form-label">
                            Document Attached
                        </label>
                        <div className="input-group">
                            <input
                                id="file-upload"
                                type="file"
                                className="form-control"
                                multiple
                                accept=".jpg, .jpeg, .png, .pdf"
                                onChange={handleFileSelect}
                            />
                        </div>
                        <ul className="list-group mt-3">
                            {defaultFiles.map((fileName, index) => (
                                <div style={{display:'flex', alignItems: 'center', justifyContent : 'center', gap:5}}>
                                <a target='_blank' href={fileName} style={{ width:'100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="list-group-item" key={index}>
                                    {decodeURIComponent(extractFileName(fileName))}
                                </a>
                                    <ImCross style={{ cursor: 'pointer' }} size={13} onClick={() => removeFile(index, defaultFiles, setDefaultFiles)}>Remove</ImCross>
                                </div>
                            ))}
                            {files.map((fileName, index) => (
                                <div style={{display:'flex', alignItems: 'center', justifyContent : 'center', gap:5}}>
                                <li style={{ width:'100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="list-group-item" key={fileName}>
                                    {decodeURIComponent(fileName.name)}
                                </li>
                                    <ImCross style={{ cursor: 'pointer' }} size={13} onClick={() => removeFile(index, files, setFiles)}>Remove</ImCross>
                                </div>
                            ))}
                        </ul>
                    </div>
                    <div style={{display:'flex', justifyContent:'end'}}>
                        <Button  variant='contained' size="small" onClick={(e)=>handleDocumentsUpdate(e)}>Update Docs</Button>
                    </div>
                </div>
            </div>
            )}
        </Fragment>
    )
};

export default Aside;
