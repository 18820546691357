import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar, Button, CircularProgress, TextField, Modal } from "@mui/material";
import Table from "../../../components/table/table";
import axiosInstance from "../../../utility/axios-instance";
import { downloadCSV } from "../../../utility/downloadCSV";
import resetState from '../../../store/actions';
import SelectLocationCode from "./selectLocationCode";
import { setProductsData } from "../../../store/updatePricingSlice";

const UpdatePricing = (props) => {
  const [csv, setCSV] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [reRender,setReRender] = useState(false);
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
  const productsPricing = useSelector((state) => state.productsPricing);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const dispatch = useDispatch();

  const countPlaces = (num) => {
    var sep = String(23.32).match(/\D/)[0];
    var b = String(num).split(sep);
    const result =  b[1] ? b[1].length : 0;
    return result === 1 || result === 0 || result === 2 ? num : Math.trunc(num*100)/100
  };

  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      width: 175,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.sku}</div>
      )
    },
    {
      field: "productName",
      headerName: "PRODUCT NAME",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.productName}</div>
      )
    },
    {
      field: "brandName",
      headerName: "BRAND NAME",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      type: 'enum',
      columnName: 'brand_name',
      tableName: 'product_collections',
      renderCell: (params) => (
        <div>{params?.row?.brandName}</div>
      )
    },
    {
      field: "mrp",
      headerName: "MRP",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>₹{countPlaces(params?.row?.mrp)}</div>
      )
    },
    {
      field: "listingPrice",
      headerName: "LISTING PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>₹{countPlaces(params?.row?.listingPrice)}</div>
      )
    },
    {
      field: "retailerPrice",
      headerName: "RETAILER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.retailerPrice ? `₹${countPlaces(params?.row?.retailerPrice)}` : "N/A"}</div>
      )
    },
    {
      field: "specialRetailerPrice",
      headerName: "SPECIAL RETAILER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.specialRetailerPrice ? `₹${countPlaces(params?.row?.specialRetailerPrice)}` : "N/A"}</div>
      )
    },
    {
      field: "interiorPrice",
      headerName: "INTERIOR PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.interiorPrice ? `₹${countPlaces(params?.row?.interiorPrice)}` : "N/A"}</div>
      )
    },
    {
      field: "oemPrice",
      headerName: "OEM PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.oemPrice ? `₹${countPlaces(params?.row?.oemPrice)}` : "N/A"}</div>
      )
    },
    {
      field: "endUserPrice",
      headerName: "END USER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.endUserPrice ? `₹${countPlaces(params?.row?.endUserPrice)}` : "N/A"}</div>
      )
    },
    {
      field: "partnerPrice",
      headerName: "PARTNER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.partnerPrice ? `₹${countPlaces(params?.row?.partnerPrice)}` : "N/A"}</div>
      )
    },
    {
      field: "promoterPrice",
      headerName: "PROMOTER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.promoterPrice ? `₹${countPlaces(params?.row?.promoterPrice)}` : "N/A"}</div>
      )
    },
  ];

  const handleSuccess = (msg) => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: true, message: msg, type: "success" }
    });
  }
  const handleError = (msg) => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: true, message: msg, type: "error" }
    });
  }

  const handleSnackBarClose = () => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: false }
    });
  };

  const handleTemplateDownload = () => {
    setLoading2(true);

    axiosInstance
      .get(`/updatePricing/downloadTemplate`)
      .then((result) => {
          const arrayOfRowObjects = result?.data?.data
          downloadCSV(arrayOfRowObjects,`update pricing template - ${new Date()}`);
          handleSuccess(`Template Downloaded Successfully`)
      })
      .catch((error) => {
        console.log(error);
          handleError(error.response.data.message);
          if (error.response.status === 401) {
              setTimeout(() => {
                  resetState(dispatch);
              }, 1000);
          }
      })
      .finally(() => {
        setLoading2(false);
      });
  }

  const submit = async (e) => {
    e.preventDefault();

    // setShowModal(true);
    const formData = new FormData();

    formData.append("products", csv);
    // formData.append('locationCode', locationCode);

    setLoading(true);

    axiosInstance.post('/product/updateProduct?type=pricing', formData)
      .then(res => {
        handleSuccess(res.data.message);
        const form = document.getElementById('updatePricing');
        form.reset();
        setReRender(!reRender);
      }).catch(err => {
        console.log(err);
        handleError(err.response.data.message);
      }).finally(() => setLoading(false));
  } 

    const updatePrice = async (locationCode) => {
      const formData = new FormData();

      formData.append("products", csv);
      // formData.append('locationCode', locationCode);

      setLoading(true);

      axiosInstance.post('/product/updateProduct', formData).then(res => {
        handleSuccess(res.data.message);
      }).catch(err => {
        console.log(err);
        handleError(err.response.data.message);
      }).finally(() => setLoading(false));
    }

  return (
    <Fragment>
      <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
          {snackBar?.message}
        </Alert>
      </Snackbar>

      <Modal open={showModal} onClose={() => setShowModal(false)} style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <SelectLocationCode  onClose={() => setShowModal(false)} updatePrice={(locationCode) => {updatePrice(locationCode)}}></SelectLocationCode>
      </Modal>

      <div className="mb-2 mt-1" style={{ display: 'flex',alignItems: 'center',justifyContent: 'space-between', flexWrap:'wrap', gap:'5px' }}>
        <form onSubmit={submit} style={{ display:'flex',alignItems: 'stretch',gap: '1rem' }} id='updatePricing'>
          <TextField 
            id='csv'
            required
            name='csv'
            type='file'
            // label='Upload CSV'
            inputProps={{ 'accept': "file/csv" }}
            sx={{'& .MuiOutlinedInput-input': {padding:'5px 14px',height:'inherit'}}}
            autoComplete='off'
            onChange={(e) => setCSV(e.target.files[0])} 
          />

          <Button variant="contained" disabled={loading} type='submit'>
            {loading ? <CircularProgress size={24} style={{color: "#ffffff"}} /> : 'Submit'}
          </Button>
        </form>

        <div>
          <Button 
            variant="contained" 
            disabled={loading2} 
            onClick={() => handleTemplateDownload()} 
          >
            {loading2 ? <CircularProgress size={24} style={{ color: "#ffffff" }} /> : 'Download Template'}
          </Button>
        </div>
      </div>

      <div className='' style={{ width: "100%" }}>
        <Table
          columns={columns}
          dataPosition={"products"}
          endpoint={"/product?prices='allPrices'"}
          rowId={'productId'}
          checkBoxSelection={false}
          version="version-1"
          // navigateOnRowClickEndpoint={`/myCustomers`}
          reRender={reRender}
          service={'products'}
          tabName={'Update Pricing'}
          setData={setProductsData}
          data={productsPricing}
          mobileResponsive={true}
          exportEndpoint={'/exports/products'}
          exportFileName={`Products - ${new Date()}`}
          searchKey={'text'}
          filterName={'filters'}
        />
      </div>
    </Fragment>
  );
};

export default UpdatePricing;