import React, { useState } from "react";
import Table from "../../../components/table/table";
import { useSelector } from "react-redux";
import { setBanners } from "../../../store/laminateSettingSlice";
import { Switch, Chip } from "@mui/material";
import axiosInstance from "../../../utility/axios-instance";

const Laminates = () => {
  const [reRender, setReRender] = useState(false);
  
  const laminateDetails = useSelector((state) => state.laminateSettings);

  const toggleIsLaminateActive = (sku, currentSalesChannel) => {
    const salesChannel = [];
    if(currentSalesChannel.includes("Laminate")){
      salesChannel.push(...currentSalesChannel.filter(channel => channel !== "Laminate"));
    }else{
      salesChannel.push(...currentSalesChannel)
      salesChannel.push("Laminate")
    }
    const productSalesChannelUpdatePayload = {
      sku,
      salesChannel,
    }
    axiosInstance
				.post(`/product/update/undefined`,{
						...productSalesChannelUpdatePayload
				})
				.then((res) => {
          setReRender(!reRender);
        })
        .catch((error)=>{
          console.log(error);
        })
  };

  const LaminateUrlsRenderCell = (row) => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5em" }}>
        {row?.dimensions?.map((dimension, index) => {
          const imageUrlForCurrentDimension = row?.imageUrls?.[index];
          return (
            <Chip
              label={`${dimension}`}
              target="_blank"
              component="a"
              href={`${imageUrlForCurrentDimension}`}
              clickable
            />
          );
        })}
      </div>
    )
  };

  const IsLaminateActiveRenderCell = (row) => {
    return (
      <Switch
        checked={row?.isLaminateActive}
        onChange={()=>toggleIsLaminateActive(row?.sku, row?.salesChannel)}
        inputProps={{ "aria-label": "controlled" }}
      />
    );
  };

  const AltTextRenderCell = (row) => row?.altText || "NA";
  const ColorRenderCell = (row) => row?.color || "NA";

  const columns = [
    {
      field: "sku",
      headerName: "Sku",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "productId",
      headerName: "Product Id",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "productImageId",
      headerName: "Image Ids",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "imageUrls",
      headerName: "Laminate Urls",
      flex: 0.7,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      renderCell: (params) => ( <LaminateUrlsRenderCell {...params?.row} />),
      customRenderCell: LaminateUrlsRenderCell
    },
    {
      field: "altText",
      headerName: "Alt Text",
      flex: 0.7,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
      renderCell: (params) => ( <AltTextRenderCell {...params?.row} />),
      customRenderCell: AltTextRenderCell
    },
    {
      field: "color",
      headerName: "Color",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
      renderCell: (params) => ( <ColorRenderCell {...params?.row} />),
      customRenderCell: ColorRenderCell
    },
    {
      field: "isLaminateActive",
      headerName: "Active",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
      renderCell: (params) => ( <IsLaminateActiveRenderCell {...params?.row} />),
      customRenderCell: IsLaminateActiveRenderCell
    },
    {
      field: "salesChannel",
      headerName: "Sales Channel",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      flex: 0.6,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 0.6,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.6,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.6,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
  ];
  const columnVisibilityModel = {
    productId: false,
    productImageId: false,
    createdBy: false,
    updatedAt: false,
    updatedBy: false,
    salesChannel: false,
  };

  return (
    <>
      <Table
        columns={columns}
        dataPosition={"laminateDetails"}
        endpoint={`/adminRoutes/laminate/laminates`}
        rowId={"productId"}
        checkBoxSelection={false}
        reRender={reRender}
        service={"laminateSetting"}
        tabName={"Laminate Settings"}
        setData={setBanners}
        data={laminateDetails}
        version={"version-1"}
        mobileResponsive={true}
        columnVisibilityModel={columnVisibilityModel}
      />
    </>
  );
};

export default Laminates;
