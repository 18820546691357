/**
 * Removes duplicate elements from an array.
 * @returns {Array} A new array with only unique elements.
 */
Array.prototype.unique = function () {};

/**
 * Converts the first character of each word in a string to uppercase.
 * @returns {string} The string with each word's first character in uppercase.
 */
String.prototype.toTitleCase = function () {};


Array.prototype.unique = function () {
    const uniqueArray = [];
    const seenElements = {};

    for (let i = 0; i < this.length; i++) {
        const element = this[i];
        if (!seenElements[element]) {
            uniqueArray.push(element);
            seenElements[element] = true;
        }
    }

    return uniqueArray;
};

String.prototype.toTitleCase = function () {
  return this.replace(/\b\w/g, (char) => char.toUpperCase());
};