import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import ContactDetails from '../../pages/home/createPurchaseOrder/aside/ContactDetails';
import AsideModal from '../../pages/home/createPurchaseOrder/aside/asideModal';
import ContactPopUp from '../../pages/home/createPurchaseOrder/aside/contactPopUp';
import ShippingPopUp from '../../pages/home/createPurchaseOrder/aside/shippingAddressPopup';
import UpdateShippingPopUp from '../../pages/home/createPurchaseOrder/aside/updateShippingAddress';
import DefaultAddressPopUp from '../../pages/home/createPurchaseOrder/aside/defaultAddressPopUp';
import GSTNoPopup from '../../pages/home/createPurchaseOrder/aside/newGstNo';
import UpdateBillingPopUp from '../../pages/home/createPurchaseOrder/aside/updateBillingAddress';
import DefaultBillingAddressPopUp from '../../pages/home/createPurchaseOrder/aside/defaultBillingAddressPopUp';
import RemoveGstPopup from '../../pages/home/createPurchaseOrder/aside/removeGstPopup';
import axiosInstance from '../../utility/axios-instance';

const VendorComponent = (props) => {
    const { 
        showCustomerDetail, 
        removeElement, 
        showContactPop, 
        vendorGstNumber, 
        addressArray,
        customerIDAddress,
        showUpdateShippingPop,
        showDefaultAddressPopUp,
        showShippingPop,
        // vendorContacts
        vendorContacts,
        setVendorContacts,
        vendorContactDetails,
        setVendorContactDetails,
        handleOutline,
        handleSearchCustomer,
        showCustomerModal,
        showGstNoPopUp,
        showRemoveGstPopup,
        handleGstNumber,
        BillingAddress,
        showUpdateBillingPop,
        showdefaultBillingAddressPop,
        showModal,
        hideCustomerModal,
        setCustomerDetail,
        searchCustomer,
        toggleButton,
        setToggleButton,
        setCustomerIDAddress,
        setPreviousTarget,
        setBillingAddress,
        toggle,
        setToggle,
        setAddressArray,
        setVendorGstNumber,
        // contact Dialog
        contactModal,
        setContactModal,
        hideContactPop,
        // shippingModal
        shippingModal,
        setShippingModal,
        hideShippingPop,
        pincode,
        setPincode,
        num,
        setNum,
        addressType,
        setAddressType,
        // updateShippingModal
        updateShippingModal,
        hideUpdateShippingPop,
        setUpdateShippingModal,
        updateExistOrder,
        // updateBillingAddressModal
        updateBillingAddressModal,
        hideUpdateBillingPop,
        setUpdateBillingAddressModal,
        // defaultAddressModal
        defaultAddressModal,
        hideDefaultAddressPopUp,
        previousTarget,
        // defaultBillingAddressModal
        defaultBillingAddressModal,
        hideDefaultBillingAddressPop,
        // newGstNo
        newGstNo,
        setNewGstNo,
        hideGstNoPopUp,
        // removeGstModal
        removeGstModal,
        setRemoveGstModal,
        hideRemoveGstPopup,
        gstNumber,
        setGstNumber,
        fetchVendorAddresses
    } = props;

    const capitalName = (text) => {
        const result1 = text.replaceAll('_', ' ');
        const arr = result1.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    return (
        <Fragment>
            <div className={[Classes.Box, 'box'].join(' ')}>
                {showCustomerDetail?.length !== 0 ? (
                    <div>
                        {showCustomerDetail?.map((item, index) => (
                            <div key={index} id={item?._id}>
                                <div className={Classes.containerBox} style={{ borderBottom:'1px solid #DBDDE0' }}>
                                    <div className={Classes.customerBox}>
                                        <h6 style={{ margin: 'auto 0px' }}>VENDOR</h6>
                                        <div className='removeIcon'>
                                            <FontAwesomeIcon
                                                icon={faXmark}
                                                className={Classes.xMarkIcon}
                                                onClick={() => removeElement(item?._id)}
                                            />
                                        </div>
                                    </div>

                                    <div className={Classes.customerDetailBox}>
                                        <p className={Classes.customerName} style={{ marginRight:5 }}>
                                            {item?.vendorName}
                                        </p>

                                        {item?.status && (
                                            <p 
                                                className={Classes.customerRole}
                                                style={{backgroundColor:item?.status === "Active"? '#00B87C': '#FF4D4D',color:'white' }}
                                            >
                                                {capitalName(item?.status)}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className={Classes.containerBox} style={{ borderBottom:'1px solid #DBDDE0' }}>
                                    <div style={{ display:'flex',justifyContent:'space-between',marginBottom:10 }}>
                                        <p className={Classes.contactInformation}>
                                            CONTACT INFORMATION
                                        </p>
                                        <p
                                            className={['removeEdit',Classes.contactEditBtn].join(' ')}
                                            onClick={(e) => showContactPop(e)}
                                        >
                                            Edit
                                        </p>
                                    </div>

                                    <div style={{ color:'#2c6ecb',fontSize:14 }}>
                                        <p>{item?.vendorCode}</p>
                                        <p>{vendorGstNumber}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {(addressArray?.length !== 0 || customerIDAddress?.length !== 0) ? (
                            <div style={{border:0,padding:'1.1rem',borderBottom:'1px solid #DBDDE0'}}>
                                <div style={{ display:'flex',justifyContent:'space-between',marginBottom:10 }}>
                                    <p className={Classes.contactInformation}>PICKUP ADDRESS</p>

                                    {customerIDAddress?.length !== 0 && (
                                        <p
                                            className={['removeAddressOnPrint',Classes.contactEditBtn].join(' ')}
                                            id='Shipping'
                                            onClick={(e) => showUpdateShippingPop(e)}
                                        >
                                            Edit address
                                        </p>
                                    )}
                                </div>
                                
                                {customerIDAddress?.length !== 0 ? (
                                    <div style={{ fontSize: 14 }}>
                                        <div style={{ display: 'flex' }}>
                                            <p style={{ marginRight: '5px' }}>{customerIDAddress[0]?.firstName}</p>
                                            <p>{customerIDAddress?.[0]?.lastName || ""}</p>
                                        </div>

                                        <div style={{ display:'flex',flexDirection: 'column' }}>
                                            <p style={{ marginRight:5 }}>{customerIDAddress[0]?.addressLine1}</p>
                                            <p>{customerIDAddress[0]?.addressLine2 !== null && customerIDAddress[0]?.addressLine2}</p>
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <p style={{ marginRight:5 }}>
                                                {customerIDAddress[0]?.pincode && customerIDAddress[0]?.pincode}
                                            </p>
                                            {customerIDAddress[0]?.city && (
                                                <p style={{ marginRight:5 }}>
                                                    {customerIDAddress[0]?.city && customerIDAddress[0]?.city},
                                                </p>
                                            )}

                                            <p>{customerIDAddress[0]?.state && customerIDAddress[0]?.state}</p>
                                        </div>

                                        <p>{customerIDAddress[0]?.phoneNumber && customerIDAddress[0]?.phoneNumber}</p>
                                    </div>
                                ) : null}

                                <div className={['removeAddressOnPrint',Classes.selectAddressBtn].join(' ')}>
                                    <p onClick={showDefaultAddressPopUp}>Select address</p>
                                    <p id='Shipping' onClick={(e) => showShippingPop(e)}>Add new address</p>
                                </div>
                            </div>
                        ) : (
                            <div style={{border:0,padding:'1.1rem',borderBottom:'1px solid #DBDDE0'}}>
                                <p style={{ margin:'0px 0px 10px',fontSize:12,fontWeight:600 }}>
                                    PICKUP ADDRESS
                                </p>
                                <p id='Shipping' className={Classes.contactEditBtn} onClick={(e) => showShippingPop(e)}>
                                    Please enter pickup address
                                </p>
                            </div>
                        )}

                        {(addressArray?.length !== 0 || BillingAddress?.length !== 0) ? (
                            <div className={Classes.containerBox} style={{ borderBottom: '1px solid #DBDDE0' }}>
                                <div style={{ display:'flex',justifyContent:'space-between',marginBottom:10 }}>
                                    <p className={Classes.contactInformation}>
                                        BILLING ADDRESS
                                    </p>
                                    {BillingAddress?.length !== 0 && (
                                        <p
                                            className={['removeAddressOnPrint',Classes.contactEditBtn].join(' ')}
                                            onClick={(e) => showUpdateBillingPop(e)}
                                            id='Billing'
                                        >
                                            Edit Address
                                        </p>
                                    )}
                                </div>

                                {BillingAddress?.length !== 0 ? (
                                    <div style={{ fontSize:14 }}>
                                        <div style={{ display:'flex' }}>
                                            <p style={{ marginRight:5 }}>{BillingAddress[0]?.firstName}</p>
                                            <p>{BillingAddress?.[0]?.lastName || ""}</p>
                                        </div>

                                        <div style={{ display:'flex',flexDirection:'column' }}>
                                            <p style={{ marginRight:5 }}>{BillingAddress[0]?.addressLine1}</p>
                                            {BillingAddress[0]?.addressLine2 !== null && <p>{BillingAddress[0]?.addressLine2}</p> }
                                        </div>

                                        <div style={{ display:'flex' }}>
                                            <p style={{ marginRight:5 }}>{BillingAddress[0]?.pincode}</p>
                                            <p style={{ marginRight:5 }}>{BillingAddress[0]?.city},</p>
                                            <p>{BillingAddress[0]?.state?.toTitleCase()}</p>
                                        </div>
                                            
                                        <p>{BillingAddress[0]?.phoneNumber}</p>

                                        <div className={Classes.selectAddressBtn}>
                                            <p className='removeAddressOnPrint' onClick={showdefaultBillingAddressPop}>
                                                Select Address
                                            </p>
                                            <p id='Billing' onClick={(e) => showShippingPop(e)}>
                                                Add new address
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={['removeAddressOnPrint',Classes.selectAddressBtn].join(' ')}>
                                        <p onClick={showdefaultBillingAddressPop}>Select address</p>
                                        <p id='Billing' onClick={(e) => showShippingPop(e)}>
                                            Add new address
                                        </p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div style={{ padding:'1.1rem',borderBottom: '1px solid #DBDDE0' }}>
                                <p style={{ margin:'auto 0px 8px',fontSize:12,fontWeight:600 }}>
                                    BILLING ADDRESS
                                </p>
                                {customerIDAddress?.length !== 0 ? (
                                    <div>
                                        <p style={{ color:'#6c757d' }}>Same as pickup address</p>
                                        <p id='billing' onClick={(e) => showShippingPop(e)} className={Classes.AddNewAddressBtn}>
                                            Add new address
                                        </p>
                                    </div>
                                ) : (
                                    <p id='billing' className={Classes.contactEditBtn} onClick={(e) => showShippingPop(e)}>
                                        Please enter billing address
                                    </p>
                                )}
                            </div>
                        )}

                        {showCustomerDetail?.length !== 0 && (
                            <div className={Classes.containerBox} style={{ borderBottom:'1px solid #DBDDE0' }} id='removeSelectGst'>
                                <div className='d-flex justify-content-between mb-2'>
                                    <h6 style={{ margin:'auto 0px',fontSize:12,fontWeight:600 }}>
                                        SELECT GST NO:
                                    </h6>
                                    <p onClick={showGstNoPopUp} className={Classes.addNewGstNo}>
                                        Add New
                                    </p>
                                </div>

                                <div className={Classes.fontSize}>
                                    <select
                                        name='gst'
                                        defaultValue={'DEFAULT'}
                                        id='gstNo'
                                        className={Classes.select}
                                        onChange={(e) => handleGstNumber(e, "Vendor")}
                                        value={vendorGstNumber}
                                    >
                                        <option value='' >Select GSTno</option>
                                        {showCustomerDetail[0]?.vendorGstNumber?.length !== 0 && 
                                            showCustomerDetail[0]?.vendorGstNumber?.map((gst, index) => (
                                            <option value={gst} key={index}>{gst}</option>
                                        ))}
                                    </select>
                                </div>

                                {showCustomerDetail?.length !== 0 && showCustomerDetail[0]?.vendorGstNumber?.length !== 0 && (
                                    <p 
                                        onClick={showRemoveGstPopup} className={Classes.addNewGstNo} 
                                        style={{ marginTop:10,marginLeft:5 }}
                                    >
                                        Remove GST
                                    </p>
                                )}
                            </div>
                        )}

                        <div className={Classes.containerBox} style={{ borderBottom: '1px solid #DBDDE0' }}>
                            <ContactDetails 
                                contacts={vendorContacts} 
                                setContacts={setVendorContacts} 
                                selectedContact={vendorContactDetails} 
                                setSelectedContact={setVendorContactDetails} 
                                type={"Vendor"} 
                                details={showCustomerDetail} 
                            />
                        </div>
                    </div>
                ) : (
                    <div className={Classes.containerBox}>
                        <h6 style={{ margin:'auto 0px 15px',padding:6 }}>
                            Vendor
                        </h6>
                        <div 
                            onClick={handleOutline} id='contactOutline'
                            className={[Classes.contactInputBox,Classes.searchContainer].join(' ')}
                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass} className={Classes.contactSearchIcon} />
                            <div style={{ width: '100%' }}>
                                <input
                                    type="text"
                                    id="search"
                                    className={Classes.searchField}
                                    placeholder="Search Vendors"
                                    autoComplete='off'
                                    onChange={(e) => handleSearchCustomer(e.target.value)}
                                    onClick={() => showCustomerModal()}
                                />
                            </div>
                        </div>
                    </div>
                )}
            
                <div>
                    <AsideModal
                        vendorContacts={vendorContacts}
                        setVendorContacts={setVendorContacts}
                        showModal={showModal}
                        onHideModal={hideCustomerModal}
                        setDetail={setCustomerDetail}
                        searchCustomer={searchCustomer}
                        toggleButton={toggleButton}
                        setToggleButton={setToggleButton}
                        setCustomerIDAddress={setCustomerIDAddress}
                        setPreviousTarget={setPreviousTarget}
                        setBillingAddress={setBillingAddress}
                        toggle={toggle}
                        setToggle={setToggle}
                        setAddressArray={setAddressArray}
                        setVendorContactDetails={setVendorContactDetails}
                        setVendorGstNumber={setVendorGstNumber}
                        fetchVendorAddresses={fetchVendorAddresses}
                    />
                </div>

                <div className={contactModal ? Classes.UpdateSalesDialog : undefined}>
                    <ContactPopUp
                        contactModal={contactModal}
                        setContactModal={setContactModal}
                        showContactPop={showContactPop}
                        hideContactPop={hideContactPop}
                        showCustomerDetail={showCustomerDetail}
                        setCustomerDetail={setCustomerDetail}
                    />
                </div>

                <div className={shippingModal ? Classes.UpdateSalesDialog : undefined}>
                    <ShippingPopUp
                        shippingModal={shippingModal}
                        setShippingModal={setShippingModal}
                        showShippingPop={showShippingPop}
                        hideShippingPop={hideShippingPop}
                        pincode={pincode}
                        setPincode={setPincode}
                        num={num}
                        setNum={setNum}
                        showCustomerDetail={showCustomerDetail}
                        setCustomerIDAddress={setCustomerIDAddress}
                        addressArray={addressArray}
                        setAddressArray={setAddressArray}
                        setBillingAddress={setBillingAddress}
                        addressType={addressType}
                        setAddressType={setAddressType}
                        type={"Vendor"}
                    />
                </div>

                <div className={updateShippingModal ? Classes.UpdateSalesDialog : undefined}>
                    <UpdateShippingPopUp
                        hideUpdateShippingPop={hideUpdateShippingPop}
                        showUpdateShippingPop={showUpdateShippingPop}
                        updateShippingModal={updateShippingModal}
                        setUpdateShippingModal={setUpdateShippingModal}
                        showCustomerDetail={showCustomerDetail}
                        setCustomerIDAddress={setCustomerIDAddress}
                        updateExistOrder={updateExistOrder}
                        customerIDAddress={customerIDAddress}
                        addressType={addressType}
                        addressArray={addressArray}
                        setAddressArray={setAddressArray}
                        type={"Vendor"}
                    />
                </div>

                <div className={updateBillingAddressModal ? Classes.UpdateSalesDialog : undefined}>
                    <UpdateBillingPopUp
                        hideUpdateBillingPop={hideUpdateBillingPop}
                        showUpdateBillingPop={showUpdateBillingPop}
                        updateBillingModal={updateBillingAddressModal}
                        setUpdateBillingModal={setUpdateBillingAddressModal}
                        showCustomerDetail={showCustomerDetail}
                        setBillingAddress={setBillingAddress}
                        updateExistOrder={updateExistOrder}
                        BillingAddress={BillingAddress}
                        addressType={addressType}
                        addressArray={addressArray}
                        setAddressArray={setAddressArray}
                        type={"Vendor"}
                    />
                </div>

                <div className={defaultAddressModal ? Classes.UpdateSalesDialog : undefined}>
                    <DefaultAddressPopUp
                        defaultAddressModal={defaultAddressModal}
                        hideDefaultAddressPopUp={hideDefaultAddressPopUp}
                        setCustomerIDAddress={setCustomerIDAddress}
                        customerIDAddress={customerIDAddress}
                        showCustomerDetail={showCustomerDetail}
                        addressArray={addressArray}
                        setAddressArray={setAddressArray}
                        previousTarget={previousTarget}
                        setPreviousTarget={setPreviousTarget}
                        fetchVendorAddresses={fetchVendorAddresses}
                    />
                </div>

                <div className={defaultBillingAddressModal ? Classes.UpdateSalesDialog : undefined}>
                    <DefaultBillingAddressPopUp
                        defaultBillingAddressModal={defaultBillingAddressModal}
                        hideDefaultBillingAddressPop={hideDefaultBillingAddressPop}
                        setBillingAddress={setBillingAddress}
                        showCustomerDetail={showCustomerDetail}
                        showdefaultBillingAddressPop={showdefaultBillingAddressPop}
                        BillingAddress={BillingAddress}
                        addressArray={addressArray}
                        setAddressArray={setAddressArray}
                        previousTarget={previousTarget}
                        setPreviousTarget={setPreviousTarget}
                        fetchVendorAddresses={fetchVendorAddresses}
                    />
                </div>

                <div className={newGstNo ? Classes.UpdateSalesDialog : undefined}>
                    <GSTNoPopup
                        newGstNo={newGstNo}
                        setNewGstNo={setNewGstNo}
                        showGstNoPopUp={showGstNoPopUp}
                        hideGstNoPopUp={hideGstNoPopUp}
                        showCustomerDetail={showCustomerDetail}
                        setCustomerDetail={setCustomerDetail}
                        type={"Vendor"}
                    />
                </div>
                
                <div className={removeGstModal ? Classes.UpdateSalesDialog : undefined}>
                    <RemoveGstPopup
                        removeGstModal={removeGstModal}
                        setRemoveGstModal={setRemoveGstModal}
                        showRemoveGstPopup={showRemoveGstPopup}
                        hideRemoveGstPopup={hideRemoveGstPopup}
                        showCustomerDetail={showCustomerDetail}
                        setCustomerDetail={setCustomerDetail}
                        gstNumber={gstNumber}
                        setGstNumber={setGstNumber}
                        type={"Vendor"}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default VendorComponent;