import { Fragment } from 'react';
import Classes from '../../pages/home/draftOrdersNew/css/draftOrderDetailPage.module.css';
import axiosInstance from '../../utility/axios-instance';
import ShipmentDetails from './shipmentDetails';

const PoEditAside = (props) => {
    const {
        draftOrder: purchaseOrder,
        setShippingDetails,
        shippingDetails,
        setShippingPrice,
        productDetails,
        overAllDiscount,
        setLoadingPurchaseOrderCalculationServiceState,
        inputRef,
        setProduct,
    } = props;


    function checkNullValues(obj, text) {
        try {
            for (const key in obj) {
                if (
                    key !== "createdAt" &&
                    key !== "updatedAt" &&
                    key !== "purchaseOrderId" &&
                    key !== `po${text}DetailId`
                ) {
                    if (obj[key] !== null) {
                        return true;
                    }
                }
            }
            return false;
        } catch (err) {
            console.log(err);
        }
    }



    const handleShippingPrice = (event) => {
        event.preventDefault();

            let shipping = {
                price: +event?.target?.value || 0,
            }

            let data = {}
            if (overAllDiscount.length !== 0) {
                data = {
                    lineItems: productDetails,
                    overallDiscount: overAllDiscount[0],
                    shippingLine: shipping
                }
            } else {
                data = {
                    lineItems: productDetails,
                    shippingLine: shipping
                }
            }
            setLoadingPurchaseOrderCalculationServiceState(true);
            const endpoint = '/calculate/purchaseOrder'
            axiosInstance
                .post(endpoint, { ...data })
                .then((res) => {
                    setProduct(res?.data);
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    alert(errorMessage || 'An error occurred while calculating the purchase order');
                })
                .finally(() => {
                    let calculatePurchaseOrderRoutePendingCount = localStorage.getItem(endpoint);
                    calculatePurchaseOrderRoutePendingCount = calculatePurchaseOrderRoutePendingCount ? parseInt(calculatePurchaseOrderRoutePendingCount) : 0;
                    if (calculatePurchaseOrderRoutePendingCount === 0) {
                        setLoadingPurchaseOrderCalculationServiceState(false);
                    }
                })

        setShippingPrice([shipping]);
    }

    const handleShippingChange = (key, e) => {
        if (key === 'freightCharges') {
            setShippingDetails({ ...purchaseOrder?.PoShipmentDetails, [key]: Number(e.target.value) });
        }
        else {
            setShippingDetails({ ...purchaseOrder?.PoShipmentDetails, [key]: e.target.value });
        }
    }

    return (
        <Fragment>
            <div
                className={[
                    Classes.Box,
                    'box'
                ].join(' ')}
            >
                {purchaseOrder.length !== 0 && (
                    <div>
                        {purchaseOrder?.PoVendorDetails.length !== 0 && (
                            <div>
                                <div>
                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        <div className={Classes.containerBox}>
                                            <div className={Classes.asideCustomer}>
                                                <h6 style={{ margin: 'auto 0px' }}>
                                                    Vendor
                                                </h6>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: '#2c6ecb',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <p style={{ marginRight: '5px' }}>
                                                        {purchaseOrder?.PoVendorDetails[0]?.vendorName}
                                                    </p>
                                                    <p>GST - {purchaseOrder?.PoVendorDetails[0]?.vendorGstNumber}</p>
                                                    <p>{purchaseOrder?.PoVendorDetails[0]?.vendorCode}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        {checkNullValues(purchaseOrder.PoVendorContactDetails, "VendorContact") !== false && (
                                            <div className={Classes.containerBox}>
                                                <div className='mb-3'>
                                                    <p className={Classes.asideHeaders}>
                                                        CONTACT INFORMATION
                                                    </p>
                                                </div>
                                                {purchaseOrder?.PoVendorContactDetails !== null && (
                                                    <div
                                                        style={{
                                                            color: '#2c6ecb',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        <p>{`${purchaseOrder?.PoVendorContactDetails?.firstName} ${purchaseOrder?.PoVendorContactDetails?.lastName || ""}`}</p>
                                                        <p>{purchaseOrder?.PoVendorContactDetails?.role}</p>
                                                        <p>{purchaseOrder?.PoVendorContactDetails?.email}</p>
                                                        <p>{purchaseOrder?.PoVendorContactDetails?.phoneNumber}</p>

                                                        {/* <p style={{ marginTop: '5px' }}>
                                        </p> */}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {purchaseOrder?.PoVendorDetails !== null && (
                                    <div>
                                        <div>
                                            {purchaseOrder?.PoVendorDetails.length !== 0 ? (
                                                <div>
                                                    {purchaseOrder?.PoVendorDetails.map((vendor, index) => (
                                                        <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                                            <div className={Classes.containerBox}>
                                                                <div>
                                                                    <div className='mb-3'>
                                                                        <p className={Classes.asideHeaders}>{vendor.addressType === "Shipping" ? "PICKUP" : "BILLING" } ADDRESS</p>
                                                                    </div>

                                                                    <div key={index} style={{ fontSize: '14px' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <p style={{ marginRight: '5px' }}>{vendor?.firstName}</p>
                                                                            <p>{vendor?.lastName || ""}</p>
                                                                        </div>

                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <p style={{ marginRight: '5px' }}>{vendor?.addressLine1}</p>
                                                                            {vendor?.addressLine2 !== null ? (
                                                                                <p>{vendor?.addressLine2}</p>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </div>

                                                                        <div style={{ display: 'flex' }}>
                                                                            <p style={{ marginRight: '5px' }}>{vendor?.pincode}</p>
                                                                            <p>{vendor?.city},</p>
                                                                        </div>

                                                                        <div>
                                                                            <p>{vendor?.state?.toTitleCase()},</p>
                                                                        </div>

                                                                        <div>
                                                                            <p>{vendor?.phoneNumber}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null}

                                        </div>

                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                )}
            </div>
            <div
                className={[
                    Classes.Box,
                    'box'
                ].join(' ')}
            >
                {purchaseOrder.length !== 0 && (
                    <div>
                        {purchaseOrder?.PoLocationDetails.length !== 0 && (
                            <div>
                                <div>
                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        <div className={Classes.containerBox}>
                                            <div className={Classes.asideCustomer}>
                                                <h6 style={{ margin: 'auto 0px' }}>
                                                    Location
                                                </h6>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: '#2c6ecb',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <p style={{ marginRight: '5px' }}>
                                                        {purchaseOrder?.PoLocationDetails[0]?.locationName}
                                                    </p>
                                                    <p>GST - {purchaseOrder?.PoLocationDetails[0]?.locationGstNumber}</p>
                                                    <p>{purchaseOrder?.PoLocationDetails[0]?.locationCode}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        {checkNullValues(purchaseOrder.PoLocationContactDetails, "LocationContact") !== false && (
                                            <div className={Classes.containerBox}>
                                                <div className='mb-3'>
                                                    <p className={Classes.asideHeaders}>
                                                        CONTACT INFORMATION
                                                    </p>
                                                </div>

                                                {purchaseOrder?.PoLocationContactDetails !== null && (
                                                    <div
                                                        style={{
                                                            color: '#2c6ecb',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        <p>{`${purchaseOrder?.PoLocationContactDetails?.firstName} ${purchaseOrder?.PoLocationContactDetails?.lastName || ""}`}</p>
                                                        <p>{purchaseOrder?.PoLocationContactDetails?.role}</p>
                                                        <p>{purchaseOrder?.PoLocationContactDetails?.email}</p>
                                                        <p>{purchaseOrder?.PoLocationContactDetails?.phoneNumber}</p>

                                                        {/* <p style={{ marginTop: '5px' }}>
                                        </p> */}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div>
                                    {purchaseOrder?.PoLocationDetails.length !== 0 ? (
                                        <div>
                                            {purchaseOrder?.PoLocationDetails.map((location, index) => (
                                                <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                                    <div className={Classes.containerBox}>
                                                        <div>
                                                            <div className='mb-3'>
                                                                <p className={Classes.asideHeaders}>{location.addressType?.toUpperCase()} ADDRESS</p>
                                                            </div>

                                                            <div key={index} style={{ fontSize: '14px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.firstName}</p>
                                                                    <p>{location?.lastName || ""}</p>
                                                                </div>

                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.addressLine1}</p>
                                                                    {location?.addressLine2 !== null ? (
                                                                        <p>{location?.addressLine2}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.pincode}</p>
                                                                    <p>{location?.city},</p>
                                                                </div>

                                                                <div>
                                                                    <p>{location?.state?.toTitleCase()},</p>
                                                                </div>

                                                                <div>
                                                                    <p>{location?.phoneNumber}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>

                            </div>
                        )}
                    </div>
                )}
            </div>

            <ShipmentDetails 
                shippingDetails={shippingDetails}
                handleShippingChange={handleShippingChange}
                handleShippingPrice={handleShippingPrice}
                inputRef={inputRef}
            />
   
        </Fragment>
    )
};

export default PoEditAside;
