import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

const websiteSettingsData = [
  {
    src: "/assets/icons/product-range-settings.svg",
    alt: "productSettingsIcon",
    text: "Product-Range",
    navigate: "productRange",
    service: "productRange",
    subTitle: "Explore Our Diverse Collection of Products"
  },
  {
    src: "/assets/icons/store-location.svg",
    alt: "storeSettingIcon",
    text: "Stores",
    navigate: "stores",
    service: "stores",
    subTitle: "Manage and Add New Stores Easily"
  },
  {
    src: "/assets/icons/customer-settings.svg",
    alt: "customerSettingsIcon",
    text: "Customer",
    navigate: "customer",
    service: "customer",
    subTitle: "Customize the media for the Customer's Section"
  },
  {
    src: "/assets/icons/dynamic-banner.svg",
    alt: "dynamicBannersIcon",
    text: "Dynamic Banners",
    navigate: "dynamicBanners",
    service: "dynamicBanners",
    subTitle: "Create or Update Dynamic Banners (Currently Inactive)"
  },
  {
    src: "/assets/icons/main-banner.svg",
    alt: "mainBannersIcon",
    text: "Main Banners",
    navigate: "mainBanners",
    service: "mainBanners",
    subTitle: "Manage and Add New Banner in the Carousel"
  },
  {
    src: "/assets/icons/laminate.svg",
    alt: "LaminatesIcon",
    text: "Laminates",
    navigate: "Laminates",
    service: "Laminates",
    subTitle: "Explore or Update the Laminates for laminates.depo24.in"
  },
];

const settingsData = [
  {
    src: "/assets/icons/discount.png",
    alt: "discountIcon",
    text: "Discount",
    navigate: "discount",
    service: "discount",
    subTitle: "Configuring Product and Order Discount Settings"
  },
  {
    src: "/assets/icons/location.svg",
    alt: "pincodeIcon",
    text: "Pincode",
    navigate: "pincode",
    service: "pincode",
    subTitle: "Optimizing Delivery Areas and Services with Pincode Configuration"
  },
  {
    src: "/assets/icons/website-settings.svg",
    alt: "webSettingsIcon",
    text: "Website",
    navigate: "website",
    service: "website",
    subTitle: "End to End Configuration of the DEPO24 Main Website"
  },
  {
    src: "/assets/icons/company-settings.svg",
    alt: "companySettingsIcon",
    text: "Company",
    navigate: "company",
    service: "company",
    subTitle: "Creating and Managing Your Business Profiles with Company Settings"
  },
  {
    src: "/assets/icons/money.svg",
    alt: "PaymentGatewayTransactions",
    text: "Payment Gateway Transactions",
    navigate: "paymentGatewayTransactions",
    service: "paymentGatewayTransactions",
    subTitle: "Monitoring and Managing Transactions"
  },
  {
    src: "/assets/icons/layer.svg",
    alt: "UserActivities",
    text: "User Activities",
    navigate: "userActivities",
    service: "userActivities",
    subTitle: "Tracking and Analyzing User Activity"
  },
  {
    src: "/assets/icons/ringing.svg",
    alt: "Notifications",
    text: "Notification",
    navigate: "Notifications",
    service: "Notifications",
    subTitle: "Manage the User's Notification Preferences"
  },
  {
    src: "/assets/icons/admin.svg",
    service: "users",
    alt: "Admin",
    text: "Admin",
    navigate: "users",
    subTitle: "Managing Administrative Permissions Settings"
  },
  {
    src: "/assets/icons/house-decoration.png",
    service: "catalogues",
    alt: "Catalogues",
    text: "Catalogues",
    navigate: "catalogues",
    subTitle: "Creating and Managing Catalogues"
  },
];

export { websiteSettingsData, settingsData };