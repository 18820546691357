import { useSelector } from 'react-redux';
import { setOrderDocs } from '../../../store/orderDocsSlice';
import Table from '../../../components/table/table';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { requestSignedUrls } from '../../../utility/s3';
import SnackBar from '../../../components/SnackBar';

const DocsListDashboard = (props) => {
    const auth = useSelector((state) => state.auth);
    const orderDocs = useSelector((state) => state?.orderDocs);
    const [locationCodes, setLocationCodes] = useState(auth?.user?.settings?.locationSetting || []);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const fetchLocations = ()=>{
        // fetch locations
        axiosInstance
            .get('/location/locations')
            .then((res) => {
                const locations = res?.data?.data;
                const locationCodes = locations.map((location) => location?.locationCode);
                setLocationCodes(locationCodes);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        if(["super-admin", "admin"].includes(auth?.user?.role)){
            fetchLocations();
        }
    }, [])

    const handleSelect = (e,value, row) => {
        const url = row?.documents?.[value]
        if(!url){
            alert('No document found');
            return;
        }
        requestSignedUrls([url], 1).then((signedUrls) => {
            signedUrls.forEach((url) => {
                window.open(url, '_blank');
            });
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: 'Pdf Download Successfully', type: "success" }
            });
        });
    }

    const PIRenderCell = (row) => {
        return (
            Object.keys(row?.documents)?.length > 0 ?
                <Autocomplete
                    id="multiple-limit-tags"
                    size="small"
                    disablePortal
                    options={Object.keys(row?.documents)}
                    onChange={(e, newValue) => handleSelect(e, newValue, row)}
                    sx={{width:'250px'}}
                    renderInput={(params) => (
                        <TextField {...params} label="Documents" variant="outlined" />
                    )}
                />
            : 'N/A'
        )
    }

    let columns = [
        {
            field: 'orderName',
            headerName: 'ORDER',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdAt',
            headerName: 'DATE',
            flex: 1.7,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'status',
            headerName: 'STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'enum',
            tableName: 'Order',
            columnName: 'status',
            renderCell: (params) => (
                params.value === "Cancelled" || params?.value === 'Delivered' ? (
                    <Chip label={params.value} style={{ backgroundColor: params.value === "Cancelled" ? '#e54545' : '#128038', color: '#fff' }} />
                ) : (
                    <Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
                )
            )
        },
        {
            field: 'locationCodes',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable: true,
            enumValues: locationCodes,
            renderCell: (params) => (params?.row?.locationCodes.join(', '))
        },
        {
            field: 'total',
            renderCell: (params) => `₹${params?.row?.total}`,
            headerName: 'TOTAL',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'pi',
            headerName: 'Docs',
            flex: 1.5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <PIRenderCell {...params?.row} /> ),
            customRenderCell: PIRenderCell
        }
    ];

    return (
        <div>
            <Table
                columns={columns}
                dataPosition={"orders"}
                endpoint={'/pgOrder/admin/orders/docsList'}
                rowId={'orderName'}
                checkBoxSelection={true}
                service={'orders'}
                tabName={'Docs List'}
                setData={setOrderDocs}
                data={orderDocs}
                mobileResponsive={true}
                exportEndpoint={'/exports/orders'}
                exportFileName={`Orders - ${new Date()}`}
                downloadDocsBulk={true}
            />

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    )
}

export default DocsListDashboard;