import React, { useEffect, useState } from 'react'
import Table from '../../../components/table/table';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css'
import { setDiscounts } from '../../../store/discountSettingSlice';
import { useSelector } from 'react-redux';
import Permission from '../../auth/permissions';
import ButtonComponent from '../../../components/button';
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Button, Chip, Switch } from '@mui/material';
import CreateDiscount from './createDiscount';
import SnackBar from '../../../components/SnackBar';
import axiosInstance from '../../../utility/axios-instance';
import Spinner from "../../../components/spinner";
import { CheckRoleBasedPermission, services } from '../../../utility/checkRoleBasedPermission';
import { useNavigate } from 'react-router-dom';
import {Delete, CurrencyRupee, Percent} from '@mui/icons-material';

const Discount = () => {
    const [reRender, setReRender] = useState(false);
    const discounts = useSelector((state) => state.discountSettings);
    const auth = useSelector((state) => state.auth);
    const user = auth?.user
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    

    const handleUpdateActiveStatus = async (activeStatus, selectedDiscountId) => {
        try{
            setLoading(true);
            const updateDiscount = ![activeStatus ,selectedDiscountId].includes(null) && (
                    await axiosInstance.put(`/settings/updateActiveStatus/${selectedDiscountId}`, {active : activeStatus})
            );

            const successMessage = "Active status updated Successfully";
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
            });
            fetchDiscounts();
        }
        catch(error){
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: error?.response?.data?.errorMessage , type: "error" }
            });
        }
        finally {
            setLoading(false)
        }
    }

    const handleDeleteDiscount = async (discountId) => {
        setLoading(true)
        await axiosInstance
            .delete(`/settings/discount/${discountId}`)
            .then((res) => {
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: "DiscountCode Deleted Successfully", type: "success" }
                });
                setLoading(false)
                setReRender(!reRender);
            })
            .catch((err) => {
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: err?.response?.data?.errorMessage , type: "error" }
                });
            });
    }
    const columnVisibilityModel={updatedAt : false, deleted : false, uptoDiscountAmount : false, 
        createdBy : false, expireDate : false, customers : false, sku : false, description: false,
        discountValue : false, discountType : false, discountMinPrice: false, maxUses : false, customer_roles : false, brands : false}

    const DeletedCodeRenderCell = (row) => {
        return (
            <Permission service={services?.discountSetting} permission={"delete"}>
                <div style={{cursor : 'pointer'}} onClick={() => handleDeleteDiscount(row?.discountId)}><Delete /></div>
            </Permission>
        )
    }

    const DiscountCodeRenderCell = (row) => {
        return (
            <div style={{fontWeight : '600', fontSize: '14px', color : row?.active ? '#1769aa' : 'grey'}}>
                {row?.discountCode}
            </div>
        )
    }

    const DetailsRenderCell = (row) => {
        return (
            <div>
                <b>{row?.discountValue}</b>
                {row?.discountType === 'PERCENTAGE' ? 
                    <>
                        <Percent color='grey' style={{fontSize:'14px'}}/> 
                        <i>
                            Upto 
                            <b>{row?.uptoDiscountAmount}</b>
                        </i>
                        <CurrencyRupee color='grey' style={{fontSize:'14px'}}/>
                    </>
                : 
                    <CurrencyRupee color='grey' style={{fontSize:'14px'}}/>  
                } discount on Minimum Purchase amount 
                <b>{row?.discountMinPrice}</b>
                <CurrencyRupee color='grey' style={{fontSize:'14px'}} />
            </div>
        )
    };

    const UptoDiscountAmountRenderCell = (row) => {
        return (
            row?.uptoDiscountAmount ? (
                <div style={{fontWeight : '500'}}>
                    {row?.uptoDiscountAmount}<CurrencyRupee color='grey' style={{fontSize:'14px'}}/>
                </div>) 
            : null
        )
    }

    const AmountOffRenderCell = (row) => {
        return (
            row?.amountOff === 'Order' ? "Order discount" : "Product discount"
        )
    }

    const SkuRenderCell = (row) => {
        return (
            <div style={{display: 'flex', flexWrap : 'wrap'}}>
                {row?.DiscountFilters[0]?.sku?.map(item => <Chip label={item} variant='filled'/>)}
            </div>
        )
    }

    const ActiveRenderCell = (row) => {
        return (
            <div>
                <Switch
                    checked={row?.active}
                    required
                    onChange={(e) => {
                        handleUpdateActiveStatus(e.target.checked, row?.discountId)
                    }}
                    disabled={!CheckRoleBasedPermission(user, services.discountSetting, "update")}
                />
            </div>
        )
    }

    const BrandRenderCell = (row) => {
        return (
            <div>
                {row?.DiscountFilters[0]?.brands?.map(item => <Chip label={item} variant='filled'/>)}
            </div>
        )
    }
    const columns = [
        {
            field: `discountId`,
            headerName: `ID`,
            flex: .3,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `deleted`,
            headerName: `Delete Code`,
            flex: .3,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => ( <DeletedCodeRenderCell {...params?.row} />),
            customRenderCell: DeletedCodeRenderCell,
        },
        {
            field: `discountCode`,
            headerName: `Discount Code`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => ( <DiscountCodeRenderCell {...params?.row} />),
            customRenderCell: DiscountCodeRenderCell,
        },
        {
            field: `details`,
            headerName: `Details`,
            flex: 3.5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell : (params) => ( <DetailsRenderCell {...params?.row} /> ),
            customRenderCell: DetailsRenderCell
        },
        {
            field: `discountType`,
            headerName: `TYPE`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `discountValue`,
            headerName: `VALUE`,
            flex: .7,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `discountMinPrice`,
            headerName: `Min Price`,
            flex: .7,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `description`,
            headerName: `Description`,
            flex: 1.6,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: true,
        },
        {
            field: `uptoDiscountAmount`,
            headerName: `Upto Discount`,
            flex: .6,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: true,
            renderCell : (params) => ( <UptoDiscountAmountRenderCell {...params?.row} /> ),
            customRenderCell: UptoDiscountAmountRenderCell,
        },
        {
            field: `amountOff`,
            headerName: `Discount For`,
            flex: .9,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: true,
            renderCell : (params) => ( <AmountOffRenderCell {...params?.row} /> ),
            customRenderCell: AmountOffRenderCell
        },
        {
            field: `sku`,
            headerName: `Products SKU`,
            flex: 1.6,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: true,
            renderCell : (params) => ( <SkuRenderCell {...params?.row} /> ),
            customRenderCell: SkuRenderCell
        },
        {
            field: `active`,
            headerName: `ACTIVE`,
            flex: .6,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell : (params) => ( <ActiveRenderCell {...params?.row} />),
            customRenderCell: ActiveRenderCell
        },
        {
            field: `createdBy`,
            headerName: `Created By`,
            flex: .8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `createdAt`,
            headerName: `Created At`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `maxUses`,
            headerName: `Max Uses`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `updatedAt`,
            headerName: `Updated At`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `expireDate`,
            headerName: `Expire At`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `customers`,
            headerName: `Specific customers`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `customer_roles`,
            headerName: `Specific customer roles`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `brands`,
            headerName: `Specific brands`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell : (params) => ( <BrandRenderCell {...params?.row} />),
            customRenderCell: BrandRenderCell
        },
    ];

    const fetchDiscounts = async () => {
        setReRender(!reRender);
    }   

    return (
        <>
            <div style={{marginTop : '20px'}}>
                <Permission service={services?.discountSetting} permission={"create"}>
                    <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'5px'}}>
                        <Button startIcon={<AddCircleIcon />} 
                            sx={{
                                textTransform: "capitalize",
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'16px'}
                            }}
                            variant="contained"
                            onClick={() => navigate('createDiscount')}
                        >Add New</Button>
                    </div>
                </Permission>
                <Permission service={services?.discountSetting} permission={"read"}>
                    <Table 
                        rowId={'discountId'} 
                        columns={columns} 
                        dataPosition={"allDiscounts"} 
                        endpoint={'/settings/getAllDiscount'}
                        checkBoxSelection={false} 
                        reRender={reRender} 
                        service={'discount'}
                        tabName={'Discount'}
                        setData={setDiscounts}
                        data={discounts}
                        columnVisibilityModel={columnVisibilityModel}
                        mobileResponsive={true}
                    />
                    {loading ? <Spinner /> : null}
                </Permission>
            </div>
            {loading ? <Spinner /> : null}
        <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </>
    )
}
export default  Discount;