import React, { useEffect } from "react";
import Classes from "./css/addProduct.module.css";
import { IconButton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import axiosInstance from "../../../../utility/axios-instance";
import { priceTypesDiscountMap } from "./AllFunctions";
import MultiPriceCard from "./MultiPriceCard";
const DEFAULT_DISTRICT = "KOLKATA";

export default function MultiPricing({
  priceCategory,
  payloadToUpload,
  setPayloadToUpload,
  endpointToFetchCategories,
}) {
  const [priceCategories, setPriceCategories] = React.useState([]);
  const priceCategoryAttribute = priceCategory + "BasedPrices";

  const addMultiPricing = (e) => {
    // priceCategory is either district or franchise
    const priceCardId = uuidv4();
    const pricesAndDiscounts = Object.keys(priceTypesDiscountMap).reduce(
      (acc, priceType) => {
        acc[priceType + "Price"] = ""; // set all price types to empty string
        acc[priceTypesDiscountMap[priceType]] = ""; // set all discount types to empty string
        return acc;
      },
      {}
    );
    setPayloadToUpload((prevPayloadToUpload) => {
      return {
        ...prevPayloadToUpload,
        [priceCategoryAttribute]: [
          ...(prevPayloadToUpload?.[priceCategoryAttribute] || []),
          {
            priceCardId,
            [priceCategory]: "",
            ...pricesAndDiscounts,
          },
        ],
      };
    });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance(endpointToFetchCategories);
        let priceCategoriesData = await response?.data?.data?.map((category) => {
          return category;
        })?.unique();
        if(priceCategory === 'district'){
          priceCategoriesData = priceCategoriesData?.filter((category) => {
            return category !== DEFAULT_DISTRICT;
          })
        }
        setPriceCategories(priceCategoriesData);
      } catch (error) {
        console.error(`Error fetching ${priceCategory}:`, error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <React.Fragment>
      <div
        className={Classes.Pricing}
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "5px 12px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {priceCategory?.toTitleCase()} Wise Pricing
        </div>
        {payloadToUpload?.defaultPrices?.mrp !== "" && (
          <IconButton onClick={(e) => addMultiPricing(e, priceCategory)}>
            <ControlPointIcon />
          </IconButton>
        )}
      </div>
      {payloadToUpload?.[priceCategoryAttribute]?.length > 0 &&
        payloadToUpload?.[priceCategoryAttribute]?.map(
          (pricesObject) => {
            return (
              <MultiPriceCard
                pricesObject={pricesObject}
                priceCategories={priceCategories}
                priceCategory={priceCategory}
                payloadToUpload={payloadToUpload}
                setPayloadToUpload={setPayloadToUpload}
                priceCategoryAttribute={priceCategoryAttribute}
              />
            );
          }
        )}
    </React.Fragment>
  );
}
