import React, {Fragment,useCallback,useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { debounce } from "../../../../utility/utilityFunctions";
import { useEffect } from "react";

const DefaultBillingAdressPopUp = (props)=> {
    const [searchText, setSearchText] = useState('');
    const [deleteAddressArray,setDeleteAddressArray] = useState([]);
    const [loading,setLoading] = useState(false);
    
    const {
        defaultBillingAddressModal,
        hideDefaultBillingAddressPop,
        showCustomerDetail,
        addressArray,
        previousTarget,
        setPreviousTarget,
        setAddressArray,
        fetchCustomerAddresses,
        selectedBillingAddress,
        setSelectedBillingAddress
    } = props;

    const handleBillingAddressSelection =(event,addressId) => {
        setPreviousTarget([event.target.id])
        axiosInstance
            .get(`/user/admin/${showCustomerDetail[0]?._id}/address/${addressId}`)
            .then((res) =>{
                setSelectedBillingAddress(res?.data?.data);
            });
    }

    const handleDeleteAddressId = (event) => {
        if (event.target.checked) {
            document.getElementById('deleteAddressBtn').style.display = 'block';
            let array = deleteAddressArray;
            array.push({id: event.target.value})
            setDeleteAddressArray(array)
        } else {
            let array = deleteAddressArray;
            const store = array.filter((ele) => ele.id !== event.target.value)
            if (store.length === 0) {
                document.getElementById('deleteAddressBtn').style.display = 'none';
            }
            setDeleteAddressArray(store)
        }
    }

    const handleDeleteAddress = async (event) => {
        if (deleteAddressArray.length !== 0) {
            setLoading(true);
            const array = [];
            for (let ele of deleteAddressArray) {
                array.push(ele.id)
            }
            await axiosInstance
                .post(`/user/admin/${showCustomerDetail?.[0]?._id}/deleteAddressInBulk`, {
                    addressId: array
                })
                .then(async (res) =>{
                    await axiosInstance
                        .get(`/user/admin/${showCustomerDetail?.[0]?._id}/address`)
                        .then((resp) => {
                            const arr = []
                            setDeleteAddressArray(arr);
                            if (resp.data.data !== undefined) {
                                setAddressArray(resp.data.data);
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.log(err,'error')
                        })
                });
        }
    }

   
    const searchAddress = async (searchText, customerId) => {
        fetchCustomerAddresses(customerId, searchText)
    }

    // useCallback ensures that debouncedAddressSearch remains the same across renders
    // such that it doesn't defeat the purpose of debouncing
    const debouncedAddressSearch = useCallback(debounce(searchAddress, 500), []);

    useEffect(() => {
        if(showCustomerDetail?.[0]?._id !== undefined){
            debouncedAddressSearch(searchText, showCustomerDetail?.[0]?._id);
        }
    }, [searchText, debouncedAddressSearch]);

    const updateDefaultBillingAddress = (event, addressId, checked) => {
        setLoading(true);
        axiosInstance
            .post(`/user/admin/${showCustomerDetail?.[0]?._id}/address/${addressId}`, {
                default: checked
            })
            .then((res) => {
                setLoading(false);
                setSelectedBillingAddress(res?.data?.data);
                const updatedAddressArray = addressArray.map((address) => {
                    if (address._id === addressId && address.addressType === 'billing') {
                        address.default = checked;
                    }else if(address._id !== addressId && address.addressType === 'billing'){
                        address.default = false;
                    }
                    return address;
                });
                setAddressArray([...updatedAddressArray]);
            });
    }

    return (
        <Fragment>
            {defaultBillingAddressModal && (
                <div className={Classes.modal} style={{bottom:'6%', zIndex:'9999'}}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage address</p>
                            <div style={{display:'flex'}}>
                                <p 
                                    className={Classes.DeleteAddressBtn}
                                    id='deleteAddressBtn'
                                    onClick={handleDeleteAddress}
                                >
                                    Delete Address
                                </p>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    className={Classes.ClosePopUpIcon}
                                    onClick={hideDefaultBillingAddressPop}
                                />
                            </div>
                        </div>

                        <div className={Classes.BoxContent} style={{
                            padding: "0px 25px 20px 25px"
                        }}>
                           <TextField 
                                size="small" 
                                type="text" 
                                label="Search Address" 
                                onChange={(e) => {
                                    setSearchText(e?.target?.value);
                                }} 
                            />
                        </div>
                    </div>
                    
                    <div style={{ overflowY: 'auto', height: 486 }}>
                        <div style={{ marginTop: '10px', padding: '10px 20px' }}>
                            <div>
                                <p style={{ fontWeight: '600', fontSize: '14px' }}>
                                    SELECT ADDRESS
                                </p>
                            </div>
                        </div>
                        {showCustomerDetail?.length !== 0 ?
                            <div>
                                {addressArray?.map((address, index) => (
                                    <>
                                        {
                                        (
                                            <div key={index} style={{ borderBottom: '1px solid #ced4da', padding: '20px' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ width: '5%' }}>
                                                        <input
                                                            type="checkbox"
                                                            id={index}
                                                            value={address?._id}
                                                            onChange={handleDeleteAddressId}
                                                        />
                                                    </div>
                                                    <div className={Classes.AddressContainer}>
                                                        {address.nick_name ? <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.nick_name}
                                                            </p>
                                                        </div> : null}
                                                        
                                                        <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.firstName}
                                                            </p>
                                                            {address?.lastName !== 'undefined'
                                                                ? <p>{address?.lastName}</p>
                                                                : null
                                                            }
                                                        </div>


                                                        <div>
                                                            <p>{address.address1}</p>
                                                            {address?.address2 !== null
                                                                ? <p>{address?.address2}</p>
                                                                : null}
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.zip},
                                                            </p>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.city}
                                                            </p>
                                                            <p>{address?.province?.toTitleCase()}</p>
                                                        </div>
                                                        <div>
                                                            <p>{address?.country}</p>
                                                        </div>

                                                        <p>{address?.phone}</p>
                                                    </div>

                                                    <div style={{
                                                        marginLeft: 'auto',
                                                    }}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <p>Address Type</p>
                                                            <p
                                                                className={Classes.AddressType}
                                                                style={{
                                                                    backgroundColor: address?.addressType === 'shipping' ?
                                                                        'turquoise' : 'greenyellow'
                                                                }}
                                                            >
                                                                {address.addressType}
                                                            </p>
                                                        </div>
                                                        {selectedBillingAddress !== null ? (
                                                            <div style={{ marginTop: 60 }}>
                                                                {selectedBillingAddress?._id === address?._id ? (
                                                                    <button
                                                                        id={address?._id}
                                                                        className={Classes.selectedAddress}
                                                                        disabled={true}
                                                                    >
                                                                        Selected
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        id={address._id}
                                                                        className={Classes.selectAddress}
                                                                        onClick={(e) => handleBillingAddressSelection(e, address?._id)}
                                                                    >
                                                                        Select
                                                                    </button>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div style={{ marginTop: 60 }}>
                                                                <button
                                                                    id={address?._id}
                                                                    className={Classes.selectAddress}
                                                                    onClick={(e) => handleBillingAddressSelection(e, address?._id)}
                                                                >
                                                                    Select
                                                                </button>
                                                            </div>
                                                        )}
                                                        { address?.addressType === "billing" && 
                                                            <FormControlLabel 
                                                                control={
                                                                    <Checkbox 
                                                                        checked={address?.default}
                                                                        onChange={(event, checked)=>{updateDefaultBillingAddress(event,address?._id, checked)}}
                                                                    />
                                                                } 
                                                                label="Default" 
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>

                                ))}
                            </div>
                        : ''}
                        <div style={{
                            height: "80%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                        }}>
                            {addressArray?.length === 0 && showCustomerDetail?.length !== 0
                                ? <div style={{padding:'20px'}}>
                                    <p style={{textAlign:'center'}}>No address found</p>
                                </div>
                            : ''}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default DefaultBillingAdressPopUp;