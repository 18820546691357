import { Delete } from "@mui/icons-material";
import { Backdrop, CircularProgress, Dialog, DialogActions, DialogTitle, Slide, Button, DialogContent, Typography } from "@mui/material";
import { forwardRef, useState } from "react";
import axiosInstance from "../../../../utility/axios-instance";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DeleteCatalogue = (props) => {
    const [loading, setLoading] = useState(false);
    const { open, handleClose, catalogueId, setSnackBar, setReRender } = props;

    const handleDeleteCatalogue = async () => {
        setLoading(true);
        await axiosInstance
            .delete(`/catalogues/${catalogueId}`)
            .then((response) => {
                setReRender((prev) => !prev);
                const successMessage = `Catalogue Deleted Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleClose();
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setReRender((prev) => !prev);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            fullWidth={true}
            sx={{'& .MuiDialog-paper': {maxWidth:'500px'}}}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <DialogTitle style={{ textAlign:'center' }}>
                Delete Catalogue
            </DialogTitle>

            <DialogContent>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:'center'}}>
                    Are you sure you want to delete this Catalogue?
                </Typography>
            </DialogContent>

            <DialogActions style={{ padding:"14px 20px" }}>
                <Button variant="outlined" onClick={handleClose} style={{ color:'gray',borderColor:'gray' }}>
                    Cancel
                </Button>

                <Button 
                    variant="outlined" 
                    startIcon={<Delete />} 
                    style={{ textTransform:'capitalize', padding:'5px 10px'}}
                    onClick={handleDeleteCatalogue}
                >
                    Delete
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default DeleteCatalogue;