import { useParams } from "react-router-dom";
import Table from "../../../../components/table/table";
import { setCatalogueDetails } from "../../../../store/catalogueDetails";
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from "../../../../store/breadcrumbSlice";
import { useEffect } from "react";
import axiosInstance from "../../../../utility/axios-instance";

const columns = [
    {
        field: 'catalogueName',
        headerName: 'Catalogue Name',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        type: 'enum',
        columnName: 'name',
        tableName: 'catalogues'
    },
    {
        field: 'reportType',
        headerName: 'Report Type',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        type: 'enum',
        columnName: 'report_type',
        tableName: 'downloaded_reports'
    },
    {
        field: 'requestedBy',
        headerName: 'Customer Name',
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'phone',
        headerName: 'Phone',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'customerCode',
        headerName: 'Customer Code',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'medium',
        headerName: 'Medium',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        type: 'enum',
        columnName: 'medium',
        tableName: 'downloaded_reports'
    },
    {
        field: 'created_at',
        headerName: 'Date',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
]

const CatalogueDetails = () => {
    const { id } = useParams();
    const catalogueDetails = useSelector((state) => state?.catalogueDetails);
    const breadCrumb = useSelector((state) => state.breadcrumb);
    const dispatch = useDispatch();

    useEffect(() => {
        axiosInstance
            .get(`/catalogues/detail/${id}`)
            .then((res) => {
                dispatch(setBreadcrumb({ ...breadCrumb, catalogueName: res?.data?.data?.name }));
            })
    }, [id]);

    return (
        <Table
            columns={columns}
            dataPosition={"catalogueDetails"}
            endpoint={`/catalogues/${id}`}
            rowId={'id'}
            checkBoxSelection={false}
            service={'catalogues'}
            tabName={'Catalogues'}
            setData={setCatalogueDetails}
            data={catalogueDetails}
            version="version-2"
            mobileResponsive={true}
            exportEndpoint={'/exports/catalogues'}
            exportFileName={`Catalogues - ${new Date()}`}
        />
    )
}

export default CatalogueDetails;