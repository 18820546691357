import { Fragment, useEffect, useState } from 'react';
import { BiArrowBack} from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import Classes from './css/addProduct.module.css';
import axiosInstance from '../../../../utility/axios-instance';
import Aside from '../addProducts/aside/aside';
import GroupTitle from '../addProducts/GroupTitle';
import Media from '../addProducts/MediaFile';
import ProductBasicDetails from '../addProducts/ProductBasicDetails';
import Inventory from '../addProducts/Inventory';
import Variants from '../addProducts/Variants';
import './css/specialImg.css';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb } from '../../../../store/breadcrumbSlice';
import MainQrcodeBarcode from './MainQrCode';
import ConfirmPopup from './deleteConfirmPopup';
import Permission from '../../../auth/permissions';
import { services } from '../../../../utility/checkRoleBasedPermission';
import MultiPricing from '../addProducts/MultiPricing';
import UserDefinedFields from '../addProducts/UserDefinedFields';
import ChildProductsCollections from '../addProducts/childProductsCollections';
import ProductHistory from '../addProducts/aside/productHistory';
import { Button } from 'react-bootstrap';
import Spinner from '../../../../components/spinner';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { v4 as uuidv4 } from "uuid";
import { handleParentProductMRP } from '../addProducts/AllFunctions';
import SnackBar from '../../../../components/SnackBar';

const ProductDetails = () => {
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const dispatch = useDispatch();

    // aside
    const [payloadToUpload, setPayloadToUpload] = useState({
        salesChannel: [],
        removeSalesChannel: [],
        defaultPrices: {
            gst: "18"
        },
        isCustomOrder: "no",
        isSampleAvailable: "no",
        isTracked: true,
        backOrder: "DENY",
        isTaxable: true,
        images: {
            "150x150": { "01":'', "02":'', "03":'', "04":'', "05":'', "06":'', "07":'', "laminate": '' },
            "400x400": { "01":'', "02":'', "03":'', "04":'', "05":'', "06":'', "07":'', "laminate": '' },
            "1200x1200": { "01":'', "02":'', "03":'', "04":'', "05":'', "06":'', "07":'', "laminate": '' },
        }
    });
    const navigate = useNavigate();
    //Product History
    const [isHistoryPopupOpen, setIsHisotryPopupOpen] = useState(false);

    // deleteProduct
    const [confirmPopupOpen,setConfirmPopUpOpen] = useState(false);

    const [isParentProduct, setIsParentProduct] = useState(false)
    const [isVirtualProduct, setIsVirtualProduct] = useState(false);
    const [isChildProduct, setIsChildProduct] = useState(false);
    const [parentSkus, setParentSkus] = useState([]);
    const [open , setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { Id } = useParams();
    const breadCrumb = useSelector((state) => state.breadcrumb);

    const showHisotryPopup = () => {
        setIsHisotryPopupOpen(true);
    }

    const hideHisotryPopup = () => {
        setIsHisotryPopupOpen(false);
    }

    const handleDeleteProduct = (event,Id) => {
        event.preventDefault();
        setConfirmPopUpOpen(true);
    }

    const hideConfirmPopup = () => {
        setConfirmPopUpOpen(false);
    }

    const handleUpdateProduct = (event) => {
        try {
        event.preventDefault();        
        
        const bulkPacking = [
            {
              type: payloadToUpload?.bulkPackingType1?.trim() || "",
              value: payloadToUpload?.bulkPackingValue1 || "",
              errorMessage: 'Please Add Bulk Packing 1',
            },
            {
              type: payloadToUpload?.bulkPackingType2?.trim() || "",
              value: payloadToUpload?.bulkPackingValue2 || "",
              errorMessage: 'Please Add Bulk Packing 2',
            },
        ]?.filter(({ type, value }) => type || value);
          
        for (const { type, value, errorMessage } of bulkPacking) {
            if ((value && !type) || (!value && type)) {
                alert(errorMessage);
                return;
            }
        }

        const multiPriceData = [
            ...payloadToUpload?.["districtBasedPrices"],
            ...payloadToUpload?.["franchiseBasedPrices"],
          ]?.map((price) => {
            const filteredPrice = Object.keys(price)?.reduce((acc, key) => {
                if (
                    key !== "priceCardId" 
                    && key !== "errors"
                    && price[key] !== "" 
                    && price[key] !== null
                ) {
                    acc[key] = price?.[key];
                }
                return acc;
            }
            , {});
            return {
                ...filteredPrice,
            };
        })?.filter((price) => {
            return Object.keys(price)?.length !== 0;
        })        
        
        // Check if all the required fields are filled
        multiPriceData.forEach((price) => {
          Object.keys(price)?.forEach((key) => {
            if (key === "district" || key === "franchise") {
              if (price[key] === undefined) {
                alert(`Please select ${key} for ${key} prices`);
                throw new Error(`Please select ${key} for ${key} prices`);
              }
              if (
                price["listingDiscount"] === undefined &&
                price["listingPrice"] === undefined
              ) {
                alert(`Please add listingPrice for ${key} prices`);
                throw new Error(`Please add listingPrice for ${key} prices`);
              }
            }
          });
        });
        
        const defaultPrices = Object.keys(payloadToUpload?.defaultPrices)
          .map((price) => {
            return {
              // nullish coalescing operator to only convert null or undefined to ""
              [price]: +payloadToUpload?.defaultPrices?.[price] ?? "", 
            };
          })
          .reduce((acc, curr) => {
            return {
              ...acc,
              ...curr,
            };
          }, {});

        delete defaultPrices?.errors;
        const data = {
          groupName: payloadToUpload?.groupName?.trim(),
          productName: payloadToUpload?.productName?.trim(),
          unit: payloadToUpload?.unit || "",
          l1: payloadToUpload?.l1,
          l2: payloadToUpload?.l2,
          l3: payloadToUpload?.l3,
          sku: payloadToUpload?.sku || "",
          brandName: payloadToUpload?.brandName,
          brandAbr: payloadToUpload?.brandAbr,
          modelNo: payloadToUpload?.modelNo?.trim() || "",
          hsnCode: payloadToUpload?.hsnCode?.toString() || "",
          costPrice:  defaultPrices?.costPrice || "",
          ...defaultPrices,
          variable1Type: payloadToUpload?.variable1Type?.trim() || "",
          variable1Value: payloadToUpload?.variable1Value?.trim() || "",
          variable2Type: payloadToUpload?.variable2Type?.trim() || "",
          variable2Value: payloadToUpload?.variable2Value?.trim() || "",
          variable3Type: payloadToUpload?.variable3Type?.trim() || "",
          variable3Value: payloadToUpload?.variable3Value?.trim() || "",
          productDescription: payloadToUpload?.productDescription?.trim() || "",
          countryOfOrigin: payloadToUpload?.countryOfOrigin?.trim() || "",
          customerCareAddress: payloadToUpload?.customerCareAddress?.trim() || "",
          marketedBy: payloadToUpload?.marketedBy?.trim() || "",
          includedComponents: payloadToUpload?.includedComponents?.trim() || "",
          warranty: payloadToUpload?.warranty?.trim() || "",
          weight: +payloadToUpload?.weight?.trim() || 0,
          materialType: payloadToUpload?.materialType?.trim() || "",
          size: payloadToUpload?.size?.trim() || "",
          color: payloadToUpload?.color?.trim() || "",
          finish: payloadToUpload?.finish?.trim() || "",
          loadCapacity: payloadToUpload?.loadCapacity?.trim() || "",
          shipmentTime: parseInt(payloadToUpload?.shipmentTime) || null,
          vendorCode: payloadToUpload?.vendorCode || "",
          vendorName: payloadToUpload?.vendorName || "",
          images: payloadToUpload?.images,
          isSampleAvailable: payloadToUpload?.isSampleAvailable,
          isTracked: payloadToUpload?.isTracked,
          isTaxable: payloadToUpload?.isTaxable,
          isCustomOrder: payloadToUpload?.isCustomOrder,
          backOrder: payloadToUpload?.backOrder,
          isActive: payloadToUpload?.isActive || false,
          salesChannel: payloadToUpload?.salesChannel,
          removeSalesChannel: payloadToUpload?.removeSalesChannel,
          tags: payloadToUpload?.tags,
          bulkPackingType1: payloadToUpload?.bulkPackingType1,
          bulkPackingValue1: payloadToUpload?.bulkPackingValue1?.toString(),
          bulkPackingType2: payloadToUpload?.bulkPackingType2,
          bulkPackingValue2: payloadToUpload?.bulkPackingValue2?.toString(),
          multiPriceData: multiPriceData,
          userDefinedFields: payloadToUpload?.userDefinedFields,
          locations: payloadToUpload?.locations,
          franchises: payloadToUpload?.franchises,
          isParentProduct: isParentProduct,
          isVirtualProduct: isVirtualProduct,
          childProducts: payloadToUpload?.childProducts,
        };
        setLoading(true);
        axiosInstance
            .post(`/product/update/${Id}`,{
                    ...data
            })
            .then((res) => {
                if (res.data.status === 'success') {
                    navigate('/productsCreated');
                } else if (res.data.status === 'Server Busy') {
                    alert('Server Busy.. Try Again Later');
                } 
                setLoading(false);
            })
            .catch(error => {
                console.error("Error in updating Product",error);
                const errorMessage = error?.response?.data?.message ?? "Error in updating product";
                setLoading(false);
                setSnackBar({ display: true, type: 'error', message: errorMessage });
            })
        }
        catch (error) {
            console.error("Error in updating Product",error);
            setLoading(false);
        }
    }

    useEffect(() => {
        handleParentProductMRP({payloadToUpload, setPayloadToUpload});
    },[payloadToUpload?.childProducts])

    useEffect(() => {
        if(payloadToUpload?.defaultPrices?.mrp){
            //show dialog when child mrp update
            if(isChildProduct){
                handleOpenDialog();
            }
        }
    },[payloadToUpload?.defaultPrices?.mrp])

    useEffect(() => {
        if(Id){
            axiosInstance
                .get(`/product/${Id}`)
                .then((res) => {
                    const productDetails = res?.data?.data;
                    if(productDetails.length !== 0){
                        setIsParentProduct(productDetails?.isParentProduct)
                        setIsVirtualProduct(productDetails?.isVirtualProduct)
                        setIsChildProduct(productDetails?.isChildProduct);
                        setParentSkus(productDetails?.parentMappedProductsDetails ?? []);

                        const childProductsWithQty = productDetails?.childProductsWithQty

                        dispatch(setBreadcrumb({...breadCrumb, product: productDetails.sku}));
                
                        productDetails.franchiseBasedPrices = 
                            productDetails
                                ?.franchiseBasedPrices
                                ?.map((price) => {
                                    return {
                                        ...price,
                                        priceCardId: uuidv4(),
                                    };
                                });

                        productDetails.districtBasedPrices = 
                            productDetails
                                ?.districtBasedPrices
                                ?.map((price) => {
                                    return {
                                        ...price,
                                        priceCardId: uuidv4(),
                                    };
                                });
                                
                        setPayloadToUpload({
                            'productId': productDetails?.productId,
                            'sku': productDetails?.sku,
                            'productName': productDetails?.productName,
                            'groupName': productDetails?.groupName,
                            'isActive': productDetails?.isActive,
                            'hsnCode': `${productDetails?.hsnCode}`,
                            'salesChannel': productDetails?.salesChannel,
                            'removeSalesChannel': [],
                            'unit': productDetails?.unit,
                            'shipmentTime': productDetails?.shipmentTime,
                            ...(
                                productDetails?.bulkPackingType1 &&
                                {"bulkPackingType1": productDetails?.bulkPackingType1}
                            ),
                            ...(
                                productDetails?.bulkPackingType2 &&
                                {"bulkPackingType2": productDetails?.bulkPackingType2}
                            ),
                            ...(
                                productDetails?.bulkPackingValue1 &&
                                {"bulkPackingValue1": productDetails?.bulkPackingValue1}
                            ),
                            ...(
                                productDetails?.bulkPackingValue2 &&
                                {"bulkPackingValue2": productDetails?.bulkPackingValue2}
                            ),
                            "variable1Type": productDetails?.variable1Type,
                            "variable1Value": productDetails?.variable1Value,
                            "variable2Type": productDetails?.variable2Type,
                            "variable2Value": productDetails?.variable2Value,
                            "variable3Type": productDetails?.variable3Type,
                            "variable3Value": productDetails?.variable3Value,
                            "countryOfOrigin": productDetails?.countryOfOrigin,
                            "includedComponents": productDetails?.includedComponents,
                            "size": productDetails?.size,
                            "finish": productDetails?.finish,
                            "color": productDetails?.color,
                            "materialType": productDetails?.materialType,
                            "warranty": productDetails?.warranty,
                            "weight": productDetails?.weight,
                            "loadCapacity": productDetails?.loadCapacity,
                            "customerCareAddress": productDetails?.customerCareAddress,
                            "marketedBy": productDetails?.marketedBy,
                            'modelNo': productDetails?.modelNo,
                            'childProducts': childProductsWithQty ?? [],
                            'tags': productDetails?.tags,
                            'brandName': productDetails?.brandName,
                            'brandAbr': productDetails?.brandAbr,
                            'vendorName': productDetails?.vendorName,
                            'vendorCode': productDetails?.vendorCode,
                            'l1': productDetails?.l1,
                            'l2': productDetails?.l2,
                            'l3': productDetails?.l3,
                            'locations': productDetails?.locations ?? [],
                            'franchises': productDetails?.franchises,
                            'productDescription': productDetails?.productDescription,
                            'userDefinedFields': productDetails?.userDefinedFields,
                            'images': productDetails?.images,
                            'isTaxable': productDetails?.isTaxable || true,
                            'isSampleAvailable': productDetails?.isSampleAvailable || "no",
                            'isCustomOrder': productDetails?.isCustomOrder || "no",
                            'isTracked': productDetails?.isTracked || true,
                            'backOrder': productDetails?.backOrder || "DENY",
                            'defaultPrices': productDetails?.defaultPrices,
                            'districtBasedPrices': productDetails?.districtBasedPrices,
                            'franchiseBasedPrices': productDetails?.franchiseBasedPrices,
                            'barcodeDataUrl': productDetails?.barcodeDataUrl,
                            'availableQuantity': productDetails?.availableQuantity,
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error in fetching Product Details",error);
                    const errorMessage = error?.response?.data?.errorMessage ?? "Error in fetching product details";
                    setSnackBar({ display: true, type: 'error', message: errorMessage });
                })
        }
    },[Id]);


    const handleOpenDialog = () => {
        setOpen(true);
    }
    const handleCloseDialog = () => {
        setOpen(false);
    }
    return (
        <Fragment>
            {loading ?  <Spinner animation='border' variant='light' /> : null}
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            <div className={Classes.container} id="color" style={{padding:'0.5rem 0rem'}}>
                <form method='post' encType="multipart/form-data" id='formData' onSubmit={handleUpdateProduct}>
                    <div className={Classes.AddProductHeader}  id='header' style={{flexWrap:'wrap'}}>
                        <div className={Classes.AddProductHeaderContent}>
                            <div>
                                <button type="button" className="btn btn-light" onClick={() => { navigate(-1) }}>
                                    <BiArrowBack />
                                </button>
                            </div>

                            <div className="mb-0">
                                <strong className={Classes.AddProductBtn}>
                                    {payloadToUpload?.groupName || 'Product Details'}
                                </strong>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div style={{marginRight:"10px"}}>
                                <Button onClick={showHisotryPopup} variant="outline-dark" style={{padding:"5px 10px",borderRadius:"4px",fontWeight:"600"}} >
                                    Product History
                                </Button>
                            </div>
                            <Permission service={services?.products} permission="update">
                                <div>
                                    <button
                                        style={{ marginRight: 10 }}
                                        className={Classes.UpdateBtn}
                                        type='submit'
                                        onClick={handleUpdateProduct}
                                    >
                                        Update Product
                                    </button>
                                </div>
                            </Permission>
                            <Permission service={services?.products} permission="delete">
                                <div>
                                    <button
                                        id='btn'
                                        style={{ marginRight: 10 }}
                                        className={Classes.DeleteBtn}
                                        onClick={(e) => handleDeleteProduct(e, Id)}
                                    >
                                        Delete Product
                                    </button>
                                </div>
                            </Permission>
                        </div>
                    </div>

                    <div className={Classes.AddProductContainer}>
                        <div className={[Classes.containBoxes, Classes.mainContainer].join(' ')} >
                            <ProductBasicDetails 
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />
                            
                            {isParentProduct && 
                                <ChildProductsCollections
                                    setIsParentProduct={setIsParentProduct}
                                    isVirtualProduct={isVirtualProduct}
                                    setIsVirtualProduct={setIsVirtualProduct}
                                    action="update"
                                    setPayloadToUpload={setPayloadToUpload}
                                    payloadToUpload={payloadToUpload}
                                /> 
                            }

                            {isChildProduct && 
                                <div className={Classes.Box}>
                                    <p style={{ display: 'flex', fontSize: '18px', fontWeight: 500, color:'#262626' }}>
                                        Mapped with Parent Products
                                    </p>
                                    {parentSkus.map(parentProductDetail => 
                                        <div style={{
                                            border:'1px solid lightgrey', padding: '5px 13px', borderRadius:'5px', marginTop:'10px',
                                            fontWeight:500, boxShadow:'0 3px 10px lightgrey', color:'#FF6200', fontSize: '16px'
                                        }}>
                                            {parentProductDetail?.parentProductSku} ||  {parentProductDetail?.productName}
                                        </div>
                                    )}
                                </div>
                            }

                            <MultiPricing 
                                priceCategory={'district'}
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                                endpointToFetchCategories='/pincode/getAllDistricts'
                            />

                            <MultiPricing 
                                priceCategory={'franchise'}
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                                endpointToFetchCategories='/pos/fetchAllFranchises'
                            />

                            <UserDefinedFields
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />

                            <Variants
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />

                            <Inventory 
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />

                            <GroupTitle
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />

                            <Media
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />
                        </div>

                        <div className={Classes.asideContainer}>
                            <Aside
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            /> 

                            {payloadToUpload?.sku && payloadToUpload?.productId && <MainQrcodeBarcode 
                                payloadToUpload = {payloadToUpload}
                                setPayloadToUpload = {setPayloadToUpload}
                            />}
                        </div>
                    </div>
                </form>
            </div>

            <Dialog
                open={open}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Warning!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        On updating <i>child Product MRP</i> , make sure update <i>Parent Product MRP</i> if needed.
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <div className={confirmPopupOpen ? Classes.UpdateSalesDialog : undefined}>
                <ConfirmPopup 
                    showConfirmPopup={confirmPopupOpen}
                    hideConfirmPopup={hideConfirmPopup}
                    productId={Id}
                    sku={payloadToUpload?.sku}
                />
            </div>

            {
                payloadToUpload?.productId &&
                <ProductHistory
                    onClose = {hideHisotryPopup}
                    isOpen ={isHistoryPopupOpen}
                    payloadToUpload={payloadToUpload}
                />
            }
        </Fragment>
    );
};

export default ProductDetails;