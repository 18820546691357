import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	catalogueDetails: [],
	page: 1,
	maxPage: 1,
	total: 0,
};

const catalogueDetailsSlice = createSlice({
	name: 'catalogueDetails',
	initialState: initialState,
	reducers: {
		setCatalogueDetails: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setCatalogueDetails, reset } = catalogueDetailsSlice.actions;

export default catalogueDetailsSlice.reducer;