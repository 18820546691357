import React, {Fragment, useCallback, useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { debounce } from "../../../../utility/utilityFunctions";

const DefaultBillingAddressPopUp = (props)=> {
    const [searchText, setSearchText] = useState("");
    const {
        defaultBillingAddressModal,
        hideDefaultBillingAddressPop,
        showCustomerDetail,
        addressArray,
        setAddressArray,
        setBillingAddress,
        previousTarget,
        setPreviousTarget,
        BillingAddress,
        fetchVendorAddresses
    } = props;

    const handleDefaultBillingAddress =(event,addressId) => {
        if(previousTarget.length !== 0){
            document.getElementById(previousTarget).style.background = 'rgb(250, 251, 251)';
            document.getElementById(previousTarget).style.color = '#000';
        }
        setPreviousTarget([event.target.id])
  
        event.target.style.background= '#008060';
        event.target.style.color = '#fff';
        setBillingAddress([addressArray.find(obj => obj.vendorAddressesId === addressId)]);
        hideDefaultBillingAddressPop();
        
    }

    const updateDefaultBillingAddress = (event, addressId, checked) => {
        axiosInstance
            .put(`/vendor/vendorAddress/${addressId}`, {
                default: checked
            })
            .then((res) => {
                setBillingAddress([res.data.data]);
                const updatedAddressArray = addressArray.map((address) => {
                    if (address.vendorAddressesId === addressId && address.addressType === 'Billing') {
                        address.default = checked;
                    }else if(address.vendorAddressesId !== addressId && address.addressType === 'Billing'){
                        address.default = false;
                    }
                    return address;
                });
                setAddressArray([...updatedAddressArray]);
            });
    }

    const searchAddress = async (searchText, vendorCode) => {
        fetchVendorAddresses({vendorCode, searchText })
    }

    // useCallback ensures that debouncedAddressSearch remains the same across renders
    // such that it doesn't defeat the purpose of debouncing
    const debouncedAddressSearch = useCallback(debounce(searchAddress, 500), []);

    useEffect(() => {
        if(showCustomerDetail?.[0]?.vendorCode){
            debouncedAddressSearch(searchText, showCustomerDetail?.[0]?.vendorCode);
        }
    }, [searchText, debouncedAddressSearch]);

    return (
        <Fragment>
            {defaultBillingAddressModal && (
                <div className={Classes.modal} style={{bottom:'6%'}}>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage address</p>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    style={{fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideDefaultBillingAddressPop}
                                />
                            </div>
                        </div>
                        <div 
                            className={Classes.BoxContent} 
                            style={{
                            padding: "0px 25px 20px 25px"
                            }}
                        >
                            <TextField 
                                size="small" 
                                type="text" 
                                label="Search Address" 
                                onChange={(e) => {
                                    setSearchText(e?.target?.value);
                                }} 
                            />
                        </div>
                    </div>
                    
                    <div style={{overflowY: 'auto',height: '512px'}}>
                        <div style={{marginTop:'10px',padding:'10px 20px'}}>
                            <div>
                                <p style={{fontWeight:'600',fontSize:'14px'}}>
                                    SELECT ADDRESS 
                                </p>
                            </div>
                        </div> 
                        {showCustomerDetail?.length !== 0 
                            ? <div>
                                {addressArray?.map((address,index) => (
                                <div key={index} style={{borderBottom: '1px solid #ced4da',padding: '20px'}}>
                                        <div>
                                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                                <div style={{display:'flex'}}>
                                                    <p style={{marginRight:'5px'}}>{address?.firstName}</p>
                                                    {address?.lastName !== 'undefined' 
                                                        ? <p>{address?.lastName || ""}</p>
                                                        : ''
                                                    }
                                                </div>
                                                <div>
                                                    <p>Address Type</p>
                                                    <p 
                                                        className={Classes.AddressType}
                                                        style={{backgroundColor: address.addressType === 'Shipping' ? 'turquoise': 'greenyellow'}}
                                                    >{address?.addressType}</p>
                                                </div>
                                            </div>

                                            <div style={{marginTop:'-20px'}}>
                                                <p>{address?.addressLine1}</p>
                                                {address?.addressLine2 !== null 
                                                    ? <p>{address?.addressLine2}</p>
                                                : ''}
                                            </div>
                                            <div style={{display:'flex'}}>
                                                <p style={{marginRight:'5px'}}>{address?.pincode},</p>
                                                <p style={{marginRight:'5px'}}>{address?.city}</p>
                                                <p>{address?.state?.toTitleCase()}</p>
                                            </div>
                                            <div>
                                                <p>{address?.country}</p>
                                            </div>
                                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                                <p>{address?.phoneNumber}</p>
                                                <div
                                                    style={{
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                    }}
                                                    >
                                                    {BillingAddress?.length !== 0 ? (
                                                        <div>
                                                        {BillingAddress?.[0]?.vendorAddressesId ===
                                                        address?.vendorAddressesId ? (
                                                            <div>
                                                            <button
                                                                id={address?._id}
                                                                style={{
                                                                background: "#008060",
                                                                border: 0,
                                                                padding: "5px 15px",
                                                                borderRadius: "4px",
                                                                color: "#fff",
                                                                }}
                                                                disabled={true}
                                                            >
                                                                Selected
                                                            </button>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                            <button
                                                                id={address?._id}
                                                                style={{
                                                                background: "#fafbfb",
                                                                border: "1px solid #d2d5d8",
                                                                padding: "5px 15px",
                                                                borderRadius: "4px",
                                                                color: "#000",
                                                                }}
                                                                onClick={(e) =>
                                                                handleDefaultBillingAddress(
                                                                    e,
                                                                    address?.vendorAddressesId
                                                                )
                                                                }
                                                            >
                                                                Select
                                                            </button>
                                                            </div>
                                                        )}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                        <button
                                                            id={address?._id}
                                                            style={{
                                                            background: "#fafbfb",
                                                            border: "1px solid #d2d5d8",
                                                            padding: "5px 15px",
                                                            borderRadius: "4px",
                                                            color: "#000",
                                                            }}
                                                            onClick={(e) =>
                                                            handleDefaultBillingAddress(
                                                                e,
                                                                address?.vendorAddressesId
                                                            )
                                                            }
                                                        >
                                                            Select
                                                        </button>
                                                        </div>
                                                    )}
                                                    {address?.addressType === "Billing" && <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={address?.default}
                                                            onChange={(event, checked) => {
                                                                console.log(address, "address")
                                                            updateDefaultBillingAddress(
                                                                event,
                                                                address?.vendorAddressesId,
                                                                checked
                                                            );
                                                            }}
                                                        />
                                                        }
                                                        label="Default"
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        : ''}

                        <div style={{
                                height: "80%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                        }}>
                            {addressArray?.length === 0 && showCustomerDetail?.length !== 0
                                ? <div style={{padding:'20px'}}>
                                    <p style={{textAlign:'center'}}>No address found</p>
                                </div>
                            : ''}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default DefaultBillingAddressPopUp;