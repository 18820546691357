import React, { useState, forwardRef, useEffect } from "react";
import Permission from "../../../auth/permissions";
import Table from "../../../../components/table/table";
import { useSelector } from "react-redux";
import { setCustomerVideos } from "../../../../store/customerSettingsSlice";
import { Button, Typography, Alert, AlertTitle, Box } from "@mui/material";
import SnackBar from "../../../../components/SnackBar";
import { Add, Edit } from "@mui/icons-material";
import CreateModal from "./createCustomerVideo";
import UpdateModal from "./editCustomerVideo";
import FormDialog from "../../../../components/FormDialog/FormDialog";
import axiosInstance from "../../../../utility/axios-instance";
import MediaPreview from "../../../../components/mediaPreview";
import Styles from "../settings.module.css";

const CustomerHome = () => {
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const customerVideos = useSelector((state) => state.customerVideosSettings);
  const [mediaDetail, setMediaDetail] = useState({});
  const [mediaId, setMediaId] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [mediaPreviewURL, setMediaPreviewURL] = useState("");
  const [mediaPreviewType, setMediaPreviewType] = useState("");

  const MediaUrlRenderCell = (row) => {
    return (
      row?.mediaType === "Image" ? (
        <img
          src={row?.mediaURL}
          alt="icon"
          className={Styles.Images}
          onClick={() => {
            setPreviewToggle(true);
            setMediaPreviewURL(row?.mediaURL);
            setMediaPreviewType(row?.mediaType);
          }}
        />
      ) : (
        <video
          src={row?.mediaURL}
          alt="icon"
          className={Styles.Images}
          onClick={() => {
            setPreviewToggle(true);
            setMediaPreviewURL(row?.mediaURL);
            setMediaPreviewType(row?.mediaType);
          }}
        />
      )
    )
  };

  const EditRenderCell = (row) => {
    return (
      <Permission service="customerVideos" permission="update">
        <Button
          size="small"
          variant="contained"
          color="success"
          startIcon={<Edit />}
          onClick={() => handleUpdate(row?.id)}
        ></Button>
      </Permission>
    )
  };

  const DeleteRenderCell = (row) => {
    return (
      <Permission service="customerVideos" permission={"delete"}>
        <FormDialog
          dialogTitle="Confirm Delete Media"
          dialogActionType="confirmation"
          openDialogButtonName="Delete"
          submitDialogButtonName="Confirm"
          onConfirmation={() => { handleDelete(row?.id) }}
        >
          <Alert
            severity="warning"
            variant="outlined"
            sx={{ borderRadius:2, boxShadow:"none", bgcolor:"background.paper" }}
          >
            <AlertTitle>
              <Typography variant="subtitle1" fontWeight="bold" color="warning.main">
                Confirm Deletion
              </Typography>
            </AlertTitle>
            <Box mb={1}>
              <Typography variant="body1">
                This action will permanently delete the media Titled:{" "}
                <Typography variant="body1" component="span" fontWeight="bold">
                  {row?.title}
                </Typography>{" "}
              </Typography>
            </Box>
            <Typography variant="body1" fontWeight="bold" color="warning.main">
              Are you sure you want to continue?
            </Typography>
          </Alert>
        </FormDialog>
      </Permission>
    )
  }

  const columns = [
    {
      field: "title",
      headerName: "TITLE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "mediaURL",
      headerName: "Media",
      width: 90,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => ( <MediaUrlRenderCell {...params?.row} />),
      customRenderCell: MediaUrlRenderCell
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "updatedAt",
      headerName: "UPDATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    // {
    //   field: "link",
    //   headerName: "LINK",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   sortable: false,
    // },
    {
      field: "active",
      headerName: "ACTIVE",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: `edit`,
      headerName: `EDIT`,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      filterable: false,
      sortable: false,
      renderCell: (params) => ( <EditRenderCell {...params?.row} />),
      customRenderCell: EditRenderCell
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
      renderCell: (params) => ( <DeleteRenderCell {...params?.row} />),
      customRenderCell: DeleteRenderCell
    },
  ];

  const handleDelete = (mediaId) => {
    axiosInstance
      .delete(`/adminRoutes/customers/${mediaId}`)
      .then((res) => {
        setReRender(!reRender);
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
      })
      .catch((err) =>
        setSnackBar({
          display: true,
          type: "error",
          message:
            err?.response?.data?.message ||
            "An error occurred during submission",
        })
      );
  };

  const handleUpdate = (mediaId) => {
    const mediaDetail = customerVideos.customerVideosDetails.find(
      (image) => image.id === mediaId
    );
    setMediaDetail(mediaDetail);
    setMediaId(mediaId);
    setShowUpdateModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };
  const handlePreviewClose = () => {
    setPreviewToggle(false);
  };
  return (
    <>
      <div style={{ display: "flex", margin: "1rem 0 1rem 0" }}>
        <Permission service="customerVideos" permission="create">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Add />}
            onClick={() => setShowCreateModal(true)}
            disabled={
              customerVideos?.customerVideosDetails?.length > 0 ? true : false
            }
          >
            Add New
          </Button>
        </Permission>
      </div>
      <Permission service="customerVideos" permission="read">
        <Table
          columns={columns}
          dataPosition={"customerVideosDetails"}
          endpoint={`/adminRoutes/customers`}
          rowId={"id"}
          checkBoxSelection={false}
          service={"customerVideo"}
          tabName={"Customer Video"}
          setData={setCustomerVideos}
          data={customerVideos}
          version={"version-1"}
          mobileResponsive={true}
          reRender={reRender}
        />
      </Permission>

      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      {showCreateModal ? (
        <CreateModal
          open={showCreateModal}
          handleClose={closeCreateModal}
          setReRender={setReRender}
        />
      ) : null}
      {showUpdateModal ? (
        <UpdateModal
          open={showUpdateModal}
          handleClose={closeUpdateModal}
          detail={mediaDetail}
          setReRender={setReRender}
          mediaId={mediaId}
        />
      ) : null}
      {previewToggle && (
        <MediaPreview
          open={previewToggle}
          handleClose={handlePreviewClose}
          type={mediaPreviewType === "Image"? "img":"video"}
          mediaURL={mediaPreviewURL}
        />
      )}
    </>
  );
};

export default CustomerHome;
