import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Classes from "./css/estimates.module.css";
import { Paper } from "@mui/material";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from "../../../components/spinner";
import NewPagination from "../../../components/newPagination";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import EstimateMobile from "./estimateMobile";
import MobileResponsive from "./mobileResponsive";
import moment from 'moment';

const years = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Estimates = (props) => {
  const [estimates, setEstimates] = useState({
    total: 0,
    page: 1,
    maxPage: 1,
    estimates: [],
  });
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ field: "username", value: "" });
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });
  const columns = [
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "numberOfProducts",
      headerName: "NUMBER OF PRODUCTS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  const fetchData = (page) => {
    setLoading(true);
    setError(null);

    const params = {
      page,
      text: search ? search : "",
      filter: JSON.stringify(filter),
      sort,
    };

    axiosInstance
      .get("/estimate/admin", {
        params,
      })
      .then((response) => {
        setEstimates(response.data.data);
      })
      .catch((error) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // console.log(sort);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter, sort]);

  return (
    <Fragment>
      {error ? (
        <Alert variant='danger' onClose={() => setError(false)} dismissible>
          <Alert.Heading>{error}</Alert.Heading>
          <p>Try reloading!</p>
        </Alert>
      ) : null}

      <div className='d-none d-md-block' style={{ width: "99%" }}>
        <Box
          component={Paper}
          className='shadow'
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: "#243750",
              color: "#ffffff",
              cursor: "default",
            },
          }}
        >
          <DataGrid
            // rowCount={estimates.estimates.length}
            rowSelection={false}
            slots={{ toolbar: GridToolbar }}
            filterMode='server'
            filterColumns={["name"]}
            sortingMode='server'
            paginationMode='server'
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
            onSortModelChange={(sort) => setSort(sort[0])}
            loading={loading}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              boxShadow: 2,
              borderRadius: 2,
              cursor: "pointer",
            }}
            columns={columns}
            rows={estimates?.estimates?.map((estimate) => ({
              id: estimate?._id,
              name: estimate?.userData?.displayName,
              phone: estimate?.userData?.phone,
              email: estimate?.userData?.email,
              numberOfProducts: estimate?.products?.length,
              status: estimate?.status,
              createdAt: moment(estimate?.createdAt).format('MMM DD, YYYY h:mm:ss A'),
            }))}
            onRowClick={(params) => {
              navigate(`/estimates/${params.id}`);
            }}
            sortingOrder={["asc", "desc"]}
            autoHeight
            // autoPageSize
            pageSize={100}
            onFilterModelChange={(filter) => {
              setFilter(filter.items[0]);
              setSearch(filter.quickFilterValues[0]);
            }}
          />
        </Box>

        {estimates?.estimates?.length > 0 ? (
          <nav aria-label='Page navigation'>
            <NewPagination totalPage={estimates} fetchData={fetchData} />
          </nav>
        ) : null}

        {loading ? <Spinner /> : null}
      </div>

      <div className={Classes.MobileResponsive}>
          <MobileResponsive 
            columns={columns}
            dataPosition={"estimates"}
            endpoint={`/estimate/admin`}
            rowId={'_id'}
            estimate={'estimate'}
            sort={sort}
            // handleClickOpen={showUpdatePopup}
              // showClosePopup={showClosePopup}
          />
      </div>
    </Fragment>
  );
};

export default Estimates;
