import { useEffect, useState, Fragment, forwardRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import Classes from './productCreated.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { downloadCSV } from "../../../utility/downloadCSV";
import Accordion from "../uploadImages/accordion";
import NewPagination from '../../../components/newPagination';
import { useSelector, useDispatch  } from 'react-redux';
import { SHOPIFY_UNPLUGGED_CHECK } from './SHOPIFY_UNPLUGGED_CHECK';
import { setBreadcrumb } from '../../../store/breadcrumbSlice';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Spinner from '../../../components/spinner';
import moment from 'moment';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProductsCreatedDetails = () => {
	const [loading,setLoading] = useState(false);
	const [productCreated, setProductCreated] = useState([]);
	const navigate = useNavigate();
	const [failedSkuBtn,setFailedSkuBtn] = useState(false);
	const [failedBrandBtn,setFailedBrandBtn] = useState(false);
	const [failedCategoryBtn,setFailedCategoryBtn] = useState(false)
	const [alignment, setAlignment] = useState('success');
	// const [toggle,setToggle] = useState(true);

	const auth = useSelector((state) => state.auth);

	const { Id } = useParams();
	const breadCrumb = useSelector((state) => state.breadcrumb);
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [data,setData] = useState([]);

	const handleClickOpen = (data) => {
		setData(data);
	  	setOpen(true);
	};
  
	const handleClose = () => {
	  	setOpen(false);
	};

	const fetchProduct = async (type,alignmentType) => {
		setLoading(true);
		const page = type;
		await axiosInstance
			.get(`/productCreated/group/${Id}?page=${page}&type=${alignmentType ? alignmentType : alignment}`)
			.then((res) => {
				let product = res?.data?.data;
				setProductCreated([res?.data?.data]);
				for (let ele of product?.product) {
					if (ele?.failedSku) {
						setFailedSkuBtn(true)
					}

					if (ele?.failedBrand) {
						setFailedBrandBtn(true)
					}

					if (ele?.failedCategory) {
						setFailedCategoryBtn(true)
					}
				}

				setLoading(false);
				const groupId = product?.product?.length !== 0 ? product?.product?.[0]?.groupId?.groupId : product?.groupRecord?.groupId;
				dispatch(setBreadcrumb({ ...breadCrumb, groupId: groupId }));
			});
	};

	const handleExport = (event) => {
		event.preventDefault();

		axiosInstance
			.get(`/exports/products/${productCreated?.[0]?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Products export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleExportFailedSku = (event) => {
		event.preventDefault();
	
		axiosInstance
			.get(`/exports/reportService/failedSku/${Id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Failed SKU Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleExportFile = (event) => {
		event.preventDefault();
	
		axiosInstance
			.get(`/exports/reportService/fileData/${productCreated?.[0]?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Uploaded File Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleExportFailedBrand = (event) => {
		event.preventDefault();
	
		axiosInstance
			.get(`/exports/reportService/failedBrand/${productCreated?.[0]?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Failed Brand Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleExportFailedCategory = (event) => {
		event.preventDefault();
	
		axiosInstance
			.get(`/exports/reportService/failedCategory/${productCreated?.[0]?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Failed Category Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}


	const handleExportImages = (event) => {
		event.preventDefault();

		axiosInstance
			.get(`/exports/products/Images/${productCreated?.[0]?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Images Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const modifyForAccordion = (obj) => {
		let result = [];
		for (let key in obj) {
			if (obj[key].length !== 0) {
				result.push({
					title: key,
					content: obj[key],
				});
			}
		}
		return result;
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
		fetchProduct(page ? page : 1,alignment);
	}, []);

	const handleChange = (event) => {
        setAlignment(event.target.value);
		fetchProduct(1,event.target.value)
    };

	return (
		<Fragment>
			<div style={{ gap:'1rem',width:'99%' }}>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle style={{fontWeight:600}}>Reason</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							{data?.length !== 0 && (
								<div>
									{data?.database?.created === false && (
										<div style={{display:'flex',marginBottom:10}}>
											<p style={{fontWeight:600,marginRight:5,color:'#000'}}>Database:</p>
											<p style={{color:'#000',fontSize:15}}>{data?.database?.errorMessage}</p>
										</div>
									)}

									{data?.pgDatabase && data?.pgDatabase?.created === false && (
										<div style={{display:'flex'}}>
											<p style={{fontWeight:600,marginRight:5,color:'#000'}}>PG Database:</p>
											<p style={{color:'#000',fontSize:15}}>{data?.pgDatabase?.errorMessage}</p>
										</div>
									)}
								</div>
							)}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>CLOSE</Button>
					</DialogActions>
				</Dialog>

				<div style={{ display:'flex',justifyContent:'flex-end',marginTop:-10 }}>
					{(auth?.user?.role === 'super-admin' || auth?.user?.role === 'admin') && ( 
						<div style={{marginRight:5}}>
							<button
								className="btn btn-primary"
								onClick={handleExportFile}
								style={{ padding:"4px 6px",fontSize:13 }}
								disabled={productCreated?.length !== 0 && productCreated?.[0]?.product?.length !== 0 ? false : true}
							>
								Export File
							</button>
						</div>
					)}

					{failedSkuBtn && (
						<div style={{marginRight:5}}>
							<button 
								className="btn btn-primary" 
								onClick={handleExportFailedSku} 
								style={{ padding:"4px 6px",fontSize:13 }}
								disabled={productCreated?.length !== 0 && productCreated?.[0]?.product?.length !== 0 ? false : true}
							>
								Export Failed Sku
							</button>
						</div>
					)}

					{failedBrandBtn && (
						<div style={{marginRight:5}}>
							<button
								className="btn btn-primary"
								onClick={handleExportFailedBrand}
								style={{ padding:"4px 6px",fontSize:13 }}
								disabled={productCreated?.length !== 0 && productCreated?.[0]?.product?.length !== 0 ? false : true}
							>
								Export Failed Brands
							</button>
						</div>
					)}

					{failedCategoryBtn && (
						<div style={{marginRight:5}}>
							<button
								className="btn btn-primary"
								onClick={handleExportFailedCategory}
								style={{ padding:"4px 6px",fontSize:13 }}
								disabled={productCreated?.length !== 0 && productCreated?.[0]?.product?.length !== 0 ? false : true}
							>
								Export Failed Category
							</button>
						</div>
					)}
					
					<div style={{marginRight:5}}>
						{productCreated?.[0]?.product[0]?.requestType !== 'Delete Product' && productCreated?.[0]?.product[0]?.requestType === 'Upload Images' && (
							<button
								className="btn btn-primary"
								onClick={handleExportImages}
								style={{ padding:"4px 6px",fontSize:13 }}
							>
								Exported Images
							</button>
						)}
					</div>
					<div>
						{productCreated?.[0]?.product[0]?.requestType !== 'Delete Product' && (
							<button
								className="btn btn-primary"
								onClick={handleExport}
								style={{ padding:"4px 6px",fontSize:13 }}
								disabled={productCreated?.length !== 0 && productCreated?.[0]?.product?.length !== 0 ? false : true}
							>
								Export Report
							</button>
						)}
					</div>
				</div>
			</div>

			<div style={{ display:'flex',justifyContent:'space-between',fontWeight:600,width:'99%',marginTop:5 }}>
				<div style={{ backgroundColor:'#9e9e9e1f',borderRadius:4,padding: 4 }}>
					<ToggleButtonGroup
						color="primary"
						value={alignment}
						exclusive
						onChange={handleChange}
						aria-label="Platform"
						sx={{
							"& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
							"& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
						}}
					>
						<ToggleButton value="success">Success SKU</ToggleButton>
						<ToggleButton value="failed">Failed SKU</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<div>
					Total: {productCreated?.[0]?.total}
				</div>
			</div>

			{productCreated?.length !== 0 && !(productCreated?.[0]?.groupRecord) ? (
				<div className={Classes.Container} style={{ width:'99%',marginTop:10,borderRadius:4,padding:0 }}>
					<div className={Classes.GroupDetailsHeader}>
						<h6 className={Classes.Column} style={{ fontSize:15,width:'9%' }}>
							Request Id
						</h6>

						{productCreated?.[0]?.product[0]?.brandName || productCreated?.[0]?.product[0]?.category ? (
							<>
								{productCreated?.[0]?.product[0]?.category ? (
									<>
										<h6 className={Classes.Column} style={{ fontSize:15,width:'12%' }}>
											Category
										</h6>
										<h6 className={Classes.ProductDetail} style={{ fontSize:15,width:'30%',marginLeft:25 }}>
											Category Name
										</h6>
									</>
								) : (
									<h6 className={Classes.ProductDetail} style={{ fontSize:15,width:'30%',marginLeft:25 }}>
										Brand Name
									</h6>
								)}
							</>
						) : (
							<>
								<h6 className={Classes.Column} style={{ fontSize:15,width:'19%' }}>
									SKU
								</h6>
								<h6 className={Classes.ProductDetail} style={{ fontSize:15,width:'34%',margin:'0px 0px 0px 25px' }}>
									Product
								</h6>
							</>
						)}

						<h6 className={Classes.CreatedAtHeader} style={{ marginLeft:20 }}>
							Created At
						</h6>
						<div className={Classes.Platforms}>
							<div className={Classes.PlatformsType} style={{ justifyContent: 'space-between' }}>
								<h6 style={{ fontSize:15,marginRight:15 }}>Database</h6>

								{productCreated?.[0]?.product[0]?.brandName || productCreated?.[0]?.product[0]?.category ?
									<h6 style={{fontSize:15}}>Reason</h6>
								: 
									<>
										{process.env.REACT_APP_VINCULUM_UNPLUGGED === "true" && (
											<h6 style={{fontSize:15}}>Vinculum</h6>
										)}
									</>
								}
								{productCreated?.[0]?.product?.some(obj => obj.hasOwnProperty("pgDatabase")) && (
									<h6 style={{ fontSize:15,marginRight:15 }}>PG Database</h6>		
								)}		
							</div>
						</div>
					</div>

					<div style={{ fontSize:13 }}>
						{productCreated?.length !== 0 && productCreated?.[0]?.product?.map((created) => (
							<div
								key={created?._id}
								className={Classes.Row}
								onClick={
									!(productCreated?.[0]?.product[0]?.brandName || productCreated?.[0]?.product[0]?.category) ? 
									created?.failedSku ? () => handleClickOpen(created) : !(created?.requestType === 'Delete Product') ?
									() => navigate(`/products/${created?.pgDbProductId}`) : false : false
								}

								style={{
									padding:12,
									cursor: !(productCreated?.[0]?.product[0]?.brandName || productCreated?.[0]?.product[0]?.category) ?
										'pointer' : 'auto'
								}}
							>
								<div className={Classes.RequestId}>
									<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600}}>Request Id:</p>
									<p>{created?.requestId}</p>
								</div>

								{productCreated?.[0]?.product[0]?.brandName || productCreated?.[0]?.product[0]?.category ? (
									<>
										{productCreated?.[0]?.product[0]?.category ? (
											<>
												<p className={Classes.Column} style={{ fontSize:15,width:'16%',paddingLeft:25 }}>
													{created?.category}
												</p>
												<p className={Classes.ProductDetail} style={{ fontSize:15,marginLeft:25 }}>
													{created?.title}
												</p>
											</>
										) : (
											<p className={Classes.ProductDetail} style={{ fontSize:15,marginLeft:25 }}>
												{created?.brandName}
											</p>
										)}
									</>
								) : (
									<>
										<div className={Classes.Sku}>
											<p className='d-flex d-md-none text-muted' style={{marginRight:10}}>SKU:</p>
											<p>{created?.sku}</p>
										</div>
										<div className={Classes.ProductDetail}>
											<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600}}>
												Product:
											</p>
											<p>{created?.title}</p>
										</div>
									</>
								)}


								<div className={Classes.CreatedAt} style={{width:'17%'}}>
									<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600}}>
										Created At:
									</p>
									<p>{moment(created?.createdAt).format('MMM DD, YYYY h:mm:ss A')}</p>
								</div>

								<div className={Classes.Platforms}>
									<div className={Classes.PlatformsType} style={{ justifyContent:'space-between' }}>
										<div className={Classes.Database}>
											<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600,marginTop:-5}}>
												Database:
											</p>
											{created?.database && (
												<>
													{Array.isArray(created?.database) && created?.database.length !== 0 ? (
														<FontAwesomeIcon
															icon={created?.database[0] === true ? faCircleCheck : faCircleXmark}
															style={{fontSize:15,color:created?.database[0] === true ? 'green': 'red'}}
														/>
													) : (
														<FontAwesomeIcon
															icon={created?.database?.created === true ? faCircleCheck : faCircleXmark}
															style={{fontSize:15,color:created?.database?.created === true ? 'green': 'red',textAlign:'center'}}
														/>
													)}
												</>
											)}
										</div>

										<div style={{display:'flex', width:'49%'}}>
											<p className='d-flex d-md-none text-muted'>PG Database:</p>
											{created?.pgDatabase && (
												<div style={{ width: '15%' }}>
													<FontAwesomeIcon
														icon={created?.pgDatabase ? created?.pgDatabase?.created === true ? faCircleCheck : faCircleXmark : faCircleCheck}
														style={{fontSize:15,marginLeft:10,color:created?.pgDatabase ? created?.pgDatabase?.created === true ? "green" : "red" : "green", textAlign:'center'}}
													/>
												</div>
											)}
										</div>

										{process.env.REACT_APP_VINCULUM_UNPLUGGED === "true" && (
											<div>
												<p className='d-flex d-md-none text-muted'>Vinculum:</p>

												{created.vinculum && (
													<div style={{ width: '15%' }}>
														{Array.isArray(created?.vinculum) && created?.vinculum?.length !== 0 ? (
															<FontAwesomeIcon
																icon={created?.vinculum[0]?.created === true ? faCircleCheck : faCircleXmark}
																style={{fontSize:15,marginRight:20,color:created?.vinculum[0]?.created === true ? 'green': 'red'}}
															/>
														) : (
															<FontAwesomeIcon
																icon={created?.vinculum?.created === true ? faCircleCheck : faCircleXmark}
																style={{fontSize:15,marginRight:20,color:created?.vinculum?.created === true ? 'green': 'red'}}
															/>
														)}
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div style={{ margin: '30px 0px 0px 0px' }}>
					{productCreated?.length !== 0 && 
					productCreated?.[0]?.groupRecord?.errors &&
					productCreated?.[0]?.groupRecord?.errors?.brandNotFoundError?.length !== 0 ? (
						<Accordion accordionData={modifyForAccordion(productCreated?.[0]?.groupRecord?.errorObject)} />
					) : (
						<p style={{display:'flex', justifyContent:'center', alignItems:'center', height:'55vh'}}>No Product Found</p>
					)}
				</div>
			)}

			<div style={{ margin: '10px auto 0px' }}>
				{productCreated?.length !== 0 ? (
					<NewPagination totalPage={productCreated?.[0]} fetchData={fetchProduct} />
				) : null}
			</div>

			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default ProductsCreatedDetails;