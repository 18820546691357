import { Box, Typography } from "@mui/material";
import { Event as EventIcon } from "@mui/icons-material";
import { format, formatDistanceToNow, isValid } from "date-fns";
import { useMemo, useState } from "react";

const AlteredAt = ({
  style,
  alteredAt,
  alterType = "created",
  alterIcon = <EventIcon sx={{ color: "#666666", fontSize: "15px" }} />,
}) => {
  const [showExactTime, setShowExactTime] = useState(false);
  const dateObject = useMemo(() => new Date(alteredAt), [alteredAt]);

  const toggleTimeDisplay = () => {
    setShowExactTime(!showExactTime);
  };

  const getTimeString = () => {
    if (!isValid(dateObject)) {
      return "Invalid date";
    }

    if (showExactTime) {
      return format(dateObject, "MMMM d, yyyy 'at' h:mm a");
    } else {
      // without suffix it will show "in 5 minutes" instead of "5 minutes ago"
      return formatDistanceToNow(dateObject, { addSuffix: true });
    }
  };
  return (
    <Box
      sx={{
        margin: "0px auto",
        padding: "8px",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff",
        fontFamily: "Arial, sans-serif",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      onClick={toggleTimeDisplay}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {alterIcon}
          <Typography
            sx={{
              fontSize: "10px",
              color: "#333333",
            }}
          >
            <span style={{ fontWeight: 600 }}>
              {alterType.toTitleCase()} At:
            </span>{" "}
            {/* {moment(alteredAt).format("MMM DD, YYYY h:mm:ss A")} */}
            {getTimeString()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AlteredAt;
